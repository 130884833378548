export const FETCH_PLUGINS = "PLUGIN/FETCH_PLUGINS";
export const FETCH_PLUGINS_DONE = "PLUGIN/FETCH_PLUGINS_DONE";

export const GET_PLUGIN = "PLUGIN/GET_PLUGIN";
export const GET_PLUGIN_DONE = "PLUGIN/GET_PLUGIN_DONE";

export const TRANSLATE = "PLUGIN/TRANSLATE";

export const THESIS_SUMMARY = "PLUGIN/THESIS_SUMMARY";

export const CREATE_PLUGIN = "PLUGIN/CREATE_PLUGIN";
export const CREATE_PLUGIN_DONE = "PLUGIN/CREATE_PLUGIN_DONE";

export const UPDATE_PLUGIN = "PLUGIN/UPDATE_PLUGIN";
export const UPDATE_PLUGIN_DONE = "PLUGIN/UPDATE_PLUGIN_DONE";

export const EXECUTE_PLUGIN = "PLUGIN/EXECUTE_PLUGIN";
export const EXECUTE_PLUGIN_DONE = "PLUGIN/EXECUTE_PLUGIN_DONE";

export const DELETE_PLUGIN = "PLUGIN/DELETE_PLUGIN";
export const DELETE_PLUGIN_DONE = "PLUGIN/DELETE_PLUGIN_DONE";

export const TRY_PLUGIN_ACTION = "PLUGIN/TRY_PLUGIN_ACTION";
export const TRY_PLUGIN_ACTION_DONE = "PLUGIN/TRY_PLUGIN_ACTION_DONE";
