import { Button, Card, Divider, Grid, Typography } from "@mui/material";
import {
  createPlugin,
  getPlugin,
  updatePlugin,
} from "app/redux/actions/plugin";
import ImageSection from "app/shared/Form/ImageSection";
import InputSection from "app/shared/Form/InputSection";
import RadioSection from "app/shared/Form/RadioSection";
import SelectSection from "app/shared/Form/SelectSection";
import SliderSection from "app/shared/Form/SliderSection";
import Loading from "app/shared/Loading";
import { useFormik } from "formik";
import Immutable from "immutable";
import { useEffect, useState } from "react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import * as yup from "yup";

import Flow from "../../../shared/Flow";

const ProcessMasterPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [tokenMarks, setTokenMarks] = useState([
    {
      value: 1,
      label: "1",
    },
    {
      value: 4096,
      label: "4096",
    },
  ]);

  const modelData = useSelector(({ model }) =>
    model.getIn(["results", "models"]),
  );

  const knowledgebaseList = useSelector(({ knowledgebase }) =>
    knowledgebase.getIn(["results", "knowledgebases"]),
  );

  const validationSchema = yup.object({
    name: yup.string().required("請輸入插件名稱"),
    description: yup.string().required("請輸入插件描述"),
    features: yup.string().required("請輸入插件特色"),
    type: yup.string().required("請選擇插件類型"),
    language_model: yup.object().when("type", {
      is: "chatbot",
      then: () => yup.object().required("請選擇語言模型"),
      otherwise: () => yup.object().notRequired(),
    }),
    knowledge_base_ids: yup.array().when("type", {
      is: "chatbot",
      then: () => yup.array().min(1, "請至少選擇一個知識庫").required(),
      otherwise: () => yup.array().notRequired(),
    }),
  });

  const initialValues = {
    thumbnail_path: "",
    name: "",
    description: "",
    features: "",
    type: "task",
    language_model: null,
    system_prompt: "",
    knowledge_base_ids: [],
    max_tokens: 4096,
    temperature: 0.5,
  };

  const handleChanges = (nodes, edges) => {
    setNodes(nodes);
    setEdges(edges);
  };

  const handleSubmit = async (values) => {
    let success = false;

    const valueList = Immutable.fromJS(values);
    const data = {
      name: valueList.get("name"),
      type: valueList.get("type"),
      thumbnail_path: valueList.get("thumbnail_path"),
      description: valueList.get("description"),
      features: valueList.get("features"),
    };
    if (valueList.get("type") === "chatbot") {
      data.language_model = valueList.getIn(["language_model", "value"]);
      data.system_prompt = valueList.get("system_prompt");
      data.knowledge_base_ids = valueList
        .get("knowledge_base_ids")
        .map((kb) => kb.get("value"))
        .toJS();
      data.temperature = valueList.get("temperature");
      data.max_tokens = valueList.get("max_tokens");
    }
    if (valueList.get("type") === "task") {
      data.nodes = nodes;
      data.edges = edges;
    }

    if (isEdit) {
      success = await dispatch(updatePlugin(id, data));
    } else {
      success = await dispatch(createPlugin(data));
    }

    if (success) {
      navigate("/admin/plugins");
    }
  };

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: handleSubmit,
  });

  const handleImageChange = (image) => {
    if (image.length > 0) {
      formik.setFieldValue("thumbnail_path", image[0].path);
    }
  };

  const handleChangeModel = (e) => {
    const value = e.target.value;
    console.log("value", value);
    formik.setFieldValue("language_model", value);

    const model = modelData.find((model) => model.get("name") === value.value);
    const marks = [
      {
        value: 1,
        label: "1",
      },
      {
        value: 4096,
        label: "4096",
      },
    ];

    if (model) {
      marks[1].value = Number(model.get("max_tokens"));
      marks[1].label = model.get("max_tokens");
      const maxTokens = model.get("max_tokens") / 2;
      formik.setFieldValue("max_tokens", maxTokens);
    }
    setTokenMarks(marks);
  };

  useEffect(() => {
    const fetchData = async (id) => {
      const plugin = await dispatch(getPlugin(id));
      if (plugin.type === "task") {
        setNodes(plugin.nodes);
        setEdges(plugin.edges);
      }
      formik.setValues({
        ...plugin,
        knowledge_base_ids:
          knowledgebaseList &&
          knowledgebaseList
            .filter((item) =>
              plugin.knowledge_base_ids.includes(item.get("id")),
            )
            .map((item) => ({
              value: item.get("id"),
              label: item.get("name"),
            }))
            .toJS(),
        language_model: {
          value: plugin.language_model,
          label: plugin.language_model,
        },
        temperature: plugin.temperature,
        max_tokens: plugin.max_tokens,
      });
      setIsEdit(true);
    };

    if (id) {
      fetchData(id);
    }
  }, [id, knowledgebaseList]);

  if (!modelData || !knowledgebaseList) return <Loading />;

  return (
    <Card sx={{ p: 4 }}>
      <Typography variant="h1" sx={{ m: 0 }}>
        新插件設定
      </Typography>

      <Divider sx={{ my: 4 }} />

      <form onSubmit={formik.handleSubmit}>
        <ImageSection
          value={formik.values.thumbnail_path}
          position="left"
          onFinish={handleImageChange}
        />

        <InputSection
          title="插件名稱"
          placeholder="請輸入插件名稱"
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
        />

        <InputSection
          title="插件描述"
          placeholder="請輸入插件描述"
          name="description"
          value={formik.values.description}
          onChange={formik.handleChange}
          error={
            formik.touched.description && Boolean(formik.errors.description)
          }
          helperText={formik.touched.description && formik.errors.description}
        />

        <InputSection
          title="插件特色"
          placeholder="請輸入插件特色"
          name="features"
          multiline
          value={formik.values.features}
          onChange={formik.handleChange}
          error={formik.touched.features && Boolean(formik.errors.features)}
          helperText={formik.touched.features && formik.errors.features}
        />

        <RadioSection
          title="插件類型"
          name="type"
          options={[
            {
              label: "任務設定",
              value: "task",
            },
            {
              label: "聊天機器人設定",
              value: "chatbot",
            },
          ]}
          value={formik.values.type}
          onChange={formik.handleChange}
          error={formik.touched.type && Boolean(formik.errors.type)}
          helperText={formik.touched.type && formik.errors.type}
        />

        {formik.values.type === "chatbot" && (
          <>
            <SelectSection
              title="語言模型"
              name="language_model"
              defaultOptionLabel="選擇語言模型"
              value={formik.values.language_model}
              onChange={handleChangeModel}
              options={modelData
                .map((model) => ({
                  value: model.get("name"),
                  label: model.get("name"),
                }))
                .toJS()}
              error={
                formik.touched.language_model &&
                Boolean(formik.errors.language_model)
              }
              helperText={
                formik.touched.language_model && formik.errors.language_model
              }
            />

            <InputSection
              multiline
              title="語言模型的行為模式"
              name="system_prompt"
              value={formik.values.system_prompt}
              onChange={formik.handleChange}
              placeholder="範例：You are an AI assistant skilled in Python programming and debugging. Help users identify and fix errors in their Python code, offer suggestions for optimization."
            />

            <SelectSection
              title="選擇知識庫"
              name="knowledge_base_ids"
              defaultOptionLabel="選擇知識庫"
              value={formik.values.knowledge_base_ids}
              multiple
              onChange={formik.handleChange}
              options={knowledgebaseList
                .map((item) => ({
                  value: item.get("id"),
                  label: item.get("name"),
                }))
                .toJS()}
              error={
                formik.touched.knowledge_base_ids &&
                Boolean(formik.errors.knowledge_base_ids)
              }
              helperText={
                formik.touched.knowledge_base_ids &&
                formik.errors.knowledge_base_ids
              }
            />
            <SliderSection
              title="活潑度(溫度)"
              name="temperature"
              tooltip="Temperature 數字越高 代表 回答的創造性 越高; 反之 則越精確"
              marks={tempMarks}
              step={0.1}
              value={formik.values.temperature}
              onChange={formik.handleChange}
            />
            <SliderSection
              title="最大 Token 長度"
              name="max_tokens"
              tooltip="Token長度越多，回覆長度越長"
              marks={tokenMarks}
              step={1}
              value={formik.values.max_tokens}
              onChange={formik.handleChange}
            />
          </>
        )}

        {formik.values.type === "task" && (
          <>
            <Grid item xs={12} md={12}>
              <Typography variant="h2">Actions</Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <div style={{ height: "700px" }}>
                <Flow
                  nodes={nodes}
                  edges={edges}
                  setNodes={setNodes}
                  setEdges={setEdges}
                  appBarColor="#1976d2"
                  onDataChanges={handleChanges}
                />
              </div>
            </Grid>
          </>
        )}

        <Button
          variant="contained"
          color="primary"
          type="submit"
          sx={{ mt: 2 }}
        >
          送出
        </Button>
      </form>
    </Card>
  );
};

export default ProcessMasterPage;

const tempMarks = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 1,
    label: "1",
  },
];
