import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Slider, Stack, Tooltip, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

const SliderSection = ({
  title,
  width,
  name,
  tooltip,
  marks,
  step,
  value,
  onChange,
}) => {
  return (
    <Stack sx={{ width: { md: width || 700, sx: "100%" }, mb: 3 }}>
      <Stack flexDirection="row" gap={0.3} sx={{ mb: 2 }}>
        <Typography variant="h4">{title}</Typography>
        {tooltip && (
          <Tooltip title={tooltip} placement="top-start">
            <ErrorOutlineIcon
              sx={{
                fontSize: 16,
                color: "#c0c0c0",
              }}
            />
          </Tooltip>
        )}
        <Typography variant="h4" sx={{ ml: 2 }}>
          數值：<strong>{value}</strong>
        </Typography>
      </Stack>
      <Slider
        name={name}
        getAriaValueText={(value) => value.toString()}
        marks={marks}
        step={step}
        valueLabelDisplay="auto"
        max={marks[marks.length - 1].value}
        min={marks[0].value}
        value={value}
        onChange={onChange}
      />
    </Stack>
  );
};

export default React.memo(SliderSection);

SliderSection.propTypes = {
  title: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  marks: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  step: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};
