import { useCallback, useEffect, useState } from "react";

const usePageVisibility = () => {
  const [isVisible, setIsVisible] = useState(() => {
    if (typeof document !== "undefined") {
      return !document.hidden;
    }
    return true; // 如果在服務器端渲染，假設頁面是可見的
  });

  const handleVisibilityChange = useCallback(() => {
    if (typeof document !== "undefined") {
      setIsVisible(!document.hidden);
    }
  }, []);

  useEffect(() => {
    if (typeof document !== "undefined") {
      document.addEventListener("visibilitychange", handleVisibilityChange);
      // 初始化時立即檢查一次狀態
      handleVisibilityChange();
    }

    return () => {
      if (typeof document !== "undefined") {
        document.removeEventListener(
          "visibilitychange",
          handleVisibilityChange,
        );
      }
    };
  }, [handleVisibilityChange]);

  return isVisible;
};

export default usePageVisibility;
