import http from "app/services";

import {
  FCH_USER,
  FCH_USER_DONE,
  USER_LOGIN,
  USER_LOGIN_FAILURE,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
} from "../types/auth";
import { errorAlert } from "./alert";

export const userLogin = (formData) => {
  return async (dispatch) => {
    dispatch({ type: USER_LOGIN });
    const res = await http.post("/auth/login", formData);
    if (res.response) {
      if (res.response.status === 401) {
        dispatch(errorAlert("帳號或密碼錯誤。"));
      }
      if (res.response.status >= 500) {
        dispatch(errorAlert("系統錯誤，請聯絡管理員。"));
      }
      return dispatch({ type: USER_LOGIN_FAILURE });
    }

    const token = res.data.payload.token;
    const loginData = JSON.parse(atob(token.split(".")[1]));

    const data = {
      token,
      data: loginData,
    };

    localStorage.setItem("ragi", JSON.stringify(data));
    window.location.href = "/";
    dispatch({ type: USER_LOGIN_SUCCESS });
  };
};

export const userLogout = () => {
  return (dispatch) => {
    localStorage.removeItem("ragi");
    dispatch({ type: USER_LOGOUT });
    window.location.href = "/user/login";
  };
};

export const fchUser = (id) => {
  let url = "/api/v1/users/me";
  if (id) url = `/api/v1/users/${id}`;
  return async (dispatch) => {
    dispatch({ type: FCH_USER });
    const res = await http.get(url);
    const storage = localStorage.getItem("ragi");
    const existingData = storage ? JSON.parse(storage) : null;
    existingData.data.role = res.data.role;
    localStorage.setItem("ragi", JSON.stringify(existingData));
    dispatch({ type: FCH_USER_DONE, payload: { doc: res.data } });
    return res.data;
  };
};
