import Div from "@jumbo/shared/Div";
import {
  Button,
  Divider,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import * as yup from "yup";

const CategoryModal = ({ category, isOpen, onClose, isEdit, onSubmit }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 1,
  };

  const handleSubmit = (values) => {
    onSubmit(values);
    formik.resetForm();
  };

  const initialValues = {
    name: "",
  };

  const validationSchema = yup.object({
    name: yup.string().required("請輸入選單名稱"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  const handleClose = () => {
    formik.resetForm();
    onClose();
  };

  useEffect(() => {
    if (isEdit && category) {
      formik.setValues({
        name: category.get("name"),
      });
    }
  }, [isEdit, category]);

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Div sx={style}>
        <Stack flexDirection="row" color="orange" gap={1} p={2}>
          <Typography variant="h2" m={0}>
            新增主選單
          </Typography>
        </Stack>
        <Divider />
        <form onSubmit={formik.handleSubmit}>
          <Stack gap={2} sx={{ p: 2 }}>
            <TextField
              id="name"
              name="name"
              placeholder="主選單名稱"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.errors.name}
              helperText={formik.errors.name}
            />
          </Stack>
          <Divider />
          <Stack p={1} flexDirection="row" gap={1} justifyContent="flex-end">
            <Button variant="outlined" onClick={handleClose} size="small">
              取消
            </Button>
            <Button variant="contained" type="submit" size="small">
              確定
            </Button>
          </Stack>
        </form>
      </Div>
    </Modal>
  );
};

export default CategoryModal;

CategoryModal.propTypes = {
  category: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};
