import Immutable from "immutable";

import { USER_LOGOUT } from "../types/auth";
import { CLEAR_CONVERSATION, GET_REFERENCE_DONE } from "../types/conversation";
import {
  CREATE_KNOWLEDGEBASE_DONE,
  DEL_KNOWLEDGEBASE_DONE,
  DEL_SOURCE_DONE,
  DEL_USER_ROLE_DONE,
  FCH_KNOWLEDGEBASE_DONE,
  FCH_KNOWLEDGEBASES_DONE,
  SET_CURRENT_SOURCE_ID,
  SORT_KNOWLEDGEBASES,
  UPD_NAME_DONE,
  UPD_USER_PERMISSION_DONE,
  UPD_USER_ROLE_DONE,
  UPLOAD_SOURCE,
  UPLOAD_SOURCE_DONE,
  UPLOAD_SOURCE_FAIL,
} from "../types/knowledgebase";
import {
  DEL_ALL_NOTIFICATIONS_DONE,
  FCH_NOTIFICATIONS_DONE,
  READ_ALL_NOTIFICATIONS_DONE,
  READ_NOTIFICATION_DONE,
} from "../types/notification";

const INIT_STATE = Immutable.OrderedMap({
  status: Immutable.List(),
  notifications: Immutable.List(),
  config: {
    currentSourceId: null,
  },
  results: {
    knowledgebases: null,
    info: null,
    suggestedQuestions: Immutable.Map(),
  },
});
const knowledgebaseReducer = (_ = INIT_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case FCH_KNOWLEDGEBASES_DONE: {
      const data = Immutable.fromJS(payload.doc).sort((a, b) =>
        a.get("created_at") < b.get("created_at") ? 1 : -1,
      );
      return _.setIn(["results", "knowledgebases"], data);
    }
    case FCH_KNOWLEDGEBASE_DONE: {
      const { doc } = payload;
      const initSources = Immutable.fromJS(doc.sources).filter(
        (source) => source.get("status") === "INIT",
      );

      if (initSources.isEmpty()) {
        return _.setIn(["results", "info"], Immutable.fromJS(doc));
      }

      const updatedDoc = {
        ...doc,
        sources: doc.sources.map((source) =>
          source.status === "INIT"
            ? { ...source, status: "UPLOADING" }
            : source,
        ),
      };

      return _.setIn(["results", "info"], Immutable.fromJS(updatedDoc));
    }
    case CREATE_KNOWLEDGEBASE_DONE: {
      const _newList = _.getIn(["results", "knowledgebases"])
        .push(Immutable.fromJS(payload.doc))
        .sort((a, b) => {
          const aIsChinese = /[\u4e00-\u9fff]/.test(a.get("name"));
          const bIsChinese = /[\u4e00-\u9fff]/.test(b.get("name"));

          if (aIsChinese && !bIsChinese) return 1;
          if (!aIsChinese && bIsChinese) return -1;
          return a.get("name").localeCompare(b.get("name"));
        });
      return _.setIn(["results", "knowledgebases"], _newList);
    }

    case DEL_KNOWLEDGEBASE_DONE: {
      const _newList = _.getIn(["results", "knowledgebases"]).filter(
        (item) => item.get("id") !== payload.id,
      );
      return _.setIn(["results", "knowledgebases"], _newList);
    }
    case UPD_NAME_DONE: {
      const _newList = _.getIn(["results", "knowledgebases"]).map((item) => {
        if (item.get("id") === payload.id) {
          return item.set("name", payload.name);
        }
        return item;
      });
      return _.setIn(["results", "knowledgebases"], _newList);
    }
    case UPLOAD_SOURCE: {
      return _.updateIn(["results", "info", "sources"], (sources) =>
        sources.push(Immutable.fromJS(payload.doc)),
      );
    }
    case UPLOAD_SOURCE_DONE: {
      const sources = _.getIn(["results", "info", "sources"]);
      const _newList = sources.map((item) => {
        if (item.get("id") === payload.id) {
          const _newSource = {
            ...item.toJS(),
            status: payload.status,
          };
          return Immutable.fromJS(_newSource);
        }
        return item;
      });
      return _.setIn(["results", "info", "sources"], _newList);
    }
    case UPLOAD_SOURCE_FAIL: {
      const sources = _.getIn(["results", "info", "sources"]);
      const _newList = sources.map((item) => {
        if (item.get("id") === payload.id) {
          const _newSource = {
            ...item.toJS(),
            status: payload.status,
          };
          return Immutable.fromJS(_newSource);
        }
        return item;
      });

      return _.setIn(["results", "info", "sources"], _newList);
    }
    case DEL_SOURCE_DONE: {
      const sources = _.getIn(["results", "info", "sources"]);
      const _newList = sources.filter((item) => item.get("id") !== payload.id);
      return _.setIn(["results", "info", "sources"], _newList);
    }
    case UPD_USER_ROLE_DONE: {
      const rules = _.getIn(["results", "info", "rules"]);
      return _.setIn(
        ["results", "info", "rules"],
        rules.push(Immutable.fromJS(payload.doc)),
      );
    }
    case DEL_USER_ROLE_DONE: {
      const rules = _.getIn(["results", "info", "rules"]);
      const _newList = rules.filter((item) => item.get("id") !== payload.id);
      return _.setIn(["results", "info", "rules"], _newList);
    }
    case UPD_USER_PERMISSION_DONE: {
      const rules = _.getIn(["results", "info", "rules"]);
      const _newList = rules.map((item) => {
        if (item.get("id") === payload.id) {
          const _newRole = { ...item.toJS(), ...payload.permission };
          return Immutable.fromJS(_newRole);
        }
        return item;
      });
      return _.setIn(["results", "info", "rules"], _newList);
    }
    case FCH_NOTIFICATIONS_DONE: {
      return _.set("notifications", Immutable.fromJS(payload.doc));
    }
    case READ_NOTIFICATION_DONE: {
      return _.updateIn(["notifications"], (notifications) =>
        notifications.map((notification) =>
          notification.get("id") === payload.id
            ? notification.merge({ is_read: true })
            : notification,
        ),
      );
    }
    case READ_ALL_NOTIFICATIONS_DONE: {
      return _.updateIn(["notifications"], (notifications) =>
        notifications.map((notification) =>
          notification.merge({ is_read: true }),
        ),
      );
    }
    case DEL_ALL_NOTIFICATIONS_DONE: {
      return _.set("notifications", Immutable.List());
    }
    // case FETCH_SOURCE_SUGGESTED_QUESTIONS: {
    //   return _.setIn(
    //     ["results", "suggestedQuestions", payload.sourceId],
    //     Immutable.fromJS({
    //       loading: true,
    //       data: null,
    //       error: null,
    //     }),
    //   );
    // }

    case SORT_KNOWLEDGEBASES: {
      const { field, order } = payload;
      const _newList = _.getIn(["results", "knowledgebases"]).sort((a, b) => {
        if (field === "created_at") {
          return order === "desc"
            ? a.get("created_at") < b.get("created_at")
              ? 1
              : -1
            : a.get("created_at") < b.get("created_at")
              ? -1
              : 1;
        }
        if (field === "name") {
          const aIsChinese = /[\u4e00-\u9fff]/.test(a.get("name"));
          const bIsChinese = /[\u4e00-\u9fff]/.test(b.get("name"));

          if (aIsChinese && !bIsChinese) return order === "asc" ? -1 : 1;
          if (!aIsChinese && bIsChinese) return order === "asc" ? 1 : -1;
          return order === "asc"
            ? a.get("name").localeCompare(b.get("name"))
            : a.get("name").localeCompare(b.get("name"));
        }
      });
      return _.setIn(["results", "knowledgebases"], _newList).setIn(
        ["config", "sort"],
        { field, order },
      );
    }

    case SET_CURRENT_SOURCE_ID: {
      return _.setIn(["config", "currentSourceId"], payload.sourceId);
    }
    case GET_REFERENCE_DONE: {
      const existingInfo = _.getIn(["results", "info"]);
      if (existingInfo) {
        const updatedSources = Immutable.fromJS(payload.doc).map((item) =>
          Immutable.Map({
            type:
              item.getIn(["metadata", "file_type"]) === "url" ? "url" : "file",
            message: item.get("text"),
            page_number: item.get("page_number"),
            id: item.get("id"),
            path: item.getIn(["metadata", "file_name"]),
            status: "DONE",
          }),
        );
        return _.setIn(
          ["results", "info", "sources"],
          Immutable.fromJS(updatedSources),
        );
      }
      return _.setIn(["results", "info"], Immutable.fromJS(payload.doc));
    }
    case CLEAR_CONVERSATION: {
      return _.setIn(["results", "info"], null).setIn(
        ["config", "currentSourceId"],
        null,
      );
    }
    case USER_LOGOUT: {
      return INIT_STATE;
    }
    default:
      return _;
  }
};

export default knowledgebaseReducer;
