import axios from "axios";

const instance = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.response.use(
  (response) => response,
  (error) => error,
);

const abortControllers = new Map();

const http = {
  cancelTokenSource: null,
  setToken() {
    const storage = localStorage.getItem("ragi");
    if (!storage) return;
    const token = JSON.parse(storage).token;
    instance.defaults.headers.common["auth-header"] = token;
  },
  async get(url) {
    this.setToken();
    try {
      return await instance.get(url);
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  async post(url, data) {
    this.setToken();
    this.cancelTokenSource = axios.CancelToken.source();
    try {
      return await instance.post(url, data, {
        cancelToken: this.cancelTokenSource.token,
      });
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled", error.message);
      } else {
        console.error(error);
      }
      return error;
    }
  },
  async cancelPost() {
    if (this.cancelTokenSource) {
      this.cancelTokenSource.cancel("Operation canceled by the user.");
      this.cancelTokenSource = null;
    }
  },
  async put(url, data) {
    this.setToken();
    try {
      return await instance.put(url, data);
    } catch (error) {
      console.error(error);
    }
  },
  async patch(url, data) {
    this.setToken();
    try {
      return await instance.patch(url, data);
    } catch (error) {
      console.error(error);
    }
  },
  async delete(url) {
    this.setToken();
    try {
      return await instance.delete(url);
    } catch (error) {
      console.error(error);
    }
  },
  async stream(url, data, onStreamData, signalKey = "default") {
    const storage = localStorage.getItem("ragi");
    if (!storage) return;
    const token = JSON.parse(storage).token;
    const controller = new AbortController();
    abortControllers.set(signalKey, controller);
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-header": token,
        },
        body: JSON.stringify(data),
        signal: controller.signal,
      });

      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      // eslint-disable-next-line no-constant-condition
      while (true) {
        const { value, done } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value, { stream: true });
        onStreamData(chunk);
      }
    } catch (error) {
      console.error(error);
    }
  },
  async stopStream(signalKey = "default") {
    const controller = abortControllers.get(signalKey);
    if (controller) {
      controller.abort();
      abortControllers.delete(signalKey);
    }
  },
};

export default http;
