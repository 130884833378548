import http from "app/services";

import {
  DEL_TASK,
  DEL_TASK_DONE,
  FCH_TASK,
  FCH_TASK_DONE,
  FCH_TASK_LIST,
  FCH_TASK_LIST_DONE,
} from "../types/task";
import { statusHandler, successAlert } from "./alert";

export const fetchTasks = (uId) => async (dispatch) => {
  dispatch({ type: FCH_TASK_LIST });
  const res = await http.get(`/api/v1/users/${uId}/translate_tasks`);
  if (res.response && res.response.status >= 400) {
    return dispatch(statusHandler(res.response.status));
  }
  dispatch({ type: FCH_TASK_LIST_DONE, payload: { doc: res.data } });
};

export const fchTask = (uId, tId) => async (dispatch) => {
  dispatch({ type: FCH_TASK });
  const res = await http.get(`/api/v1/users/${uId}/translate_tasks/${tId}`);
  if (res.response && res.response.status >= 400) {
    return dispatch(statusHandler(res.response.status));
  }
  dispatch({ type: FCH_TASK_DONE, payload: { doc: res.data } });
};

export const delTask = (uId, tId) => async (dispatch) => {
  dispatch({ type: DEL_TASK });
  const res = await http.delete(`/api/v1/users/${uId}/translate_tasks/${tId}`);
  if (res.response && res.response.status >= 400) {
    return dispatch(statusHandler(res.response.status));
  }
  dispatch(successAlert("刪除成功"));
  dispatch({ type: DEL_TASK_DONE, payload: { id: tId } });
};
