import JumboIconButton from "@jumbo/components/JumboIconButton";
import Div from "@jumbo/shared/Div";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import {
  Autocomplete,
  Button,
  Checkbox,
  Divider,
  Modal,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import {
  delUserRole,
  updUserPermission,
  updUserRole,
} from "app/redux/actions/knowledgebase";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const RoleModal = ({ roleData, kId, groupData }) => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [roles, setRoles] = useState([]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangePermission = (checked, id, permission, index) => {
    const role = [...roles];
    role[index][permission] = checked;
    const data = {
      r: role[index].r,
      w: role[index].w,
      x: role[index].x,
    };
    dispatch(updUserPermission(kId, id, data));
  };

  const handleChangeRole = (value) => {
    if (value.length < roles.length) {
      // 找出被刪除的 object
      const deletedValue = roles.find(
        (item) => !value.some((val) => val.id === item.id),
      );
      dispatch(delUserRole(kId, deletedValue.id));
    } else if (value.length > roles.length) {
      // 找出被新增的 object
      const addedValue = value.find(
        (item) => !roles.some((role) => role.id === item.id),
      );
      dispatch(updUserRole(kId, addedValue));
    }
  };

  const handleDelRole = (id) => {
    dispatch(delUserRole(kId, id));
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 900,
    bgcolor: "background.paper",
    boxShadow: 24,
    pt: 2,
    pb: 1,
    borderRadius: 1,
  };

  useEffect(() => {
    if (!roleData) return;

    const role = roleData.map((value) => ({
      user_id: value.get("user_id"),
      r: value.get("r"),
      w: value.get("w"),
      x: value.get("x"),
      email: value.get("email"),
      id: value.get("id"),
    }));

    setRoles(role.toJS());
  }, [roleData.size]);

  if (!groupData) return null;

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Div sx={{ ...style }}>
          <Div sx={{ px: 3 }}>
            <Typography variant="h3">權限設定</Typography>
          </Div>
          <Divider sx={{ height: 1, mt: 2, mb: 3 }} />
          <Div sx={{ px: 3 }}>
            <Autocomplete
              sx={{ mb: 3 }}
              multiple
              id="group"
              limitTags={3}
              filterSelectedOptions
              options={groupData.toJS()}
              isOptionEqualToValue={(option, value) =>
                option.email === value.email
              }
              value={roles}
              getOptionLabel={(option) => option.email}
              onChange={(e, value) => handleChangeRole(value)}
              renderInput={(params) => (
                <TextField {...params} label="使用者/群組" />
              )}
            />
            <TableContainer component={Paper} sx={{ mb: 3, maxHeight: 500 }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>使用者/群組</TableCell>
                    <TableCell>讀取</TableCell>
                    <TableCell>修改</TableCell>
                    <TableCell>刪除</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {roles.map((role, index) => (
                    <TableRow key={index}>
                      <TableCell>{role.email}</TableCell>
                      <TableCell>
                        <Checkbox
                          checked={role.r}
                          onChange={(e, checked) =>
                            handleChangePermission(checked, role.id, "r", index)
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <Checkbox
                          checked={role.w}
                          onChange={(e, checked) =>
                            handleChangePermission(checked, role.id, "w", index)
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <Checkbox
                          checked={role.x}
                          onChange={(e, checked) =>
                            handleChangePermission(checked, role.id, "x", index)
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <JumboIconButton onClick={() => handleDelRole(role.id)}>
                          <CancelOutlinedIcon color="error" />
                        </JumboIconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Div>
          <Divider sx={{ height: 1, mt: 3, mb: 1 }} />
          <Stack
            sx={{ px: 3 }}
            flexDirection="row"
            gap={2}
            justifyContent="flex-end"
          >
            <Button variant="contained" color="primary" onClick={handleClose}>
              關閉
            </Button>
          </Stack>
        </Div>
      </Modal>
      <Button
        variant="contained"
        color="primary"
        sx={{ mb: 3 }}
        onClick={handleOpen}
      >
        權限設定
      </Button>
    </>
  );
};

export default RoleModal;

RoleModal.propTypes = {
  roleData: PropTypes.object,
  kId: PropTypes.string,
  groupData: PropTypes.object,
};
