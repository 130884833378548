import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import Immutable from "immutable";
import moment from "moment";
import React from "react";

export const menuConvert = (data) => {
  if (!data) return [];

  const currentDate = moment().format("YYYY-MM-DD");

  const groupedConversations = data.reduce((acc, conversation) => {
    const formattedDate = moment
      .unix(conversation.get("created_at"))
      .format("YYYY-MM-DD");
    if (!acc.has(formattedDate)) {
      acc = acc.set(formattedDate, Immutable.List());
    }
    return acc.update(formattedDate, (list) => list.push(conversation));
  }, Immutable.Map());

  // Sort dates in descending order
  const sortedDates = groupedConversations
    .keySeq()
    .sort(
      (a, b) => moment(b, "YYYY-MM-DD").unix() - moment(a, "YYYY-MM-DD").unix(),
    );

  // Convert to menus format
  const menus = sortedDates.reduce((acc, formattedDate) => {
    const notesForDate = groupedConversations.get(formattedDate);
    const children = notesForDate
      .map((conversation) => {
        return {
          id: conversation.get("id"),
          uri: `/conversation/${conversation.get("id")}`,
          label: conversation.get("name"),
          type: "nav-item",
          icon: <ChatBubbleIcon sx={{ fontSize: 20 }} />,
          // handleOpen: handleOpen,
        };
      })
      .toList();

    let date;

    switch (true) {
      case formattedDate === currentDate:
        // date = t("TODAY");
        date = "今日";
        break;
      case moment(formattedDate, "YYYY-MM-DD").isSame(
        moment().subtract(1, "days"),
        "day",
      ):
        // date = t("YESTERDAY");
        date = "昨日";
        break;
      case moment(formattedDate, "YYYY-MM-DD").isSame(
        moment().subtract(7, "days"),
        "day",
      ):
        // date = t("SEVEN_DAYS");
        date = "七日內";
        break;
      default:
        date = formattedDate;
    }

    acc = acc.push({
      label: date,
      type: "section",
      children: children.toJS(),
    });

    return acc;
  }, Immutable.List());

  return menus;
};
