import http from "app/services";
import moment from "moment";

import {
  CREATE_USER,
  CREATE_USER_DONE,
  DEL_USER,
  DEL_USER_DONE,
  FCH_GROUPS,
  FCH_GROUPS_DONE,
  FCH_OTHER_GROUPS,
  FCH_OTHER_GROUPS_DONE,
  UPD_PERMISSION,
  UPD_PERMISSION_DONE,
  UPD_USER,
  UPD_USER_DONE,
} from "../types/group";
import { statusHandler, successAlert } from "./alert";

export const fchGroups = (id) => {
  return async (dispatch) => {
    dispatch({ type: FCH_GROUPS });
    const res = await http.get(`/api/v1/groups/${id}/users`);
    if (res.response && res.response.status >= 400) {
      return { code: res.response.status, message: "伺服器錯誤" };
    }
    dispatch({
      type: FCH_GROUPS_DONE,
      payload: { doc: res.data },
    });
  };
};

export const fchOtherGroups = (id) => {
  return async (dispatch) => {
    dispatch({ type: FCH_OTHER_GROUPS });
    const res = await http.get(`/api/v1/groups/${id}/other_users`);
    if (res.response && res.response.status >= 400) {
      return dispatch(statusHandler(res.response.status));
    }
    dispatch({
      type: FCH_OTHER_GROUPS_DONE,
      payload: { doc: res.data },
    });
  };
};

export const createUser = (gId, formData) => {
  return async (dispatch) => {
    dispatch({ type: CREATE_USER });
    console.log("formData", formData);
    const res = await http.post(`/api/v1/groups/${gId}/users`, formData);
    if (res.response && res.response.status >= 400) {
      return dispatch(statusHandler(res.response.status));
    }
    const doc = {
      ...formData,
      id: res.data.payload.id,
      group_id: gId,
      created_at: moment().unix(),
    };
    dispatch({ type: CREATE_USER_DONE, payload: { doc } });
    dispatch(successAlert("建立成功"));
  };
};

export const updateUser = (gId, uId, formData) => {
  return async (dispatch) => {
    dispatch({ type: UPD_USER });
    const res = await http.patch(
      `/api/v1/groups/${gId}/users/${uId}`,
      formData,
    );
    if (res.response && res.response.status >= 400) {
      return dispatch(statusHandler(res.response.status));
    }
    const doc = {
      ...formData,
      id: uId,
      group_id: gId,
    };
    dispatch({ type: UPD_USER_DONE, payload: { doc } });
    dispatch(successAlert("更新成功"));
  };
};

export const updUserPermission = (gId, uId, is_enabled) => {
  return async (dispatch) => {
    dispatch({ type: UPD_PERMISSION });
    const res = await http.patch(`/api/v1/groups/${gId}/users/${uId}`, {
      is_enabled,
    });
    if (res.response && res.response.status >= 400) {
      return dispatch(statusHandler(res.response.status));
    }

    dispatch({
      type: UPD_PERMISSION_DONE,
      payload: { is_enabled, id: uId },
    });
    dispatch(successAlert("更新成功"));
  };
};

export const delUser = (gId, uId) => {
  return async (dispatch) => {
    dispatch({ type: DEL_USER });
    const res = await http.delete(`/api/v1/groups/${gId}/users/${uId}`);
    if (res.response && res.response.status >= 400) {
      return dispatch(statusHandler(res.response.status));
    }
    dispatch({ type: DEL_USER_DONE, payload: { id: uId } });
    dispatch(successAlert("刪除成功"));
  };
};
