import Immutable from "immutable";

import {
  ERROR_ALERT,
  INFO_ALERT,
  REMOVE_ALERT,
  SUCCESS_ALERT,
  WARNING_ALERT,
} from "../types/alert";
import {
  ADD_PROMPT_DONE,
  DEL_PROMPT_DONE,
  FCH_USER_PROMPT_DONE,
  HEALTH_CHECK_DONE,
} from "../types/app";
import { FCH_USER_DONE, USER_LOGOUT } from "../types/auth";
import {
  DEL_ALL_NOTIFICATIONS_DONE,
  FCH_NOTIFICATIONS_DONE,
  READ_ALL_NOTIFICATIONS_DONE,
  READ_NOTIFICATION_DONE,
} from "../types/notification";

const INIT_STATE = Immutable.OrderedMap({
  user: null,
  alerts: [],
  health: [],
  notifications: [],
  prompts: null,
});

const appReducer = (_ = INIT_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case FCH_USER_DONE: {
      return _.set("user", payload.doc);
    }
    case USER_LOGOUT: {
      return INIT_STATE;
    }
    case SUCCESS_ALERT: {
      return _.update("alerts", (alerts) => alerts.concat(payload));
    }
    case ERROR_ALERT: {
      return _.update("alerts", (alerts) => alerts.concat(payload));
    }
    case INFO_ALERT: {
      return _.update("alerts", (alerts) => alerts.concat(payload));
    }
    case FCH_USER_PROMPT_DONE: {
      return _.set("prompts", Immutable.fromJS(payload.data));
    }
    case WARNING_ALERT: {
      return _.update("alerts", (alerts) => alerts.concat(payload));
    }
    case REMOVE_ALERT: {
      return _.update("alerts", (alerts) =>
        alerts.filter((n) => n.key !== payload.key),
      );
    }
    case HEALTH_CHECK_DONE: {
      return _.set("health", payload.data);
    }
    case FCH_NOTIFICATIONS_DONE: {
      return _.set("notifications", Immutable.fromJS(payload.doc));
    }
    case READ_NOTIFICATION_DONE: {
      return _.update("notifications", (notifications) =>
        notifications.map((notification) =>
          notification.get("id") === payload.id
            ? notification.merge({ is_read: true })
            : notification,
        ),
      );
    }
    case READ_ALL_NOTIFICATIONS_DONE: {
      return _.update("notifications", (notifications) =>
        notifications.map((notification) => notification.set("is_read", true)),
      );
    }
    case ADD_PROMPT_DONE: {
      return _.update("prompts", (prompts) =>
        prompts.push(Immutable.fromJS(payload.data)),
      );
    }
    case DEL_PROMPT_DONE: {
      return _.update("prompts", (prompts) =>
        prompts.filter((prompt) => prompt.get("id") !== payload.id),
      );
    }
    case DEL_ALL_NOTIFICATIONS_DONE: {
      return _.set("notifications", Immutable.List());
    }
    default:
      return _;
  }
};

export default appReducer;
