export const FCH_CATEGORY_LIST = "MENU/FCH_CATEGORY_LIST";
export const FCH_CATEGORY_LIST_DONE = "MENU/FCH_CATEGORY_LIST_DONE";

export const CREATE_CATEGORY = "MENU/CATEGORY_CREATE";
export const CREATE_CATEGORY_DONE = "MENU/CATEGORY_CREATE_DONE";
export const UPDATE_CATEGORY = "MENU/CATEGORY_UPDATE";
export const UPDATE_CATEGORY_DONE = "MENU/CATEGORY_UPDATE_DONE";
export const CNG_CATEGORY_POSITION = "MENU/CNG_CATEGORY_POSITION";
export const CNG_CATEGORY_POSITION_DONE = "MENU/CNG_CATEGORY_POSITION_DONE";
export const DELETE_CATEGORY = "MENU/CATEGORY_DELETE";
export const DELETE_CATEGORY_DONE = "MENU/CATEGORY_DELETE_DONE";

export const FCH_SUB_LIST = "MENU/FCH_SUB_LIST";
export const FCH_SUB_LIST_DONE = "MENU/FCH_SUB_LIST_DONE";

export const CREATE_SUB_CATEGORY = "MENU/SUB_CREATE";
export const CREATE_SUB_CATEGORY_DONE = "MENU/SUB_CREATE_DONE";
export const UPDATE_SUB_CATEGORY = "MENU/SUB_UPDATE";
export const UPDATE_SUB_CATEGORY_DONE = "MENU/SUB_UPDATE_DONE";
export const CNG_SUB_CATEGORY_POSITION = "MENU/CNG_SUB_CATEGORY_POSITION";
export const CNG_SUB_CATEGORY_POSITION_DONE =
  "MENU/CNG_SUB_CATEGORY_POSITION_DONE";
export const DELETE_SUB_CATEGORY = "MENU/SUB_DELETE";
export const DELETE_SUB_CATEGORY_DONE = "MENU/SUB_DELETE_DONE";
