import ConversationPage from "app/pages/conversation";
import CreateConversation from "app/pages/conversation/create";
import React from "react";

const conversationRoutes = [
  {
    path: "/create/conversation",
    element: <CreateConversation />,
  },
  {
    path: "/conversation/:cId",
    element: <ConversationPage />,
  },
];

export default conversationRoutes;
