import Div from "@jumbo/shared/Div";
import { Button, Modal, Stack, TextField } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

const PromptModal = ({ open, onClose, onSubmit, onChange, value }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 1,
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Div sx={{ ...style, p: 2 }}>
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          gap={1}
        >
          <TextField
            size="small"
            label="請輸入常用指令"
            fullWidth
            value={value}
            onChange={onChange}
          />
          <Button
            variant="contained"
            size="small"
            onClick={onSubmit}
            sx={{ height: 40 }}
          >
            新增
          </Button>
        </Stack>
      </Div>
    </Modal>
  );
};

export default PromptModal;

PromptModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};
