import http from "app/services";
import moment from "moment";

import {
  CNG_CATEGORY_POSITION,
  CNG_CATEGORY_POSITION_DONE,
  CNG_SUB_CATEGORY_POSITION,
  CNG_SUB_CATEGORY_POSITION_DONE,
  CREATE_CATEGORY,
  CREATE_CATEGORY_DONE,
  CREATE_SUB_CATEGORY,
  CREATE_SUB_CATEGORY_DONE,
  DELETE_CATEGORY,
  DELETE_CATEGORY_DONE,
  DELETE_SUB_CATEGORY,
  DELETE_SUB_CATEGORY_DONE,
  FCH_CATEGORY_LIST,
  FCH_CATEGORY_LIST_DONE,
  FCH_SUB_LIST,
  FCH_SUB_LIST_DONE,
  UPDATE_CATEGORY,
  UPDATE_CATEGORY_DONE,
  UPDATE_SUB_CATEGORY,
  UPDATE_SUB_CATEGORY_DONE,
} from "../types/menu";
import { statusHandler, successAlert } from "./alert";

export const fchSubCategoryList = () => {
  return async (dispatch) => {
    dispatch({ type: FCH_SUB_LIST });
    const res = await http.get("/api/v1/menus");
    dispatch({ type: FCH_SUB_LIST_DONE, payload: { doc: res.data } });
  };
};

export const createSubCategory = (data) => {
  return async (dispatch) => {
    dispatch({ type: CREATE_SUB_CATEGORY });
    const res = await http.post("/api/v1/menus", data);
    if (res.response && res.response.status >= 400) {
      return dispatch(statusHandler(res.response.status));
    }
    const id = res.data.payload.id;
    const doc = {
      ...data,
      id,
      created_at: moment().unix(),
    };
    dispatch({ type: CREATE_SUB_CATEGORY_DONE, payload: { doc } });
  };
};

export const updateSubCategory = (id, data) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_SUB_CATEGORY });
    const res = await http.patch(`/api/v1/menus/${id}`, data);
    if (res.response && res.response.status >= 400) {
      return dispatch(statusHandler(res.response.status));
    }
    const doc = {
      ...data,
      id,
    };
    dispatch(successAlert("更新成功"));
    dispatch({ type: UPDATE_SUB_CATEGORY_DONE, payload: { id, doc } });
  };
};

export const cngSubCategoryPosition = (active, over) => {
  return async (dispatch) => {
    dispatch({ type: CNG_SUB_CATEGORY_POSITION });
    const res = await http.patch(`/api/v1/menus/${active.id}`, {
      sn: active.sn,
    });

    if (res.response && res.response.status >= 400) {
      return dispatch(statusHandler(res.response.status));
    }

    const id = active.category_id;

    const doc = [active, over];
    dispatch({ type: CNG_SUB_CATEGORY_POSITION_DONE, payload: { id, doc } });
  };
};

export const deleteSubCategory = (id) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_SUB_CATEGORY });
    const res = await http.delete(`/api/v1/menus/${id}`);
    if (res.response && res.response.status >= 400) {
      return dispatch(statusHandler(res.response.status));
    }
    dispatch(successAlert("刪除成功"));
    dispatch({ type: DELETE_SUB_CATEGORY_DONE, payload: { id } });
  };
};

export const fchCategoryList = () => {
  return async (dispatch) => {
    dispatch({ type: FCH_CATEGORY_LIST });
    const res = await http.get(`/api/v1/categories`);
    const categoryId = res.data.map((item) => item.id);
    const fchSubCategoryListByCategory = async (id) => {
      const res = await http.get(`/api/v1/categories/${id}`);
      return res.data;
    };
    const fchCategoryList = async () => {
      const data = categoryId.map((id) => fchSubCategoryListByCategory(id));
      const result = await Promise.all(data);
      return result;
    };
    const categoryList = await fchCategoryList();
    dispatch({ type: FCH_CATEGORY_LIST_DONE, payload: { doc: categoryList } });
  };
};

export const createCategory = (data) => {
  return async (dispatch) => {
    dispatch({ type: CREATE_CATEGORY });
    const res = await http.post(`/api/v1/categories`, data);
    if (res.response && res.response.status >= 400) {
      return dispatch(statusHandler(res.response.status));
    }
    const doc = {
      ...data,
      id: res.data.payload.id,
      created_at: moment().unix(),
      menus: [],
    };
    dispatch(successAlert("新增成功"));
    dispatch({ type: CREATE_CATEGORY_DONE, payload: { doc } });
  };
};

export const updateCategory = (id, data) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_CATEGORY });
    const res = await http.patch(`/api/v1/categories/${id}`, data);
    if (res.response && res.response.status >= 400) {
      return dispatch(statusHandler(res.response.status));
    }
    const doc = {
      ...data,
      id,
    };
    dispatch(successAlert("更新成功"));
    dispatch({ type: UPDATE_CATEGORY_DONE, payload: { id, doc } });
  };
};

export const cngCategoryPosition = (active, over) => {
  return async (dispatch) => {
    dispatch({ type: CNG_CATEGORY_POSITION });
    const res = await http.patch(`/api/v1/categories/${active.id}`, {
      sn: active.sn,
    });

    if (res.response && res.response.status >= 400) {
      return dispatch(statusHandler(res.response.status));
    }

    const doc = [active, over];
    dispatch({ type: CNG_CATEGORY_POSITION_DONE, payload: { doc } });
  };
};

export const deleteCategory = (id) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_CATEGORY });
    const res = await http.delete(`/api/v1/categories/${id}`);
    if (res.response && res.response.status >= 400) {
      return dispatch(statusHandler(res.response.status));
    }
    dispatch(successAlert("刪除成功"));
    dispatch({ type: DELETE_CATEGORY_DONE, payload: { id } });
  };
};
