import Div from "@jumbo/shared/Div";
import {
  Button,
  Divider,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { createKnowledgebase } from "app/redux/actions/knowledgebase";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

const CreateKnowledgebaseModal = ({ uId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    setName("");
  };

  const handleCreate = async () => {
    const id = await dispatch(createKnowledgebase(uId, name));
    onClose();
    if (id) {
      navigate(`/knowledgebase/${id}`);
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    pt: 2,
    pb: 1,
    borderRadius: 1,
  };
  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Div sx={{ ...style }}>
          <Div sx={{ px: 3 }}>
            <Typography variant="h3">請輸入知識庫名稱</Typography>
          </Div>

          <Divider sx={{ height: 1, mt: 2, mb: 3 }} />

          <Div sx={{ px: 3 }}>
            <TextField
              value={name}
              onChange={(e) => setName(e.target.value)}
              fullWidth
              placeholder="知識庫名稱"
              variant="outlined"
            />
          </Div>

          <Divider sx={{ height: 1, mt: 3, mb: 1 }} />

          <Div sx={{ px: 1 }}>
            <Stack
              flexDirection="row"
              alignItems="center"
              justifyContent="flex-end"
              gap={1}
            >
              <Button variant="outlined" onClick={onClose}>
                取消
              </Button>
              <Button variant="contained" onClick={handleCreate}>
                建立
              </Button>
            </Stack>
          </Div>
        </Div>
      </Modal>
      <Button variant="contained" onClick={onOpen}>
        建立
      </Button>
    </>
  );
};

export default CreateKnowledgebaseModal;
