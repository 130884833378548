import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Button, Card, CardContent, Divider, Typography } from "@mui/material";
import { Stack } from "@mui/material";
import { translate } from "app/redux/actions/plugin";
import FileSection from "app/shared/Form/FileSection";
import SelectSection from "app/shared/Form/SelectSection";
import SliderSection from "app/shared/Form/SliderSection";
import TextAreaSection from "app/shared/Form/TextAreaSection";
import UploadSection from "app/shared/Form/UploadSection";
import Loading from "app/shared/Loading";
import PDF_PARSER from "app/utils/constants/pdfParser";
import { translateLangDict } from "app/utils/constants/translateLangDict";
import { useFormik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import * as XLSX from "xlsx";
import * as yup from "yup";

const TranslateMasterPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const modelData = useSelector(({ model }) =>
    model.getIn(["results", "models"]),
  );

  const uId = useSelector(({ app }) => app.getIn(["user", "id"]));

  const initialValues = {
    sources: [],
    source_lang: { label: "繁體中文", value: "zh-tw" },
    target_lang: { label: "英文", value: "en-us" },
    output_mode: { label: "取代原文", value: "replace" },
    translation_word_dict: {},
    model_name: null,
    temperature: 0.5,
    prompt: "",
    pdf_engine: PDF_PARSER[0],
  };

  const validationSchema = yup.object({
    sources: yup.array().required("請上傳檔案"),
    source_lang: yup.object().required("請選擇來源語言"),
    target_lang: yup.object().required("請選擇目標語言"),
    model_name: yup.object().required("請選擇語言模型"),
    output_mode: yup.object().required("請選擇輸出格式"),
    translation_word_dict: yup.object(),
    temperature: yup.number().required("請設定溫度"),
    prompt: yup.string(),
    pdf_engine: yup.object().when("sources", {
      is: (sources) => sources.length > 0 && sources[0].path.endsWith(".pdf"),
      then: () => yup.object().required("請選擇解析器"),
      otherwise: () => yup.object().notRequired(),
    }),
  });

  const handleSubmit = async (values) => {
    const data = {
      model_name: values.model_name.value,
      file_path: values.sources[0].path,
      source_lang: values.source_lang.value,
      target_lang: values.target_lang.value,
      output_mode: values.output_mode.value,
      translation_word_dict: values.translation_word_dict,
      temperature: values.temperature,
      prompt: values.prompt,
      pdf_engine: values.pdf_engine.value,
    };

    const success = await dispatch(translate(uId, data));
    if (!success) return;
    navigate("/task");
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  const handleUploadSource = (data) => {
    formik.setFieldValue("sources", data);
  };

  const handleDeleteFile = () => {
    formik.setFieldValue("translation_word_dict", {});
  };

  const handleUploadTranslationWordDict = (e) => {
    const file = e.target.files[0];

    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array", codepage: 65001 });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];

      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: "A" });

      const result = jsonData.reduce((acc, row) => {
        const key = row.A;
        const value = row.B;
        acc[key] = value;
        return acc;
      }, {});

      formik.setFieldValue("translation_word_dict", result);
    };
    reader.readAsArrayBuffer(file);

    return [{ filename: file.name, filetype: file.type }];
  };

  if (!modelData) return <Loading />;

  return (
    <Card sx={{ p: 4 }}>
      <Typography variant="h3">翻譯設定</Typography>

      <Divider sx={{ my: 4 }} />

      <CardContent sx={{ p: 0 }}>
        <form onSubmit={formik.handleSubmit}>
          <UploadSection
            position="left"
            showPath
            supportType={["docx", "pptx", "pdf", "txt"]}
            tooltip={
              <>
                <Typography sx={{ fontSize: 12 }}>
                  支援格式：沒有加密的 .docx、.pptx、.pdf、.txt
                </Typography>
                <Typography sx={{ fontSize: 12 }}>
                  建議格式：.docx 與 .pptx 效果比較好；其他如 .pdf、.txt
                  等格式的輸出為 .docx
                </Typography>
              </>
            }
            value={formik.values.sources}
            onFinish={handleUploadSource}
            error={formik.touched.sources && Boolean(formik.errors.sources)}
            helperText={formik.touched.sources && formik.errors.sources}
          />

          <FileSection
            showPath
            title="請選擇對照詞檔案"
            tooltip={
              <>
                <Typography sx={{ fontSize: 12 }}>
                  請上傳.csv、.xlsx，內容包含原文與翻譯
                </Typography>
                <Typography sx={{ fontSize: 12 }}>
                  {`請至上方選單連結 > MyNova醬使用手冊 > 範例檔案_字詞Mapping，下載範例檔案`}
                </Typography>
              </>
            }
            onProcess={handleUploadTranslationWordDict}
            onDelete={handleDeleteFile}
            supportType={["xlsx", "csv"]}
            error={
              formik.touched.translation_word_dict &&
              Boolean(formik.errors.translation_word_dict)
            }
            helperText={
              formik.touched.translation_word_dict &&
              formik.errors.translation_word_dict
            }
          />

          <Typography variant="h4" sx={{ mb: 3 }}>
            選擇翻譯語言
          </Typography>
          <Stack flexDirection="row" gap={4} alignItems="center">
            <SelectSection
              title="來源語言"
              name="source_lang"
              value={formik.values.source_lang}
              onChange={formik.handleChange}
              defaultOptionLabel="翻譯的語言"
              options={Object.entries(translateLangDict).map(
                ([value, label]) => ({
                  label,
                  value,
                }),
              )}
              error={
                formik.touched.source_lang && Boolean(formik.errors.source_lang)
              }
              helperText={
                formik.touched.source_lang && formik.errors.source_lang
              }
            />
            <ArrowForwardIcon sx={{ fontSize: 30 }} />
            <SelectSection
              title="目標語言"
              name="target_lang"
              defaultOptionLabel="請選擇翻譯後的語言"
              value={formik.values.target_lang}
              onChange={formik.handleChange}
              options={Object.entries(translateLangDict).map(
                ([value, label]) => ({
                  label,
                  value,
                }),
              )}
              error={
                formik.touched.target_lang && Boolean(formik.errors.target_lang)
              }
              helperText={
                formik.touched.target_lang && formik.errors.target_lang
              }
            />
          </Stack>

          <SelectSection
            title="語言模型"
            name="model_name"
            defaultOptionLabel="選擇語言模型"
            value={formik.values.model_name}
            onChange={formik.handleChange}
            options={modelData
              .map((model) => ({
                value: model.get("name"),
                label: model.get("name"),
              }))
              .toJS()}
            error={
              formik.touched.model_name && Boolean(formik.errors.model_name)
            }
            helperText={formik.touched.model_name && formik.errors.model_name}
          />
          <SelectSection
            title="輸出格式"
            name="output_mode"
            value={formik.values.output_mode}
            onChange={formik.handleChange}
            defaultOptionLabel="請選擇輸出格式"
            options={[
              { label: "取代原文", value: "replace" },
              { label: "文章比對", value: "parallel" },
            ]}
            error={
              formik.touched.output_mode && Boolean(formik.errors.output_mode)
            }
            helperText={formik.touched.output_mode && formik.errors.output_mode}
          />
          {formik.values.sources.length > 0 &&
            formik.values.sources[0].path.endsWith(".pdf") && (
              <SelectSection
                title="解析器"
                name="pdf_engine"
                value={formik.values.pdf_engine}
                onChange={formik.handleChange}
                defaultOptionLabel="請選擇解析器"
                options={PDF_PARSER}
                error={
                  formik.touched.pdf_engine && Boolean(formik.errors.pdf_engine)
                }
                helperText={
                  formik.touched.pdf_engine && formik.errors.pdf_engine
                }
              />
            )}
          <TextAreaSection
            title="翻譯提示"
            name="prompt"
            tooltip="可以給予翻譯的特定指示或風格要求"
            value={formik.values.prompt}
            onChange={formik.handleChange}
            placeholder="例如：請以正式的商業語言進行翻譯"
          />
          <SliderSection
            title="活潑度(溫度)"
            name="temperature"
            tooltip="Temperature 數字越高 代表 回答的創造性 越高; 反之 則越精確"
            marks={[
              { value: 0, label: "0" },
              { value: 1, label: "1" },
            ]}
            step={0.1}
            value={formik.values.temperature}
            onChange={formik.handleChange}
          />
          <Button type="submit" variant="contained" color="primary">
            送出
          </Button>
        </form>
      </CardContent>
    </Card>
  );
};

export default TranslateMasterPage;
