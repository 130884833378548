import MenuPage from "app/pages/admin/menu";
import SubMenuPage from "app/pages/admin/menu/subMuen";
import React from "react";

const adminMenuRoutes = [
  {
    path: "/admin/menu",
    element: <MenuPage />,
  },
  {
    path: "/admin/menu/:id",
    element: <SubMenuPage />,
  },
];

export default adminMenuRoutes;
