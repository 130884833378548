import { TextField } from "@mui/material";
import { useReactFlow } from "@xyflow/react";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";

import NodeWrapper from "./NodeWrapper";

const TextWriterNode = ({ id, data }) => {
  const [text, setText] = useState(data.text || "");
  const { setNodes } = useReactFlow();

  const updateNodeData = useCallback(
    (newData) => {
      setNodes((nds) =>
        nds.map((node) => {
          if (node.id === id) {
            node.data = { ...node.data, ...newData };
          }
          return node;
        }),
      );
    },
    [id, setNodes],
  );

  const handleTextChange = (e) => {
    const newText = e.target.value;
    setText(newText);
    updateNodeData({ text: newText });
  };

  return (
    <NodeWrapper
      id={id}
      title="Text Writer Node"
      initialInputs={[{ id: "input", label: "input" }]}
      initialOutputs={[]}
      data={data}
      width={300}
      color="#E53935"
      setNodes={setNodes}
      canAddInputs={false}
      canAddOutputs={false}
      canDeleteInputs={false}
      canDeleteOutputs={false}
    >
      <TextField
        id={`text-${id}`}
        label="Text"
        value={text}
        onChange={handleTextChange}
        placeholder="Enter text"
        fullWidth
        margin="normal"
        variant="outlined"
        multiline
        rows={4}
      />
    </NodeWrapper>
  );
};

TextWriterNode.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.shape({
    text: PropTypes.string,
  }).isRequired,
};

export default TextWriterNode;
