import Div from "@jumbo/shared/Div";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import {
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { uploadSource } from "app/redux/actions/knowledgebase";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import React from "react";
import { useDispatch } from "react-redux";
import * as yup from "yup";

const UrlSection = ({ kId, onClose }) => {
  const dispatch = useDispatch();

  const handleSubmit = async (values) => {
    const convertToCrontab = (type, schedules) => {
      if (type === "once") return [];
      if (type === "normal") return ["* * * * *"];
      return schedules.map(({ hour, minute }) => {
        return `${minute} ${hour} * * *`;
      });
    };

    const data = {
      type: "url",
      path: values.url,
      crontab: convertToCrontab(values.cronType, values.crontab),
    };

    await dispatch(uploadSource(kId, data));
    onClose();
  };

  const formik = useFormik({
    initialValues: {
      url: "",
      cronType: "once",
      crontab: [],
    },
    validationSchema: yup.object({
      url: yup.string().url().required("請輸入正確網址"),
      cronType: yup.string().required("請選擇排程類型"),
      crontab: yup.array().when("cronType", {
        is: "customer",
        then: () => yup.array().min(1, "請輸入至少一組Crontab排程"),
        otherwise: () => yup.array().notRequired(),
      }),
    }),
    onSubmit: handleSubmit,
  });

  const handleChangeCronType = (e) => {
    formik.setFieldValue("cronType", e.target.value);
    formik.setFieldValue("crontab", [{ hour: 0, minute: 0 }]);
  };

  const handleCrontabChange = (e, index, type) => {
    const newCrontab = [...formik.values.crontab];
    newCrontab[index][type] = e.target.value;
    formik.setFieldValue("crontab", newCrontab);
  };

  const handleAddCrontab = () => {
    if (formik.values.crontab.length >= 3) {
      return;
    }
    const newCrontab = [...formik.values.crontab];
    newCrontab.push({ hour: 0, minute: 0 });
    formik.setFieldValue("crontab", newCrontab);
  };

  const handleRemoveCrontab = (index) => {
    if (index === 0 && formik.values.crontab.length === 1) {
      formik.setFieldValue("cronType", "once");
      formik.setFieldValue("crontab", []);
      return;
    }

    const newCrontab = [...formik.values.crontab];
    newCrontab.splice(index, 1);
    formik.setFieldValue("crontab", newCrontab);
  };

  return (
    <>
      <Div>
        <form onSubmit={formik.handleSubmit}>
          <Stack sx={{ px: 3 }} gap={2}>
            <TextField
              name="url"
              label="網址"
              fullWidth
              placeholder="請輸入網址"
              value={formik.values.url}
              onChange={formik.handleChange}
              error={formik.touched.url && Boolean(formik.errors.url)}
              helperText={formik.touched.url && formik.errors.url}
            />
            <FormControl sx={{ width: "100%" }}>
              <InputLabel>排程類型</InputLabel>
              <Select
                label="排程類型"
                name="cronType"
                value={formik.values.cronType}
                onChange={handleChangeCronType}
              >
                <MenuItem value="once">只執行一次</MenuItem>
                <MenuItem value="normal">每天執行</MenuItem>
                <MenuItem value="customer">自訂排程</MenuItem>
              </Select>
            </FormControl>

            {formik.values.cronType === "customer" &&
              formik.values.crontab.map((cron, index) => (
                <Grid container spacing={1} key={index}>
                  <Grid item md={5} key={index}>
                    <FormControl sx={{ width: "100%" }}>
                      <InputLabel>小時</InputLabel>
                      <Select
                        value={cron.hour}
                        label="小時"
                        onChange={(e) => handleCrontabChange(e, index, "hour")}
                      >
                        {Array.from({ length: 24 }, (_, i) => i).map((hour) => (
                          <MenuItem key={hour} value={hour}>
                            {hour.toString().padStart(2, "0")}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item md={5}>
                    <FormControl sx={{ width: "100%" }}>
                      <InputLabel>分</InputLabel>
                      <Select
                        value={cron.minute}
                        label="分"
                        onChange={(e) =>
                          handleCrontabChange(e, index, "minute")
                        }
                      >
                        {Array.from({ length: 12 }, (_, i) => i * 5).map(
                          (minute) => (
                            <MenuItem key={minute} value={minute}>
                              {minute.toString().padStart(2, "0")}
                            </MenuItem>
                          ),
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item md={2}>
                    <Stack
                      direction="row"
                      height="100%"
                      alignItems="center"
                      gap={1}
                    >
                      {index < 2 && (
                        <IconButton sx={{ p: 0 }} onClick={handleAddCrontab}>
                          <AddCircleOutlineIcon />
                        </IconButton>
                      )}
                      <IconButton
                        sx={{ p: 0 }}
                        onClick={() => handleRemoveCrontab(index)}
                      >
                        <RemoveCircleOutlineIcon />
                      </IconButton>
                    </Stack>
                  </Grid>
                </Grid>
              ))}
          </Stack>

          <Divider sx={{ height: 1, mt: 3, mb: 2 }} />

          <Div sx={{ px: 3 }}>
            <Button variant="contained" color="primary" type="submit" fullWidth>
              新增
            </Button>
          </Div>
        </form>
      </Div>
    </>
  );
};

export default UrlSection;

UrlSection.propTypes = {
  kId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};
