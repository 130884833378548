export const FCH_MODELS = "MODEL/FCH_MODELS";
export const FCH_MODELS_DONE = "MODEL/FCH_MODELS_DONE";

export const FCH_MODEL = "MODEL/FCH_MODEL";
export const FCH_MODEL_DONE = "MODEL/FCH_MODEL_DONE";

export const CREATE_MODEL = "MODEL/CREATE_MODEL";
export const CREATE_MODEL_DONE = "MODEL/CREATE_MODEL_DONE";

export const UPDATE_MODEL = "MODEL/UPDATE_MODEL";
export const UPDATE_MODEL_DONE = "MODEL/UPDATE_MODEL_DONE";

export const DELETE_MODEL = "MODEL/DELETE_MODEL";
export const DELETE_MODEL_DONE = "MODEL/DELETE_MODEL_DONE";

export const ADD_MODEL_RULES = "MODEL/ADD_RULES";
export const ADD_MODEL_RULES_DONE = "MODEL/ADD_RULES_DONE";

export const ADD_ALL_MODEL_RULES = "MODEL/ADD_ALL_RULES";
export const ADD_ALL_MODEL_RULES_DONE = "MODEL/ADD_ALL_RULES_DONE";

export const UPDATE_MODEL_RULES = "MODEL/UPDATE_RULES";
export const UPDATE_MODEL_RULES_DONE = "MODEL/UPDATE_RULES_DONE";

export const DELETE_MODEL_RULES = "MODEL/DELETE_RULES";
export const DELETE_MODEL_RULES_DONE = "MODEL/DELETE_RULES_DONE";

export const DELETE_ALL_MODEL_RULES = "MODEL/DELETE_ALL_RULES";
export const DELETE_ALL_MODEL_RULES_DONE = "MODEL/DELETE_ALL_RULES_DONE";
