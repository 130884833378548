import PluginManagePage from "app/pages/plugin";
import React from "react";

import executeRoute from "./execute";
import ThesisMasterRoutes from "./thesismaster";
import TranslateMasterRoutes from "./translatemaster";

const pluginManageRoutes = [
  {
    path: "/plugins",
    element: <PluginManagePage />,
  },
];

const pluginRoutes = [
  ...pluginManageRoutes,
  ...ThesisMasterRoutes,
  ...TranslateMasterRoutes,
  ...executeRoute,
];

export default pluginRoutes;
