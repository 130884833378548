import JumboDdPopover from "@jumbo/components/JumboDdPopover";
import JumboIconButton from "@jumbo/components/JumboIconButton";
import { useJumboHeaderTheme, useJumboTheme } from "@jumbo/hooks";
import Div from "@jumbo/shared/Div";
import NotificationImportantOutlinedIcon from "@mui/icons-material/NotificationImportantOutlined";
import {
  Badge,
  CardActions,
  ListItem,
  ListItemText,
  ThemeProvider,
} from "@mui/material";
import Button from "@mui/material/Button";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import usePageVisibility from "app/hooks/usePageVisibility";
import {
  delAllNotifications,
  fchNotifications,
  readAllNotifications,
  readNotification,
} from "app/redux/actions/notification";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import NotificationPost from "./NotificationPost";

function NotificationsDropdown() {
  const dispatch = useDispatch();
  const isPageVisible = usePageVisibility();

  const { theme } = useJumboTheme();
  const { headerTheme } = useJumboHeaderTheme();
  const notifications = useSelector(({ app }) => app.get("notifications"));
  const user_id = useSelector(({ app }) => app.getIn(["user", "id"]));

  const handleReadNotification = (user_id, id) => {
    const isRead = notifications
      .find((item) => item.get("id") === id)
      ?.get("is_read");
    if (isRead) return;
    dispatch(readNotification(user_id, id));
  };

  useEffect(() => {
    const env = process.env.NODE_ENV;
    if (env === "development") return;
    const intervalId = setInterval(() => {
      const storage = localStorage.getItem("ragi");
      const userId = storage ? JSON.parse(storage).data.id : null;
      if (userId && isPageVisible) {
        dispatch(fchNotifications(userId));
      }
    }, 10000);

    return () => clearInterval(intervalId);
  }, [dispatch, isPageVisible]);

  if (!notifications) return null;

  const unreadCount = notifications.filter(
    (item) => item.get("is_read") === false,
  ).size;

  return (
    <ThemeProvider theme={theme}>
      <JumboDdPopover
        triggerButton={
          <ThemeProvider theme={headerTheme}>
            <JumboIconButton
              sx={{
                width: 40,
                height: 40,
                boxShadow: 25,
                padding: 1.25,
              }}
            >
              <Badge
                badgeContent={unreadCount}
                color="error"
                max={99}
                sx={{
                  "& .MuiBadge-badge": {
                    top: -5,
                    right: -5,
                    width: 6,
                    border: 2,
                    borderColor: "common.white",
                  },
                }}
              >
                <NotificationImportantOutlinedIcon
                  sx={{ fontSize: "1.25rem" }}
                />
              </Badge>
            </JumboIconButton>
          </ThemeProvider>
        }
        disableInsideClick
      >
        <Div sx={{ width: 360, maxWidth: "100%" }}>
          <CardHeader
            title={`通知 (${notifications.size})`}
            sx={{
              borderBottom: "1px solid #e0e0e0",
            }}
            action={
              <Button onClick={() => dispatch(delAllNotifications(user_id))}>
                刪除全部
              </Button>
            }
          />
          <List disablePadding>
            {notifications.size === 0 && (
              <ListItem>
                <ListItemText sx={{ textAlign: "center" }}>
                  目前沒有任何通知
                </ListItemText>
              </ListItem>
            )}

            {notifications.size !== 0 &&
              notifications.map((item) => {
                return (
                  <NotificationPost
                    isRead={item.get("is_read")}
                    key={item.get("id")}
                    item={item}
                    onUpdate={handleReadNotification}
                  />
                );
              })}
          </List>
          <Divider />
          <CardActions sx={{ justifyContent: "center" }}>
            <Button
              sx={{
                textTransform: "none",
                fontWeight: "normal",
                "&:hover": { bgcolor: "transparent" },
              }}
              onClick={() => dispatch(readAllNotifications(user_id))}
              size="small"
              variant="text"
              disableRipple
            >
              讀取全部
            </Button>
          </CardActions>
        </Div>
      </JumboDdPopover>
    </ThemeProvider>
  );
}

export default NotificationsDropdown;
