import ModelPage from "app/pages/admin/model";
import ModelEditPage from "app/pages/admin/model/edit";
import React from "react";

const adminModelRoutes = [
  {
    path: "/admin/model",
    element: <ModelPage />,
  },
  {
    path: "/admin/model/:id",
    element: <ModelEditPage />,
  },
];

export default adminModelRoutes;
