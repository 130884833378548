import { Chart, registerables } from "chart.js";
import PropTypes from "prop-types";
import React from "react";
import { Line } from "react-chartjs-2";

Chart.register(...registerables);

const LineChart = ({ data, chartRef }) => {
  return <Line ref={chartRef} data={data} />;
};

export default LineChart;

LineChart.propTypes = {
  data: PropTypes.object.isRequired,
  chartRef: PropTypes.object.isRequired,
};
