import http from "app/services";

import {
  DEL_ALL_NOTIFICATIONS,
  DEL_ALL_NOTIFICATIONS_DONE,
  FCH_NOTIFICATIONS,
  FCH_NOTIFICATIONS_DONE,
  READ_ALL_NOTIFICATIONS,
  READ_ALL_NOTIFICATIONS_DONE,
  READ_NOTIFICATION,
  READ_NOTIFICATION_DONE,
} from "../types/notification";
import { statusHandler } from "./alert";

export const fchNotifications = (uId) => {
  return async (dispatch) => {
    dispatch({ type: FCH_NOTIFICATIONS });
    const res = await http.get(`/api/v1/users/${uId}/notifications`);
    dispatch({ type: FCH_NOTIFICATIONS_DONE, payload: { doc: res.data } });
  };
};

export const readNotification = (uId, nId) => {
  return async (dispatch) => {
    dispatch({ type: READ_NOTIFICATION });
    const res = await http.patch(`/api/v1/users/${uId}/notifications/${nId}`, {
      is_read: true,
    });
    if (res.response && res.response.status >= 400) {
      return dispatch(statusHandler(res.response.status));
    }
    dispatch({ type: READ_NOTIFICATION_DONE, payload: { id: nId } });
  };
};

export const readAllNotifications = (uId) => {
  return async (dispatch) => {
    dispatch({ type: READ_ALL_NOTIFICATIONS });
    const res = await http.patch(
      `/api/v1/users/${uId}/notifications/_read_all`,
    );
    if (res.response && res.response.status >= 400) {
      return dispatch(statusHandler(res.response.status));
    }
    dispatch({ type: READ_ALL_NOTIFICATIONS_DONE });
  };
};

export const delAllNotifications = (uId) => {
  return async (dispatch) => {
    dispatch({ type: DEL_ALL_NOTIFICATIONS });
    const res = await http.delete(`/api/v1/users/${uId}/notifications/_all`);
    if (res.response && res.response.status >= 400) {
      return dispatch(statusHandler(res.response.status));
    }
    dispatch({ type: DEL_ALL_NOTIFICATIONS_DONE });
  };
};
