import Immutable from "immutable";

import {
  DEL_TASK_DONE,
  FCH_TASK_DONE,
  FCH_TASK_LIST_DONE,
} from "../types/task";

const INIT_STATE = Immutable.OrderedMap({
  results: null,
});

const taskReducer = (_ = INIT_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case FCH_TASK_LIST_DONE: {
      const doc = Immutable.fromJS(payload.doc).map((task) =>
        task.update("status", (status) => status.toUpperCase()),
      );
      return _.set("results", doc);
    }
    case FCH_TASK_DONE: {
      const doc = Immutable.fromJS(payload.doc);
      return _.update("results", (tasks) => {
        const updatedDoc = tasks.map((task) => {
          if (task.get("id") === doc.get("id")) {
            return doc;
          }
          return task;
        });
        return updatedDoc;
      });
    }
    case DEL_TASK_DONE: {
      const id = payload.id;
      return _.update("results", (tasks) => {
        return tasks.filter((task) => task.get("id") !== id);
      });
    }
    default:
      return _;
  }
};

export default taskReducer;
