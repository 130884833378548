import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import SendIcon from "@mui/icons-material/Send";
import StopCircleOutlinedIcon from "@mui/icons-material/StopCircleOutlined";
import {
  Button,
  CircularProgress,
  IconButton,
  InputBase,
  Paper,
  Stack,
  Tooltip,
} from "@mui/material";
import { List } from "immutable";
import PropTypes from "prop-types";
import React, { useState } from "react";

import SuggestedQuestionModal from "../Modal/SuggestedQuestionModal";
import PromptPopover from "../PromptMenu/PromptPopover";

const RESET_MESSAGE = "對話已重置！";

const InputSection = ({
  logs,
  message,
  isWaiting,
  promptList,
  isStreamMode,
  isLoading,
  suggestedQuestions,
  handleChange,
  handleSendMessage,
  handleStopStream,
  handleStopBatch,
  handleResetConversation,
}) => {
  const [isOnComposition, setIsOnComposition] = useState(false);

  const handleComposition = (e) => {
    if (e.type === "compositionend") {
      // composition is end
      setIsOnComposition(false);
    } else {
      // in composition
      setIsOnComposition(true);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (event.shiftKey || event.altKey) {
        // Shift+Enter 或 Alt+Enter 插入新行
        event.preventDefault();
        const start = event.target.selectionStart;
        const end = event.target.selectionEnd;
        const newValue =
          message.substring(0, start) + "\n" + message.substring(end);

        // 更新輸入框的值並恢復光標位置
        handleChange(newValue);
        setTimeout(() => {
          event.target.setSelectionRange(start + 1, start + 1);
        }, 0);
      } else if (!isOnComposition) {
        // Enter 提交消息
        event.preventDefault();
        handleSendMessage();
        handleChange("");
      }
    }
  };

  const handleStopMessage = () => {
    if (isStreamMode) {
      console.log("stop stream");
      handleStopStream();
    } else {
      console.log("stop batch");
      handleStopBatch();
    }
  };

  const handleSendSuggestedQuestion = (question) => {
    handleChange(question);
  };

  const handleSendPrompt = (prompt) => {
    handleChange(prompt);
  };

  return (
    <Stack flexDirection="column" gap={1}>
      <Stack
        alignItems="flex-end"
        direction="row"
        justifyContent="space-between"
        sx={{
          top: -38,
          width: "100%",
          px: 3,
          backgroundColor: "rgba(0,0,0,0)",
        }}
      >
        <Stack direction="row" gap={1}>
          {(logs.size === 0 ||
            logs.last().get("message") === RESET_MESSAGE) && (
            <SuggestedQuestionModal
              suggestedQuestions={suggestedQuestions}
              onClick={handleSendSuggestedQuestion}
            />
          )}
          <PromptPopover
            promptList={promptList}
            onSendPrompt={handleSendPrompt}
          />
        </Stack>
        <Tooltip title="讓語言模型忘掉前面的對話內容" placement="top-end">
          <Button
            size="small"
            variant="outlined"
            onClick={handleResetConversation}
          >
            重置對話
            <ErrorOutlineIcon
              sx={{ fontSize: 14, position: "relative", top: -3, ml: 0.1 }}
            />
          </Button>
        </Tooltip>
      </Stack>
      <Paper
        component="form"
        sx={{
          width: "100%",
          padding: "12px 16px",
          bottom: 0,
          borderTop: "1px solid #ccc",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <InputBase
          size="medium"
          sx={{
            width: "100%",
            border: "1px solid #ccc",
            borderRadius: 1,
            minHeight: 40,
            fontSize: 16,
            padding: 1,
            mr: 2,
            maxHeight: "200px",
            overflowY: "auto",
          }}
          multiline
          placeholder="請輸入問題"
          value={message}
          onChange={(e) => handleChange(e.target.value)}
          onKeyDown={handleKeyDown}
          onCompositionStart={handleComposition}
          onCompositionUpdate={handleComposition}
          onCompositionEnd={handleComposition}
          endAdornment={isLoading && <CircularProgress size={20} />}
        />
        {isWaiting && (
          <IconButton sx={{ padding: 0 }} onClick={handleStopMessage}>
            <StopCircleOutlinedIcon color="primary" sx={{ fontSize: 36 }} />
          </IconButton>
        )}
        {!isWaiting && (
          <IconButton sx={{ padding: 0 }} onClick={handleSendMessage}>
            <SendIcon color="primary" sx={{ fontSize: 24 }} />
          </IconButton>
        )}
      </Paper>
    </Stack>
  );
};

export default InputSection;

InputSection.propTypes = {
  message: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSendMessage: PropTypes.func.isRequired,
  isWaiting: PropTypes.bool,
  isStreamMode: PropTypes.bool,
  handleStopBatch: PropTypes.func,
  handleStopStream: PropTypes.func,
  handleResetConversation: PropTypes.func,
  isLoading: PropTypes.bool,
  suggestedQuestions: PropTypes.instanceOf(List),
  logs: PropTypes.instanceOf(List),
  promptList: PropTypes.instanceOf(List),
};
