import Div from "@jumbo/shared/Div";
import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { createModel, updateModel } from "app/redux/actions/model";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import * as yup from "yup";

const ModelFormModal = ({ isOpen, onClose, model, modelId }) => {
  const dispatch = useDispatch();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 1,
  };

  const validationSchema = yup.object({
    name: yup.string().required("模型名稱為必填欄位"),
    api_base: yup.string().required("網址為必填欄位"),
    api_key: yup.string(),
    is_chat_model: yup.boolean(),
    max_tokens: yup
      .number()
      .min(1, "最大Token數需大於0")
      .required("最大Token數為必填欄位"),
  });

  const initialValues = {
    name: "",
    api_base: "",
    api_key: "",
    is_chat_model: true,
    max_tokens: 0,
  };

  const handleSubmit = (values) => {
    const isEdit = modelId && model;

    const data = {
      ...values,
      api_key: values.api_key || "key",
    };

    if (isEdit) dispatch(updateModel(modelId, data));
    else dispatch(createModel(data));

    handleClose();
  };

  const handleClose = () => {
    formik.resetForm();
    onClose();
  };

  const formik = useFormik({
    validateOnChange: true,
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (model) {
      formik.setValues({
        name: model.name,
        api_base: model.api_base,
        api_key:
          model.api_key === "key" || model.api_key === "empty"
            ? ""
            : model.api_key,
        max_tokens: model.max_tokens,
        is_chat_model: model.is_chat_model,
      });
    }
  }, [modelId, model]);

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Div sx={style}>
        <Stack flexDirection="row" color="orange" gap={1} p={2}>
          <Typography variant="h2" m={0}>
            建立模型
          </Typography>
        </Stack>
        <Divider />
        <form onSubmit={formik.handleSubmit}>
          <Stack>
            <Div sx={{ p: 2 }}>
              <TextField
                fullWidth
                placeholder="模型名稱"
                name="name"
                sx={{ mb: 2 }}
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
              <TextField
                fullWidth
                placeholder="網址"
                name="api_base"
                sx={{ mb: 2 }}
                value={formik.values.api_base}
                onChange={formik.handleChange}
                error={
                  formik.touched.api_base && Boolean(formik.errors.api_base)
                }
                helperText={formik.touched.api_base && formik.errors.api_base}
              />
              <TextField
                fullWidth
                placeholder="API KEY"
                name="api_key"
                sx={{ mb: 2 }}
                value={formik.values.api_key}
                onChange={formik.handleChange}
                error={formik.touched.api_key && Boolean(formik.errors.api_key)}
                helperText={formik.touched.api_key && formik.errors.api_key}
              />
              <TextField
                fullWidth
                placeholder="最大Token數"
                name="max_tokens"
                type="number"
                sx={{ mb: 2 }}
                value={formik.values.max_tokens}
                onChange={formik.handleChange}
                error={
                  formik.touched.max_tokens && Boolean(formik.errors.max_tokens)
                }
                helperText={
                  formik.touched.max_tokens && formik.errors.max_tokens
                }
              />
              <FormControl>
                <FormControlLabel
                  name="is_chat_model"
                  checked={formik.values.is_chat_model}
                  onChange={formik.handleChange}
                  control={<Checkbox />}
                  label="對話模型"
                />
              </FormControl>
            </Div>
            <Divider />
            <Stack
              flexDirection="row"
              justifyContent="flex-end"
              gap={2}
              sx={{ p: 1 }}
            >
              <Button variant="outlined" onClick={handleClose}>
                取消
              </Button>
              <Button type="submit" variant="contained" color="primary">
                確認
              </Button>
            </Stack>
          </Stack>
        </form>
      </Div>
    </Modal>
  );
};

export default ModelFormModal;

ModelFormModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  modelId: PropTypes.string,
  model: PropTypes.object,
};
