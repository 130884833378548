import ThesisMasterPage from "app/pages/plugin/thesismaster";
import React from "react";

const ThesisMasterRoutes = [
  {
    path: "/plugin/thesis_master",
    element: <ThesisMasterPage />,
  },
];

export default ThesisMasterRoutes;
