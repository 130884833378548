export const translateLangDict = {
  de: "德文",
  "en-us": "英文",
  es: "西班牙文",
  fr: "法文",
  it: "意大利文",
  ja: "日文",
  ko: "韓文",
  "pt-pt": "葡萄牙文",
  ru: "俄文",
  vi: "越南文",
  "zh-cn": "簡體中文",
  "zh-tw": "繁體中文",
};
