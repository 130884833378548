import JumboLayout from "@jumbo/components/JumboLayout";
import useJumboLayout from "@jumbo/hooks/useJumboLayout";
import PropTypes from "prop-types";
import React from "react";

import layoutConfig from "./layoutConfig";

function SoloPage({ children }) {
  const { setJumboLayoutOptions } = useJumboLayout();

  React.useEffect(() => {
    setJumboLayoutOptions(layoutConfig);
  }, []);

  return <JumboLayout>{children}</JumboLayout>;
}

SoloPage.propTypes = {
  children: PropTypes.node,
};

export default SoloPage;
