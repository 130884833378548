import http from "app/services";
import moment from "moment";

import {
  CREATE_KNOWLEDGEBASE,
  CREATE_KNOWLEDGEBASE_DONE,
  DEL_KNOWLEDGEBASE,
  DEL_KNOWLEDGEBASE_DONE,
  DEL_SOURCE,
  DEL_SOURCE_DONE,
  DEL_USER_ROLE,
  DEL_USER_ROLE_DONE,
  FCH_KNOWLEDGEBASE,
  FCH_KNOWLEDGEBASE_DONE,
  FCH_KNOWLEDGEBASES,
  FCH_KNOWLEDGEBASES_DONE,
  SET_CURRENT_SOURCE_ID,
  SORT_KNOWLEDGEBASES,
  UPD_NAME,
  UPD_NAME_DONE,
  UPD_USER_PERMISSION,
  UPD_USER_PERMISSION_DONE,
  UPD_USER_ROLE,
  UPD_USER_ROLE_DONE,
  UPLOAD_SOURCE,
  UPLOAD_SOURCE_DONE,
  UPLOAD_SOURCE_FAIL,
} from "../types/knowledgebase";
import { errorAlert, statusHandler, successAlert } from "./alert";
import { fchConversationList } from "./conversation";

export const fchKnowledgebaseList = (id) => {
  return async (dispatch) => {
    dispatch({ type: FCH_KNOWLEDGEBASES });
    const res = await http.get(`/api/v1/users/${id}/knowledgebases`);
    if (res.response && res.response.status === 401) {
      return { code: res.response.status, message: "伺服器錯誤" };
    }
    dispatch({
      type: FCH_KNOWLEDGEBASES_DONE,
      payload: { doc: [...res.data] },
    });
  };
};

export const fchKnowledgebase = (id) => {
  return async (dispatch) => {
    dispatch({ type: FCH_KNOWLEDGEBASE });
    const res = await http.get(`/api/v1/knowledgebases/${id}`);
    if (res.response && res.response.status >= 400) {
      return { success: false, code: res.response.status };
    }
    dispatch({
      type: FCH_KNOWLEDGEBASE_DONE,
      payload: { doc: res.data },
    });
    return { success: true, data: res.data };
  };
};

export const createKnowledgebase = (id, name) => {
  return async (dispatch) => {
    const data = { name };
    dispatch({ type: CREATE_KNOWLEDGEBASE });
    const res = await http.post(`/api/v1/users/${id}/knowledgebases`, data);
    if (res.response && res.response.status >= 400) {
      return dispatch(statusHandler(res.response.status));
    }
    const doc = {
      ...data,
      id: res.data.payload.id,
      user_id: id,
      created_at: moment().unix(),
    };

    dispatch({
      type: CREATE_KNOWLEDGEBASE_DONE,
      payload: { doc },
    });

    dispatch(successAlert("新增成功"));

    return res.data.payload.id;
  };
};

export const delKnowledgebase = (id, uId) => {
  return async (dispatch) => {
    dispatch({ type: DEL_KNOWLEDGEBASE });
    const res = await http.delete(`/api/v1/knowledgebases/${id}`);
    if (res.response && res.response.status >= 400) {
      return dispatch(statusHandler(res.response.status));
    }
    dispatch({ type: DEL_KNOWLEDGEBASE_DONE, payload: { id } });
    dispatch(fchConversationList(uId));
    dispatch(successAlert("刪除成功"));
  };
};

export const uploadSource = (kId, source) => {
  return async (dispatch) => {
    const startTime = moment();
    const res = await http.post(
      `/api/v1/knowledgebases/${kId}/sources`,
      source,
    );

    if (res.response && res.response.status >= 400) {
      return dispatch(statusHandler(res.response.status));
    }

    const sId = res.data.payload.id;

    const doc = {
      ...source,
      knowledge_base_id: kId,
      status: "UPLOADING",
      id: sId,
      created_at: moment().unix(),
    };

    dispatch({ type: UPLOAD_SOURCE, payload: { doc } });

    const checkStatus = async () => {
      const res = await http.get(
        `/api/v1/knowledgebases/${kId}/sources/${sId}`,
      );

      const path = urlConvert(res.data.path);

      if (res.response && res.response.status >= 400) {
        dispatch(statusHandler(res.response.status));
        clearInterval(interval);
      }
      if (res.data.status === "FAILED") {
        dispatch({
          type: UPLOAD_SOURCE_FAIL,
          payload: { id: sId, status: res.data.status },
        });
        const elapsedTime = moment().diff(startTime, "seconds");
        let reason = "";
        if (res.data?.message) reason = res.data.message;
        dispatch(
          errorAlert(`${path}\n 上傳失敗 ${reason} 總共花費 ${elapsedTime} 秒`),
        );
        clearInterval(interval);
      }
      if (res.data.status === "DONE") {
        dispatch({
          type: UPLOAD_SOURCE_DONE,
          payload: { id: sId, status: res.data.status },
        });
        const elapsedTime = moment().diff(startTime, "seconds");
        dispatch(successAlert(`${path}\n 上傳成功 總共花費 ${elapsedTime} 秒`));
        clearInterval(interval);
      }
    };
    const interval = setInterval(checkStatus, 3000);
  };
};

export const updKnowledgebaseName = (id, name) => {
  return async (dispatch) => {
    dispatch({ type: UPD_NAME });
    const res = await http.patch(`/api/v1/knowledgebases/${id}`, { name });
    if (res.response && res.response.status >= 400) {
      return dispatch(statusHandler(res.response.status));
    }
    dispatch({ type: UPD_NAME_DONE, payload: { id, name } });
    dispatch(successAlert("更新成功"));
  };
};

export const delSource = (kId, sId) => {
  return async (dispatch) => {
    dispatch({ type: DEL_SOURCE });
    const res = await http.delete(
      `/api/v1/knowledgebases/${kId}/sources/${sId}`,
    );
    if (res.response && res.response.status >= 400) {
      return dispatch(statusHandler(res.response.status));
    }
    dispatch({ type: DEL_SOURCE_DONE, payload: { id: sId } });
    return dispatch(successAlert("刪除成功"));
  };
};

export const updUserRole = (kId, group) => {
  return async (dispatch) => {
    dispatch({ type: UPD_USER_ROLE });
    const data = {
      r: false,
      w: false,
      x: false,
      user_id: group.id,
      email: group.email,
    };
    const res = await http.post(`/api/v1/knowledgebases/${kId}/rules`, data);
    if (res.response && res.response.status >= 400) {
      return dispatch(statusHandler(res.response.status));
    }
    dispatch({
      type: UPD_USER_ROLE_DONE,
      payload: {
        doc: { ...data, id: res.data.payload.id },
      },
    });
    return dispatch(successAlert("新增成功"));
  };
};

export const delUserRole = (kId, rId) => {
  return async (dispatch) => {
    dispatch({ type: DEL_USER_ROLE });
    const res = await http.delete(`/api/v1/knowledgebases/${kId}/rules/${rId}`);
    if (res.response && res.response.status >= 400) {
      return dispatch(statusHandler(res.response.status));
    }
    dispatch({ type: DEL_USER_ROLE_DONE, payload: { id: rId } });
    return dispatch(successAlert("刪除成功"));
  };
};

export const updUserPermission = (kId, rId, permission) => {
  return async (dispatch) => {
    dispatch({ type: UPD_USER_PERMISSION });
    const res = await http.patch(
      `/api/v1/knowledgebases/${kId}/rules/${rId}`,
      permission,
    );
    if (res.response && res.response.status >= 400) {
      return dispatch(statusHandler(res.response.status));
    }
    dispatch({
      type: UPD_USER_PERMISSION_DONE,
      payload: { id: rId, permission },
    });
  };
};

const urlConvert = (path) => {
  if (!path) return "";
  if (path.startsWith("http")) return path;
  return path.split("/").pop();
};

export const checkSourceStatus = (kbStatus) => {
  return async (dispatch) => {
    const intervalIds = [];
    kbStatus.forEach(({ id, knowledge_base_id, created_at }) => {
      const checkStatus = async () => {
        const res = await http.get(
          `/api/v1/knowledgebases/${knowledge_base_id}/sources/${id}`,
        );

        const path = urlConvert(res.data.path);
        const startTime = moment.unix(created_at);

        if (res.response && res.response.status >= 400) {
          dispatch(statusHandler(res.response.status));
          clearInterval(interval);
        }
        if (res.data.status === "FAILED") {
          dispatch({
            type: UPLOAD_SOURCE_FAIL,
            payload: { id, status: res.data.status },
          });
          const elapsedTime = moment().diff(startTime, "seconds");
          let reason = "";
          if (res.data?.message) reason = res.data.message;
          dispatch(
            errorAlert(
              `${path}\n 上傳失敗 ${reason} 總共花費 ${elapsedTime} 秒`,
            ),
          );
          clearInterval(interval);
        }
        if (res.data.status === "DONE") {
          dispatch({
            type: UPLOAD_SOURCE_DONE,
            payload: { id, status: res.data.status },
          });
          const elapsedTime = moment().diff(startTime, "seconds");
          dispatch(
            successAlert(`${path}\n 上傳成功 總共花費 ${elapsedTime} 秒`),
          );
          clearInterval(interval);
        }
      };

      const interval = setInterval(checkStatus, 3000);
      intervalIds.push(interval);
    });

    return intervalIds;
  };
};

export const setCurrentSourceId = (sourceId) => {
  return async (dispatch) => {
    dispatch({ type: SET_CURRENT_SOURCE_ID, payload: { sourceId } });
  };
};

export const sortKnowledgebases = (field, order) => {
  return async (dispatch) => {
    dispatch({ type: SORT_KNOWLEDGEBASES, payload: { field, order } });
  };
};
