import { TextField } from "@mui/material";
import { useReactFlow } from "@xyflow/react";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";

import NodeWrapper from "./NodeWrapper";

const FileWriterNode = ({ id, data }) => {
  const [fileName, setFileName] = useState(data.fileName || "");
  const { setNodes } = useReactFlow();

  const updateNodeData = useCallback(
    (newData) => {
      setNodes((nds) =>
        nds.map((node) => {
          if (node.id === id) {
            node.data = { ...node.data, ...newData };
          }
          return node;
        }),
      );
    },
    [id, setNodes],
  );

  const handleFileNameChange = (e) => {
    const newFileName = e.target.value;
    setFileName(newFileName);
    updateNodeData({ fileName: newFileName });
  };

  return (
    <NodeWrapper
      id={id}
      title="File Writer Node"
      initialInputs={data.inputs || []}
      initialOutputs={data.outputs || []}
      data={data}
      width={300}
      color="#E53935"
      setNodes={setNodes}
      canAddInputs={false}
      canAddOutputs={false}
      canDeleteInputs={false}
      canDeleteOutputs={false}
    >
      <TextField
        id={`file-name-${id}`}
        label="File Name"
        value={fileName}
        onChange={handleFileNameChange}
        placeholder="Enter file name"
        fullWidth
        margin="normal"
        variant="outlined"
      />
    </NodeWrapper>
  );
};

FileWriterNode.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.shape({
    fileName: PropTypes.string,
    inputs: PropTypes.array,
    outputs: PropTypes.array,
  }).isRequired,
};

export default FileWriterNode;
