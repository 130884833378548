import {
  closestCorners,
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  restrictToParentElement,
  restrictToVerticalAxis,
} from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import JumboIconButton from "@jumbo/components/JumboIconButton";
import DehazeIcon from "@mui/icons-material/Dehaze";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  cngSubCategoryPosition,
  deleteSubCategory,
  updateSubCategory,
} from "app/redux/actions/menu";
import { List, Map } from "immutable";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import ConfirmModal from "../Modal/ConfirmModal";
import MenuModal from "../Modal/MenuModal";

const SubCategoryDataTable = ({ dataSource, category_id }) => {
  const dispatch = useDispatch();

  const [isDelModalOpen, setIsDelModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [menuId, setMenuId] = useState(null);
  const [category, setCategory] = useState(null);
  const tableRef = useRef(null);

  const sensors = useSensors(useSensor(PointerSensor));

  const handleDelModalOpen = (id) => {
    setMenuId(id);
    setIsDelModalOpen(true);
  };

  const handleDelModalClose = () => {
    setMenuId(null);
    setIsDelModalOpen(false);
  };

  const handleDeleteMenu = async () => {
    await dispatch(deleteSubCategory(menuId));
    handleDelModalClose();
  };

  const handleEditModalOpen = (id) => {
    setMenuId(id);
    setIsEditModalOpen(true);
  };

  const handleEditModalClose = () => {
    setMenuId(null);
    setIsEditModalOpen(false);
  };

  const handleEditModalSubmit = (values) => {
    const data = {
      ...values,
      category_id,
    };
    dispatch(updateSubCategory(menuId, data));
    handleEditModalClose();
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      const oldIndex = dataSource.findIndex(
        (menu) => menu.get("sn") === active.id,
      );
      const newIndex = dataSource.findIndex(
        (menu) => menu.get("sn") === over.id,
      );

      const updatedItems = arrayMove(dataSource.toJS(), oldIndex, newIndex);
      const updatedArray = updatedItems.map((item, index) => ({
        ...item,
        sn: index + 1,
      }));

      // 過濾出 active 和 over 的 sn，updatedActive為被調換的物件，updatedOver為被拖動的物件
      const [updatedActive, updatedOver] = updatedArray.filter(
        (item) => item.sn === active.id || item.sn === over.id,
      );

      console.log(updatedActive, updatedOver);

      dispatch(cngSubCategoryPosition(updatedActive, updatedOver));
    }
  };

  useEffect(() => {
    setCategory(dataSource);
  }, [dataSource]);

  if (!category) return null;

  return (
    <>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCorners}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToVerticalAxis, restrictToParentElement]}
      >
        <TableContainer component={Paper} ref={tableRef}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>副選單名稱</TableCell>
                <TableCell>網址</TableCell>
                <TableCell>建立日期</TableCell>
                <TableCell>設定</TableCell>
                <TableCell>刪除</TableCell>
                <TableCell>排序</TableCell>
              </TableRow>
            </TableHead>
            <SortableContext
              items={dataSource.map((menu) => menu.get("sn"))}
              strategy={verticalListSortingStrategy}
            >
              <TableBody>
                {dataSource.map((menu) => (
                  <SortableTableRow
                    key={menu.get("id")}
                    menu={menu}
                    onEdit={() => handleEditModalOpen(menu.get("id"), menu)}
                    onDelete={() => handleDelModalOpen(menu.get("id"))}
                  />
                ))}
              </TableBody>
            </SortableContext>
          </Table>
        </TableContainer>
      </DndContext>
      <ConfirmModal
        open={isDelModalOpen}
        content={
          <>
            <Typography variant="body1">確定要刪除此副選單嗎？</Typography>
          </>
        }
        onClose={handleDelModalClose}
        onConfirm={handleDeleteMenu}
      />
      <MenuModal
        menu={dataSource.find((menu) => menu.get("id") === menuId)}
        isEdit={true}
        isOpen={isEditModalOpen}
        onClose={handleEditModalClose}
        onSubmit={handleEditModalSubmit}
      />
    </>
  );
};

export default React.memo(SubCategoryDataTable);
SubCategoryDataTable.propTypes = {
  dataSource: PropTypes.instanceOf(List).isRequired,
  category_id: PropTypes.string.isRequired,
};

export const SortableTableRow = ({ menu, onEdit, onDelete }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: menu.get("sn") });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <TableRow ref={setNodeRef} {...attributes} style={style}>
      <TableCell>{menu.get("name")}</TableCell>
      <TableCell>
        <Link
          sx={{ textDecoration: "none" }}
          target="_blank"
          href={menu.get("url")}
        >
          {menu.get("url")}
        </Link>
      </TableCell>
      <TableCell>
        {moment.unix(menu.get("created_at")).format("YYYY/MM/DD HH:mm:ss")}
      </TableCell>
      <TableCell>
        <JumboIconButton
          sx={{ position: "relative", left: -5 }}
          onClick={onEdit}
        >
          <EditIcon color="primary" />
        </JumboIconButton>
      </TableCell>
      <TableCell>
        <JumboIconButton
          sx={{ position: "relative", left: -5 }}
          onClick={onDelete}
        >
          <DeleteIcon color="error" />
        </JumboIconButton>
      </TableCell>
      <TableCell>
        <DehazeIcon {...listeners} sx={{ cursor: "grab" }} />
      </TableCell>
    </TableRow>
  );
};

SortableTableRow.propTypes = {
  menu: PropTypes.instanceOf(Map).isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};
