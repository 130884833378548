import {
  Button,
  Card,
  CardContent,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { createConversation } from "app/redux/actions/conversation";
import InputSection from "app/shared/Form/InputSection";
import RadioSection from "app/shared/Form/RadioSection";
import SelectSection from "app/shared/Form/SelectSection";
import SliderSection from "app/shared/Form/SliderSection";
import TextAreaSection from "app/shared/Form/TextAreaSection";
import Loading from "app/shared/Loading";
import CreateKnowledgebaseModal from "app/shared/Modal/CreateKnowledgebase";
import { useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

const CreateChat = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const initialValues = {
    name: "",
    language_model: "",
    conversation_mode: null,
    knowledge_base_ids: [],
    output_mode: "streaming",
    system_prompt: "",
    temperature: 0.5,
    max_tokens: 4096,
  };

  const [tokenMarks, setTokenMarks] = useState([
    {
      value: 1,
      label: "1",
    },
    {
      value: 4096,
      label: "4096",
    },
  ]);

  const uId = useSelector(({ app }) => app.getIn(["user", "id"]));
  const modelData = useSelector(({ model }) =>
    model.getIn(["results", "models"]),
  );

  const knowledgebaseList = useSelector(({ knowledgebase }) =>
    knowledgebase.getIn(["results", "knowledgebases"]),
  );

  const handleSubmit = async (uId, values) => {
    const data = {
      name: values.name,
      knowledge_base_id:
        values.conversation_mode !== "language_model"
          ? values.knowledge_base_ids.map((id) => id.value)
          : "",
      knowledge_base_ids: values.knowledge_base_ids.map((id) => id.value),
      metadata: {
        language_model: values.language_model.label,
        language_model_id: values.language_model.value,
        conversation_mode: values.conversation_mode,
        output_mode: values.output_mode,
        system_prompt: values.system_prompt,
        temperature: values.temperature,
        max_tokens:
          values.max_tokens > modelData.getIn([0, "max_tokens"])
            ? modelData.getIn([0, "max_tokens"])
            : values.max_tokens,
      },
    };
    const id = await dispatch(createConversation(uId, data));
    navigate(`/conversation/${id}`);
  };

  useEffect(() => {
    if (!modelData) return;

    const data = {
      name: moment().format("YYYY-MM-DD HH:mm:ss"),
      language_model: {
        value: modelData.getIn([0, "id"]),
        label: modelData.getIn([0, "name"]),
      },
      conversation_mode: "knowledgebase",
      knowledge_base_ids: [],
      output_mode: "streaming",
      system_prompt: "",
      temperature: 0.5,
      max_tokens: 2048,
    };

    formik.setValues(data);
    setTokenMarks([
      {
        value: 1,
        label: "1",
      },
      {
        value: Number(modelData.getIn([0, "max_tokens"])),
        label: modelData.getIn([0, "max_tokens"]),
      },
    ]);
  }, [modelData]);

  const validationSchema = yup.object({
    name: yup.string().required("請輸入對話名稱"),
    language_model: yup.object().required("請選擇語言模型"),
    knowledge_base_ids: yup.array().when("conversation_mode", {
      is: (value) => value === "knowledgebase" || value === "text_to_sql",
      then: () => yup.array().min(1, "請選擇知識庫"),
      otherwise: () => yup.array().notRequired(),
    }),
  });

  const formik = useFormik({
    validateOnChange: true,
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: (values) => handleSubmit(uId, values),
  });

  const handleChangeModel = (e) => {
    const value = e.target.value;
    formik.setFieldValue("language_model", value);

    const model = modelData.find((model) => model.get("id") === value.value);
    const marks = [
      {
        value: 1,
        label: "1",
      },
      {
        value: 4096,
        label: "4096",
      },
    ];

    if (model) {
      marks[1].value = Number(model.get("max_tokens"));
      marks[1].label = model.get("max_tokens");
      const maxTokens = model.get("max_tokens") / 2;
      formik.setFieldValue("max_tokens", maxTokens);
    }
    setTokenMarks(marks);
  };

  const handleChangeConversationMode = (e) => {
    const value = e.target.value;
    formik.setFieldValue("conversation_mode", value);

    if (value === "text_to_sql") {
      formik.setFieldValue("output_mode", "batch");
    }
  };

  if (!modelData || !knowledgebaseList) return <Loading />;

  return (
    <Card sx={{ p: 4 }}>
      <Typography variant="h3">對話AI設定</Typography>

      <Divider sx={{ my: 4 }} />

      <CardContent sx={{ p: 0 }}>
        <form onSubmit={formik.handleSubmit}>
          <InputSection
            title="對話名稱"
            tooltip="可以自訂對話名稱"
            name="name"
            placeholder="請輸入對話名稱"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />

          <SelectSection
            title="語言模型"
            name="language_model"
            defaultOptionLabel="選擇語言模型"
            value={formik.values.language_model}
            onChange={handleChangeModel}
            options={modelData
              .map((model) => ({
                value: model.get("id"),
                label: model.get("name"),
              }))
              .toJS()}
            error={
              formik.touched.language_model &&
              Boolean(formik.errors.language_model)
            }
            helperText={
              formik.touched.language_model && formik.errors.language_model
            }
          />

          <RadioSection
            title="選擇對話模式"
            name="conversation_mode"
            value={formik.values.conversation_mode}
            onChange={handleChangeConversationMode}
            options={[
              {
                label: "與知識庫對話",
                value: "knowledgebase",
                tooltip: "參考知識庫文件回答",
              },
              {
                label: "與語言模型對話",
                value: "language_model",
                tooltip: "語言模型直接回答",
              },
              {
                label: "智慧資料分析",
                value: "text_to_sql",
                tooltip: "智慧資料分析",
              },
            ]}
          />

          <RadioSection
            title="輸出模式"
            name="output_mode"
            value={formik.values.output_mode}
            onChange={formik.handleChange}
            options={[
              {
                label: "串流(streaming)",
                value: "streaming",
                tooltip: "回覆內容 一個一個字 接續出現",
                disabled: formik.values.conversation_mode === "text_to_sql",
              },
              {
                label: "批次(batch)",
                value: "batch",
                tooltip: "回覆內容 完整生成後 一次性出現",
              },
            ]}
          />

          <Stack
            gap={2}
            sx={{
              width: { md: 700, sx: "100%" },
              mb: 3,
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <SelectSection
              title="選擇知識庫"
              name="knowledge_base_ids"
              defaultOptionLabel="選擇知識庫"
              value={formik.values.knowledge_base_ids}
              onChange={(e) =>
                formik.setFieldValue("knowledge_base_ids", e.target.value)
              }
              groupBy={(option) => option.groupBy}
              multiple
              options={knowledgebaseList
                .map((item) => ({
                  value: item.get("id"),
                  label: item.get("name"),
                }))
                .toJS()}
              error={
                formik.touched.knowledge_base_ids &&
                Boolean(formik.errors.knowledge_base_ids)
              }
              helperText={
                formik.touched.knowledge_base_ids &&
                formik.errors.knowledge_base_ids
              }
              disabled={formik.values.conversation_mode === "language_model"}
            />
            <CreateKnowledgebaseModal uId={uId} />
          </Stack>

          <TextAreaSection
            title="語言模型的行為模式"
            name="system_prompt"
            tooltip="語言模型 扮演特定角色與執行特定行為"
            value={formik.values.system_prompt}
            onChange={formik.handleChange}
            placeholder="範例：You are an AI assistant skilled in Python programming and debugging. Help users identify and fix errors in their Python code, offer suggestions for optimization."
          />

          <SliderSection
            title="活潑度(溫度)"
            name="temperature"
            tooltip="Temperature 數字越高 代表 回答的創造性 越高; 反之 則越精確"
            marks={tempMarks}
            step={0.1}
            value={formik.values.temperature}
            onChange={formik.handleChange}
          />

          <SliderSection
            title="最大 Token 長度"
            name="max_tokens"
            tooltip="Token長度越多，回覆長度越長"
            marks={tokenMarks}
            step={1}
            value={formik.values.max_tokens}
            onChange={formik.handleChange}
          />

          <Button type="submit" variant="contained">
            建立對話
          </Button>
        </form>
      </CardContent>
    </Card>
  );
};

export default CreateChat;

const tempMarks = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 1,
    label: "1",
  },
];
