import Div from "@jumbo/shared/Div";
import Span from "@jumbo/shared/Span";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { ListItemSecondaryAction, Typography } from "@mui/material";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";

function NotificationPost({ item, onUpdate, isRead }) {
  return (
    <ListItemButton
      component="li"
      alignItems="flex-start"
      sx={{
        bgcolor: isRead ? "background.default" : "background.paper",
      }}
      onClick={() => onUpdate(item.get("user_id"), item.get("id"))}
    >
      <ListItemAvatar>
        <ErrorOutlineIcon fontSize="large" sx={{ color: "info.main" }} />
      </ListItemAvatar>
      <ListItemText>
        {item.get("content")}
        <Typography
          component="span"
          sx={{
            display: "flex",
            fontSize: "90%",
            mt: 0.5,
          }}
        >
          <Span sx={{ color: "text.secondary" }}>
            {moment.unix(item.get("created_at")).format("YYYY-MM-DD HH:mm:ss")}
          </Span>
        </Typography>
      </ListItemText>
      <ListItemSecondaryAction>
        {!isRead && (
          <Div
            sx={{
              width: 8,
              height: 8,
              borderRadius: 99,
              backgroundColor: "warning.main",
            }}
          />
        )}
      </ListItemSecondaryAction>
    </ListItemButton>
  );
}

NotificationPost.propTypes = {
  item: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  isRead: PropTypes.bool.isRequired,
};

export default NotificationPost;
