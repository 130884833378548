import TranslateMasterPage from "app/pages/plugin/translatemaster";
import React from "react";

const TranslateMasterRoutes = [
  {
    path: "/plugin/translate_master",
    element: <TranslateMasterPage />,
  },
];

export default TranslateMasterRoutes;
