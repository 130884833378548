import AddIcon from "@mui/icons-material/Add";
import { Button, Card, Divider, Grid, Stack, Typography } from "@mui/material";
import { fchPluginList } from "app/redux/actions/plugin";
import Loading from "app/shared/Loading";
import PluginCard from "app/shared/PluginCard";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const PluginPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const pluginList = useSelector(({ plugin }) =>
    plugin.getIn(["results", "plugins"]),
  );

  const user_id = useSelector(({ app }) => app.getIn(["user", "id"]));

  useEffect(() => {
    dispatch(fchPluginList());
  }, [dispatch]);

  if (!pluginList) return <Loading />;

  return (
    <Card sx={{ p: 4 }}>
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h3" sx={{ m: 0 }}>
          插件管理
        </Typography>
        <Button
          variant="contained"
          endIcon={<AddIcon />}
          onClick={() => navigate("/admin/plugin/process_master")}
        >
          新增插件
        </Button>
      </Stack>

      <Divider sx={{ my: 4 }} />

      <Grid container spacing={2}>
        {pluginList.map((plugin) => (
          <Grid item xs={12} md={3} key={plugin.id}>
            <PluginCard plugin={plugin} user_id={user_id} sx={{ flex: 1 }} />
          </Grid>
        ))}
      </Grid>
    </Card>
  );
};

export default PluginPage;
