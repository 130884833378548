import { Chart, registerables } from "chart.js";
import PropTypes from "prop-types";
import React from "react";
import { Bar } from "react-chartjs-2";

Chart.register(...registerables);

const BarChart = ({ data, chartRef }) => {
  return <Bar ref={chartRef} data={data} />;
};

export default BarChart;

BarChart.propTypes = {
  data: PropTypes.object.isRequired,
  chartRef: PropTypes.object.isRequired,
};
