import JumboIconButton from "@jumbo/components/JumboIconButton";
import Div from "@jumbo/shared/Div";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Button,
  Card,
  Chip,
  Divider,
  IconButton,
  InputBase,
  Stack,
  Typography,
} from "@mui/material";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "app/redux/actions/alert";
import {
  changeNameEditable,
  delConversation,
  fchConversationLogs,
  getReference,
  resetConversation,
  sendMessage,
  sendStreamMessage,
  setLogIndex,
  stopBatchMessage,
  stopStreamMessage,
  updConversationName,
} from "app/redux/actions/conversation";
import Immutable, { List } from "immutable";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as uuid from "uuid";

import ConfirmModal from "../Modal/ConfirmModal";
import InputSection from "./InputSection";
import MessageBox from "./MessageBox";

function ChatRoom({
  title,
  logs,
  conversationId,
  sources,
  selectedSources,
  isStreamMode,
  modalBadge,
  conversationInfo,
  user,
  model,
  mode,
}) {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [message, setMessage] = useState("");
  const [isDelModalOpen, setIsDelModalOpen] = useState(false);
  const [signalKey, setSignalKey] = useState(null);
  const [name, setName] = useState(title);

  const isSending = useSelector(({ conversation }) =>
    conversation.get("isSending"),
  );
  const isWaiting = useSelector(({ conversation }) =>
    conversation.get("isWaiting"),
  );

  const isLoading = useSelector(({ conversation }) =>
    conversation.get("isLoading"),
  );

  const isEditable = useSelector(({ conversation }) =>
    conversation.getIn(["config", "isEditable"]),
  );

  const promptList = useSelector(({ app }) => app.get("prompts"));

  const isHasKb = conversationInfo.get("knowledge_base_ids").size > 0;

  const suggestedQuestions = useSelector(({ conversation }) =>
    conversation
      .get("sources")
      .flatMap((item) => item.get("questions"))
      .sortBy(() => Math.random())
      .take(3),
  );

  const handleChange = (value) => {
    setMessage(value);
  };

  const handleSendMessage = async () => {
    if (!message) return;

    try {
      if (isStreamMode) {
        const key = uuid.v4();
        await dispatch(
          sendStreamMessage(
            conversationId,
            message,
            selectedSources.toJS(),
            key,
          ),
        );

        setSignalKey(key);
      } else {
        await dispatch(
          sendMessage(conversationId, message, selectedSources.toJS()),
        );
      }

      if (isHasKb) {
        const logs = await dispatch(fchConversationLogs(conversationId));
        const lastLogId = Immutable.fromJS(logs).last().get("id");
        dispatch(getReference(lastLogId));
      }

      setMessage("");
    } catch (error) {
      console.log(error);
      dispatch(errorAlert("伺服器錯誤"));
    }
  };

  const handleStopStream = () => {
    setSignalKey(null);
    return dispatch(stopStreamMessage(signalKey));
  };

  const handleStopBatch = () => {
    dispatch(stopBatchMessage());
  };

  const handleDelModalOpen = () => {
    setIsDelModalOpen(true);
  };

  const handleDelModalClose = () => {
    setIsDelModalOpen(false);
  };

  const handleDelConversation = async () => {
    const success = await dispatch(delConversation(conversationId));
    if (!success) return;
    setIsDelModalOpen(false);
    navigate("/");
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleNameSubmit = () => {
    if (name !== title) {
      dispatch(updConversationName(conversationId, name));
    }
    dispatch(changeNameEditable(false));
  };

  const handleResetConversation = () => {
    if (isWaiting) {
      if (isStreamMode) {
        dispatch(stopStreamMessage(signalKey));
      }
      if (!isStreamMode) {
        dispatch(stopBatchMessage());
      }
    }
    dispatch(resetConversation(conversationId));
  };

  const handleCopyContent = (content, e) => {
    e.stopPropagation();
    navigator.clipboard.writeText(content);
    dispatch(successAlert("已複製"));
  };

  const handleSetIndex = (index, e) => {
    e.stopPropagation();
    dispatch(setLogIndex(index));
  };

  const handleGetReference = async (id, e) => {
    e.stopPropagation();
    const reference = await dispatch(getReference(id));
    if (reference.length === 0) {
      dispatch(warningAlert("查無參考資料"));
    }
  };

  const suggestedQuestionsMemo = useMemo(() => suggestedQuestions, [sources]);

  useEffect(() => {
    return () => {
      if (isWaiting && isStreamMode) {
        dispatch(stopStreamMessage(signalKey));
      }
    };
  }, [isStreamMode, isWaiting]);

  return (
    <>
      <Card sx={{ position: "relative" }}>
        <Div
          sx={{
            py: 3,
            px: 3,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Stack flexDirection="column" alignItems="flex-start">
            {isEditable ? (
              <Stack flexDirection="row">
                <InputBase
                  value={name}
                  autoFocus
                  sx={{
                    fontSize: "1.5rem",
                    border: "1px solid #c0c0c0",
                    px: 1,
                    borderTopLeftRadius: 1,
                    borderBottomLeftRadius: 1,
                  }}
                  onChange={handleNameChange}
                />
                <Button
                  variant="contained"
                  sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                  onClick={handleNameSubmit}
                >
                  確認
                </Button>
              </Stack>
            ) : (
              <Stack flexDirection="row" alignItems="center">
                <Typography
                  variant="h2"
                  m={0}
                  sx={{ height: 45.5, lineHeight: 2 }}
                >
                  {title}
                </Typography>
                <JumboIconButton
                  onClick={() => dispatch(changeNameEditable(true))}
                >
                  <EditIcon color="primary" sx={{ fontSize: 18 }} />
                </JumboIconButton>
              </Stack>
            )}

            <Stack mb={1} alignItems="flex-start" gap={1}>
              <Typography
                color="text.secondary"
                variant="h5"
                mb={0}
                fontWeight={500}
              >
                {`${modeType[mode]}`}
              </Typography>
              <Typography variant="caption">
                {moment
                  .unix(conversationInfo.get("created_at"))
                  .format("YYYY-MM-DD HH:mm:ss")}
              </Typography>
            </Stack>
            <Typography variant="body2" color="text.secondary"></Typography>
            <Stack flexDirection="row" gap={1}>
              {modalBadge && (
                <Chip
                  label={modalBadge}
                  size="small"
                  color="primary"
                  sx={{ color: "#fff" }}
                />
              )}
              {conversationInfo &&
                conversationInfo.get("knowledge_bases") &&
                conversationInfo
                  .get("knowledge_bases")
                  .map((kb) => (
                    <Chip
                      key={kb.get("id")}
                      label={kb.get("name")}
                      size="small"
                      color="info"
                      sx={{ color: "#fff" }}
                    />
                  ))}
            </Stack>
          </Stack>

          <IconButton onClick={handleDelModalOpen}>
            <DeleteIcon />
          </IconButton>
        </Div>
        <Divider />

        <MessageBox
          type={conversationInfo.getIn(["metadata", "conversation_mode"])}
          model={model}
          user={user}
          logs={logs}
          isSending={isSending}
          isHasKb={isHasKb}
          handleCopyContent={handleCopyContent}
          handleSetIndex={handleSetIndex}
          handleGetReference={handleGetReference}
        />

        <InputSection
          logs={logs}
          message={message}
          isWaiting={isWaiting}
          isStreamMode={isStreamMode}
          isLoading={isLoading}
          suggestedQuestions={suggestedQuestionsMemo}
          promptList={promptList}
          handleChange={handleChange}
          handleSendMessage={handleSendMessage}
          handleStopStream={handleStopStream}
          handleStopBatch={handleStopBatch}
          handleResetConversation={handleResetConversation}
        />
      </Card>
      <ConfirmModal
        open={isDelModalOpen}
        content="確認刪除此對話？"
        onClose={handleDelModalClose}
        onConfirm={handleDelConversation}
      />
    </>
  );
}

export default React.memo(ChatRoom);

ChatRoom.propTypes = {
  logs: PropTypes.instanceOf(List).isRequired,
  title: PropTypes.string.isRequired,
  conversationId: PropTypes.string.isRequired,
  sources: PropTypes.instanceOf(List),
  isStreamMode: PropTypes.bool,
  modalBadge: PropTypes.string,
  conversationInfo: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  model: PropTypes.string.isRequired,
  knowledgebase: PropTypes.object,
  selectedSources: PropTypes.instanceOf(List),
  mode: PropTypes.string,
};

const modeType = {
  knowledgebase: "正在與知識庫對話",
  language_model: "正在與模型對話",
  text_to_sql: "智慧資料分析",
};
