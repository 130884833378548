import ExecutePage from "app/pages/plugin/execute";
import React from "react";

const ExecuteRoutes = [
  {
    path: "/plugin/:id/execute",
    element: <ExecutePage />,
  },
];

export default ExecuteRoutes;
