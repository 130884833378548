import JumboIconButton from "@jumbo/components/JumboIconButton";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";

const APIKeyTransform = ({ apiKey }) => {
  const [isShow, setIsShow] = useState(false);

  const key = apiKey === "empty" || apiKey === "key" ? "" : apiKey;

  const maskedKey = isShow ? key : "•".repeat(10);

  if (!key) {
    return <Typography>N/A</Typography>;
  }

  return (
    <Stack flexDirection="row" alignItems="center">
      <Typography>{isShow ? apiKey : maskedKey}</Typography>
      <JumboIconButton onClick={() => setIsShow(!isShow)}>
        {isShow ? <VisibilityOffIcon /> : <RemoveRedEyeIcon />}
      </JumboIconButton>
    </Stack>
  );
};

export default APIKeyTransform;

APIKeyTransform.propTypes = {
  apiKey: PropTypes.string.isRequired,
};
