import { DrawerHeader } from "@jumbo/components/JumboLayout/style";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import JumboVerticalNavbar from "@jumbo/components/JumboVerticalNavbar/JumboVerticalNavbar";
import { useJumboLayoutSidebar, useJumboSidebarTheme } from "@jumbo/hooks";
import Div from "@jumbo/shared/Div";
import { SIDEBAR_STYLES, SIDEBAR_VIEWS } from "@jumbo/utils/constants/layout";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import { IconButton } from "@mui/material";
import Zoom from "@mui/material/Zoom";
import React, { Suspense } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";

import Logo from "../../../../shared/Logo";
import adminMenu from "./adminMenu";
import Information from "./information";
import { menuConvert } from "./menus";
import SidebarSkeleton from "./SidebarSkeleton";

function Sidebar() {
  const { pathname } = useLocation();

  const user = useSelector(({ app }) => app.get("user"));
  const conversationList = useSelector(({ conversation }) =>
    conversation.getIn(["results", "conversations"]),
  );

  const menus = menuConvert(conversationList);

  if (!user) return null;

  return (
    <>
      <SidebarHeader />
      <Information user={user} />
      <JumboScrollbar autoHide autoHideDuration={200} autoHideTimeout={500}>
        <Suspense
          fallback={
            <Div
              sx={{
                display: "flex",
                minWidth: 0,
                alignItems: "center",
                alignContent: "center",
                px: 3,
              }}
            >
              <SidebarSkeleton />
            </Div>
          }
        >
          {pathname.includes("/admin") && (
            <JumboVerticalNavbar translate items={adminMenu} />
          )}
          {!pathname.includes("/admin") && (
            <JumboVerticalNavbar translate items={menus.toJS()} />
          )}
        </Suspense>
      </JumboScrollbar>
    </>
  );
}

function SidebarHeader() {
  const { sidebarOptions, setSidebarOptions } = useJumboLayoutSidebar();
  const { sidebarTheme } = useJumboSidebarTheme();

  const isMiniAndClosed = React.useMemo(() => {
    return sidebarOptions?.view === SIDEBAR_VIEWS.MINI && !sidebarOptions?.open;
  }, [sidebarOptions.view, sidebarOptions.open]);

  return (
    <div>
      {sidebarOptions?.style !== SIDEBAR_STYLES.CLIPPED_UNDER_HEADER && (
        <DrawerHeader>
          <Logo mini={isMiniAndClosed} mode={sidebarTheme.type} />
          <Zoom in={sidebarOptions?.open}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              sx={{ ml: 0, mr: -1.5 }}
              onClick={() => setSidebarOptions({ open: false })}
            >
              <MenuOpenIcon />
            </IconButton>
          </Zoom>
        </DrawerHeader>
      )}
    </div>
  );
}

export default Sidebar;
