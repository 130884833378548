import "@xyflow/react/dist/style.css";

import { Button, Divider, Typography } from "@mui/material";
import {
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  Background,
  Controls,
  ReactFlow,
} from "@xyflow/react";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";

import ApiNode from "./nodes/ApiNode";
import FileWriterNode from "./nodes/FileWriterNode";
import ImageWriterNode from "./nodes/ImageWriterNode";
import LLMNode from "./nodes/LLMNode";
import ParameterNode from "./nodes/ParameterNode";
import TextWriterNode from "./nodes/TextWriterNode";
import URLWriterNode from "./nodes/URLWriterNode";

const nodeTypes = {
  parameter: ParameterNode,
  api: ApiNode,
  llm: LLMNode,
  fileWriter: FileWriterNode,
  urlWriter: URLWriterNode,
  imageWriter: ImageWriterNode,
  textWriter: TextWriterNode,
};

const nodeCategories = {
  input: ["parameter"],
  action: ["api", "llm"],
  output: ["fileWriter", "urlWriter", "imageWriter", "textWriter"],
};

const Flow = ({
  nodes,
  edges,
  setNodes,
  setEdges,
  onDataChanges = () => {},
  appBarColor = "#1976d2",
}) => {
  const [showNodeTypeMenu, setShowNodeTypeMenu] = useState(false);

  useEffect(() => {
    onDataChanges(nodes, edges);
  }, [nodes, edges, onDataChanges]);

  const onNodesChange = useCallback((changes) => {
    setNodes((nds) => applyNodeChanges(changes, nds));
  }, []);

  const onEdgesChange = useCallback((changes) => {
    setEdges((eds) => applyEdgeChanges(changes, eds));
  }, []);

  const onConnect = useCallback((connection) => {
    setEdges((eds) => addEdge(connection, eds));
  }, []);

  const addNewNode = (type) => {
    const newNode = {
      id: `node-${nodes.length + 1}`,
      type: type,
      position: { x: 100, y: 100 },
      data: {
        name: `new_${type}_node`,
        inputs: [],
        outputs: [],
      },
    };

    // Add default configuration for specific node types
    switch (type) {
      case "api":
        newNode.data = {
          ...newNode.data,
          url: "",
          method: "GET",
          headers: [],
          inputs: [],
          outputs: [],
        };
        break;
      case "parameter":
        newNode.data = {
          ...newNode.data,
          paramLabel: "",
          paramType: "str",
          paramValue: "",
          paramDescription: "",
          options: [],
          outputs: [{ id: "output", label: "output" }],
        };
        break;
      case "llm":
        newNode.data = {
          ...newNode.data,
          model: "",
          prompt: "",
          temperature: 0.7,
          max_tokens: 1024,
          system_prompt: "",
          inputs: [],
          outputs: [{ id: "output", label: "output" }],
        };
        break;
      case "fileWriter":
        newNode.data = {
          ...newNode.data,
          filePath: "",
          content: "",
          mode: "write",
          inputs: [{ id: "input", label: "input" }],
          outputs: [],
        };
        break;
      case "urlWriter":
        newNode.data = {
          ...newNode.data,
          url: "",
          inputs: [{ id: "input", label: "input" }],
          outputs: [],
        };
        break;
      case "imageWriter":
        newNode.data = {
          ...newNode.data,
          imagePath: "",
          inputs: [{ id: "input", label: "input" }],
          outputs: [],
        };
        break;
      case "textWriter":
        newNode.data = {
          ...newNode.data,
          text: "",
          inputs: [{ id: "input", label: "input" }],
          outputs: [],
        };
        break;
      default:
        break;
    }

    setNodes((nds) => [...nds, newNode]);
    setShowNodeTypeMenu(false);
  };

  const handleAddNodeClick = () => {
    setShowNodeTypeMenu(!showNodeTypeMenu);
  };

  const handleNodeTypeClick = (type) => {
    addNewNode(type);
  };

  return (
    <div style={{ height: "100%", position: "relative" }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        fitView
      >
        <Background />
        <Controls />
      </ReactFlow>
      <Button
        onClick={handleAddNodeClick}
        variant="contained"
        style={{
          position: "absolute",
          top: "20px",
          left: "20px",
          zIndex: 4,
          minWidth: "40px",
          width: "40px",
          height: "40px",
          borderRadius: "50%",
          padding: 0,
        }}
        sx={{
          backgroundColor: appBarColor,
          "&:hover": {
            backgroundColor: adjustColor(appBarColor, -20),
          },
        }}
      >
        +
      </Button>
      {showNodeTypeMenu && (
        <div
          style={{
            position: "absolute",
            top: "70px",
            left: "20px",
            zIndex: 4,
            backgroundColor: "white",
            borderRadius: "5px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            padding: "10px",
          }}
        >
          {Object.entries(nodeCategories).map(([category, types]) => (
            <React.Fragment key={category}>
              <Typography
                variant="subtitle1"
                style={{ fontWeight: "bold", marginTop: "10px" }}
              >
                {category.charAt(0).toUpperCase() + category.slice(1)}
              </Typography>
              <Divider style={{ margin: "5px 0" }} />
              {types.map((type) => (
                <Button
                  key={type}
                  onClick={() => handleNodeTypeClick(type)}
                  fullWidth
                  style={{
                    justifyContent: "flex-start",
                    padding: "5px 10px",
                    textTransform: "none",
                  }}
                >
                  {type}
                </Button>
              ))}
            </React.Fragment>
          ))}
        </div>
      )}
    </div>
  );
};

// Helper function to darken/lighten color
function adjustColor(color, amount) {
  return (
    "#" +
    color
      .replace(/^#/, "")
      .replace(/../g, (color) =>
        (
          "0" +
          Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)
        ).substr(-2),
      )
  );
}

Flow.propTypes = {
  initialNodes: PropTypes.array.isRequired,
  initialEdges: PropTypes.array.isRequired,
  onDataChanges: PropTypes.func,
  appBarColor: PropTypes.string,
};

export default Flow;
