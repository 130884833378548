import Div from "@jumbo/shared/Div";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
  Button,
  Divider,
  Modal,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { uploadSource } from "app/redux/actions/knowledgebase";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

import UploadSection from "../Form/UploadSection";
import UrlSection from "./UrlSection";

const UploadModal = ({ kId }) => {
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [type, setType] = useState("select");

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
    setType("select");
  };

  const handleChangeType = (type) => {
    setType(type);
  };

  const handleUploadSource = (data) => {
    if (data) {
      const dataLength = data.length;
      for (let i = 0; i < dataLength; i++) {
        const source = {
          type: "file",
          path: data[i].path,
        };
        dispatch(uploadSource(kId, source));
      }
    }

    const delay = 1000;
    setTimeout(() => {
      handleClose();
    }, delay);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    pt: 2,
    pb: 2,
    borderRadius: 1,
  };

  return (
    <>
      <Modal open={isOpen} onClose={handleClose}>
        <Div sx={{ ...style }}>
          <Div sx={{ px: 3 }}>
            <Stack flexDirection="row" gap={0.3}>
              <Typography variant="h3">{typeDict[type].text}</Typography>
              <Tooltip title={typeDict[type].tooltip} placement="top-start">
                <ErrorOutlineIcon
                  sx={{
                    fontSize: 16,
                    color: "#c0c0c0",
                  }}
                />
              </Tooltip>
            </Stack>
          </Div>
          <Divider sx={{ height: 1, mt: 1, mb: 3 }} />
          <Div>
            {type === "select" && (
              <SelectSection handleChangeType={handleChangeType} />
            )}
            {type === "files" && (
              <UploadSection multiple onFinish={handleUploadSource} />
            )}
            {type === "url" && <UrlSection kId={kId} onClose={handleClose} />}
          </Div>
        </Div>
      </Modal>
      <Button
        variant="contained"
        color="primary"
        sx={{ mb: 3 }}
        onClick={handleOpen}
      >
        新增來源
      </Button>
    </>
  );
};

const SelectSection = ({ handleChangeType }) => {
  return (
    <Stack flexDirection="row" gap={2} sx={{ px: 3 }}>
      <Button
        sx={{ width: 200, height: 200 }}
        variant="outlined"
        onClick={() => handleChangeType("url")}
      >
        新增網址
      </Button>
      <Button
        sx={{ width: 200, height: 200 }}
        variant="outlined"
        onClick={() => handleChangeType("files")}
      >
        新增檔案
      </Button>
    </Stack>
  );
};

export default UploadModal;

UploadModal.propTypes = {
  kId: PropTypes.string,
};

const typeDict = {
  select: {
    text: "新增來源",
    tooltip: "請選擇新增來源的方式",
  },
  url: {
    text: "新增網址",
    tooltip:
      "新增網址，因資安考量，不開放MyNVT網址，但可自行整理為支援的檔案格式後上載",
  },
  files: {
    text: "新增檔案",
    tooltip:
      "新增檔案，檔案格式支援(請不要加密)：pdf, docx, pptx, xlsx, csv, txt, json, .cs, .cpp, .c, .py, .html, .msg, .md",
  },
};
