import PluginPage from "app/pages/admin/plugin";
import ProcessMasterPage from "app/pages/admin/plugin/processmaster";
import React from "react";

const adminPluginRoutes = [
  {
    path: "/admin/plugins",
    element: <PluginPage />,
  },
  {
    path: "/admin/plugin/process_master",
    element: <ProcessMasterPage />,
  },
  {
    path: "/admin/plugin/process_master/:id",
    element: <ProcessMasterPage />,
  },
];

export default adminPluginRoutes;
