import { Card, Divider, Typography } from "@mui/material";
import HealthGroup from "app/shared/HealthGroup";
import React from "react";

const AdminDashboardPage = () => {
  return (
    <Card sx={{ p: 4 }}>
      <Typography variant="h1" sx={{ m: 0 }}>
        管理員後台
      </Typography>

      <Divider sx={{ my: 4 }} />
      <Typography variant="h3">伺服器狀態</Typography>
      <HealthGroup />
    </Card>
  );
};

export default AdminDashboardPage;
