import JumboIconButton from "@jumbo/components/JumboIconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { deleteModel } from "app/redux/actions/model";
import { List } from "immutable";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

import APIKeyTransform from "../APIKeyTransform";
import ConfirmModal from "../Modal/ConfirmModal";

const ModelDataTable = ({ dataSource }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isDelModalOpen, setIsDelModalOpen] = useState(false);
  const [modelId, setModelId] = useState(null);

  const handleDelModalOpen = (id) => {
    setIsDelModalOpen(true);
    setModelId(id);
  };

  const handleDelModalClose = () => {
    setIsDelModalOpen(false);
    setModelId(null);
  };

  const handleDeleteModel = () => {
    dispatch(deleteModel(modelId));
    handleDelModalClose();
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>模型名稱</TableCell>
              <TableCell>網址</TableCell>
              <TableCell>最大Token數</TableCell>
              <TableCell>API KEY</TableCell>
              <TableCell>建立日期</TableCell>
              <TableCell>編輯</TableCell>
              <TableCell>刪除</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataSource.map((model) => (
              <TableRow key={model.get("id")}>
                <TableCell sx={{ width: "12%" }}>{model.get("name")}</TableCell>
                <TableCell sx={{ width: "26%" }}>
                  {model.get("api_base")}
                </TableCell>
                <TableCell sx={{ width: "10%" }}>
                  {model.get("max_tokens")}
                </TableCell>
                <TableCell sx={{ maxWidth: "28%" }}>
                  <APIKeyTransform apiKey={model.get("api_key")} />
                </TableCell>
                <TableCell sx={{ width: "12%" }}>
                  {moment
                    .unix(model.get("created_at"))
                    .format("YYYY-MM-DD HH:mm:ss")}
                </TableCell>
                <TableCell sx={{ width: "6%" }}>
                  <JumboIconButton
                    sx={{ position: "relative", left: -5 }}
                    onClick={() => navigate(`/admin/model/${model.get("id")}`)}
                  >
                    <EditIcon color="primary" />
                  </JumboIconButton>
                </TableCell>
                <TableCell sx={{ width: "6%" }}>
                  <JumboIconButton
                    sx={{ position: "relative", left: -5 }}
                    onClick={() => handleDelModalOpen(model.get("id"))}
                  >
                    <DeleteIcon color="error" />
                  </JumboIconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ConfirmModal
        open={isDelModalOpen}
        content="確定要刪除此模型嗎？"
        onClose={handleDelModalClose}
        onConfirm={handleDeleteModel}
      />
    </>
  );
};

export default ModelDataTable;

ModelDataTable.propTypes = {
  dataSource: PropTypes.instanceOf(List).isRequired,
};
