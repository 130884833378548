import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { successAlert } from "app/redux/actions/alert";
import { addPrompt, delPrompt } from "app/redux/actions/app";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import PromptModal from "../Modal/PromptModal";

const PromptMenu = () => {
  const prompts = useSelector(({ app }) => app.get("prompts"));
  const user = useSelector(({ app }) => app.get("user"));
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [promptValue, setPromptValue] = useState("");

  const open = Boolean(anchorEl);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (item) => {
    navigator.clipboard.writeText(item.prompt);
    dispatch(successAlert("已複製常用指令"));
  };

  const handleOpenPromptModal = () => {
    setIsOpen(true);
    handleClose();
  };

  const handleClosePromptModal = () => {
    setIsOpen(false);
    setPromptValue("");
  };

  const handleChangePrompt = (e) => {
    setPromptValue(e.target.value);
  };

  const handleSubmitPrompt = async () => {
    dispatch(addPrompt(user.id, promptValue));
    handleClosePromptModal();
  };

  const handleDelete = (promptId) => {
    dispatch(delPrompt(user.id, promptId));
  };

  if (!prompts) return null;

  return (
    <>
      <Button variant="outlined" onClick={handleOpen}>
        常用指令集
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {prompts.size === 0 && (
          <MenuItem>
            <Typography variant="body2" textAlign="center">
              目前沒有常用指令
            </Typography>
          </MenuItem>
        )}
        {prompts.map((prompt) => (
          <MenuItem key={prompt.get("id")} onClick={() => handleSelect(prompt)}>
            <Stack
              width="100%"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              gap={1}
            >
              <Typography variant="body2" width="100%">
                {prompt.get("prompt")}
              </Typography>
              <IconButton onClick={() => handleDelete(prompt.get("id"))}>
                <DeleteIcon />
              </IconButton>
            </Stack>
          </MenuItem>
        ))}
        <Divider />
        <MenuItem sx={{ py: 0, display: "flex", justifyContent: "center" }}>
          <Button
            fullWidth
            size="small"
            startIcon={<AddIcon />}
            onClick={handleOpenPromptModal}
          >
            新增常用指令
          </Button>
        </MenuItem>
      </Menu>
      <PromptModal
        open={isOpen}
        value={promptValue}
        onClose={handleClosePromptModal}
        onSubmit={handleSubmitPrompt}
        onChange={handleChangePrompt}
      />
    </>
  );
};

export default PromptMenu;
