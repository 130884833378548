import {
  closestCorners,
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  restrictToParentElement,
  restrictToVerticalAxis,
} from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import JumboIconButton from "@jumbo/components/JumboIconButton";
import Div from "@jumbo/shared/Div";
import DehazeIcon from "@mui/icons-material/Dehaze";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import NavigationIcon from "@mui/icons-material/Navigation";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import {
  cngCategoryPosition,
  deleteCategory,
  updateCategory,
} from "app/redux/actions/menu";
import { List } from "immutable";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

import ConfirmModal from "../Modal/ConfirmModal";

const CategoryDataTable = ({ dataSource }) => {
  const dispatch = useDispatch();

  const sensors = useSensors(useSensor(PointerSensor));

  const [isDelModalOpen, setIsDelModalOpen] = useState(false);
  const [menuId, setMenuId] = useState(null);

  const handleDelModalOpen = (id) => {
    setMenuId(id);
    setIsDelModalOpen(true);
  };

  const handleDelModalClose = () => {
    setMenuId(null);
    setIsDelModalOpen(false);
  };

  const handleDeleteMenu = async () => {
    await dispatch(deleteCategory(menuId));
    handleDelModalClose();
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      const oldIndex = dataSource.findIndex(
        (category) => category.get("sn") === active.id,
      );
      const newIndex = dataSource.findIndex(
        (category) => category.get("sn") === over.id,
      );

      const updatedItems = arrayMove(dataSource.toJS(), oldIndex, newIndex);
      const updatedArray = updatedItems.map((item, index) => ({
        ...item,
        sn: index + 1,
      }));

      // 過濾出 active 和 over 的 sn，updatedActive為被調換的物件，updatedOver為被拖動的物件
      const [updatedActive, updatedOver] = updatedArray.filter(
        (item) => item.sn === active.id || item.sn === over.id,
      );

      dispatch(cngCategoryPosition(updatedActive, updatedOver));
    }
  };

  return (
    <>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCorners}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToVerticalAxis, restrictToParentElement]}
      >
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>主選單名稱</TableCell>
                <TableCell>建立日期</TableCell>
                <TableCell>子選單</TableCell>
                <TableCell>刪除</TableCell>
                <TableCell>排序</TableCell>
              </TableRow>
            </TableHead>

            <SortableContext
              items={dataSource.map((category) => category.get("sn"))}
              strategy={verticalListSortingStrategy}
            >
              <TableBody>
                {dataSource.map((category) => (
                  <SortableTableRow
                    key={category.get("id")}
                    category={category}
                    dataSource={dataSource}
                    onDelete={() => handleDelModalOpen(category.get("id"))}
                  />
                ))}
              </TableBody>
            </SortableContext>
          </Table>
        </TableContainer>
      </DndContext>
      <ConfirmModal
        open={isDelModalOpen}
        content={
          <>
            <Typography variant="body1">確定要刪除此選單嗎？</Typography>
          </>
        }
        onClose={handleDelModalClose}
        onConfirm={handleDeleteMenu}
      />
    </>
  );
};

export default React.memo(CategoryDataTable);

CategoryDataTable.propTypes = {
  dataSource: PropTypes.instanceOf(List).isRequired,
};

export const SortableTableRow = ({ category, dataSource, onDelete }) => {
  const navigate = useNavigate();
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: category.get("sn") });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <TableRow ref={setNodeRef} {...attributes} style={style}>
      <TableCell>
        <NameEdit
          name={category.get("name")}
          id={category.get("id")}
          dataSource={dataSource}
        />
      </TableCell>
      <TableCell>
        {moment.unix(category.get("created_at")).format("YYYY/MM/DD HH:mm:ss")}
      </TableCell>
      <TableCell>
        <JumboIconButton
          sx={{ position: "relative", left: -5 }}
          onClick={() => navigate(`/admin/menu/${category.get("id")}`)}
        >
          <NavigationIcon
            color="primary"
            sx={{ rotate: "90deg", fontSize: 24 }}
          />
        </JumboIconButton>
      </TableCell>
      <TableCell>
        <JumboIconButton
          sx={{ position: "relative", left: -5 }}
          onClick={onDelete}
        >
          <DeleteIcon color="error" />
        </JumboIconButton>
      </TableCell>
      <TableCell>
        <DehazeIcon {...listeners} sx={{ cursor: "grab" }} />
      </TableCell>
    </TableRow>
  );
};

SortableTableRow.propTypes = {
  category: PropTypes.instanceOf(List).isRequired,
  dataSource: PropTypes.instanceOf(List).isRequired,
  onDelete: PropTypes.func.isRequired,
};

const NameEdit = ({ name, id, dataSource }) => {
  const dispatch = useDispatch();

  const [inputValue, setInputValue] = useState(name);
  const [editingId, setEditingId] = useState(null);
  const [editValues, setEditValues] = useState({});

  const handleNameChange = (id, newName) => {
    if (
      newName !== "" &&
      newName !== dataSource.find((menu) => menu.get("id") === id).get("name")
    ) {
      dispatch(updateCategory(id, { name: newName }));
    }
    setEditingId(null);
    setEditValues({});
  };

  useEffect(() => {
    if (editingId !== id) {
      setInputValue(name);
    }
  }, [editingId, id, name]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleNameChange(id, inputValue);
    }
  };

  const handleEditStart = () => {
    if (editingId && editingId !== id) {
      setEditValues((prev) => ({
        ...prev,
        [editingId]: dataSource
          .find((menu) => menu.get("id") === editingId)
          .get("name"),
      }));
    }
    setEditingId(id);
    setInputValue(editValues[id] || name);
  };

  const handleConfirm = () => {
    handleNameChange(id, inputValue);
  };

  return editingId === id ? (
    <Div sx={{ display: "flex", alignItems: "center" }}>
      <TextField
        size="small"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyDown={handleKeyDown}
      />
      <Button
        onClick={handleConfirm}
        variant="contained"
        size="small"
        sx={{ ml: 1 }}
      >
        確認
      </Button>
    </Div>
  ) : (
    <Div sx={{ display: "flex", alignItems: "center" }}>
      <Typography>{name}</Typography>
      <JumboIconButton onClick={handleEditStart}>
        <EditIcon sx={{ fontSize: 16 }} />
      </JumboIconButton>
    </Div>
  );
};

NameEdit.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  dataSource: PropTypes.instanceOf(List).isRequired,
};
