import JumboIconButton from "@jumbo/components/JumboIconButton";
import DeleteIcon from "@mui/icons-material/Delete";
// import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Button,
  Card,
  Divider,
  Paper,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  createUser,
  updateUser,
  updUserPermission,
} from "app/redux/actions/group";
import { delUser } from "app/redux/actions/group";
import ConfirmModal from "app/shared/Modal/ConfirmModal";
import UserModal from "app/shared/Modal/UserModal";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const AdminUserPage = () => {
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [isDelModalOpen, setIsDelModalOpen] = useState(false);

  const user = useSelector(({ app }) => app.get("user"));

  const groups = useSelector(({ group }) => group.getIn(["results", "groups"]));

  const handleModalOpen = () => {
    setIsOpen(true);
  };

  const handleEditModalOpen = (user) => {
    setSelectedUser(user);
    setIsOpen(true);
    setIsEdit(true);
  };

  const handleModalClose = () => {
    setSelectedUser(null);
    setIsEdit(false);
    setIsOpen(false);
  };

  const handleChangePermission = (checked, user) => {
    dispatch(updUserPermission(user.get("group_id"), user.get("id"), checked));
  };

  const handleSubmit = (values) => {
    if (isEdit) {
      dispatch(updateUser(user.group_id, selectedUser.get("id"), values));
    }
    if (!isEdit) {
      dispatch(createUser(user.group_id, values));
    }
    handleModalClose();
  };

  const handleDelModalOpen = (gId, uId) => {
    setSelectedUser(uId);
    setSelectedGroupId(gId);
    setIsDelModalOpen(true);
  };

  const handleDelModalClose = () => {
    setSelectedUser(null);
    setSelectedGroupId(null);
    setIsDelModalOpen(false);
  };

  const handleDeleteUser = () => {
    dispatch(delUser(selectedGroupId, selectedUser));
    handleDelModalClose();
  };

  return (
    <>
      <Card sx={{ p: 4 }}>
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h3" sx={{ m: 0 }}>
            使用者管理
          </Typography>
          <Stack flexDirection="row" gap={2}>
            <Button variant="contained" onClick={handleModalOpen}>
              建立
            </Button>
          </Stack>
        </Stack>

        <Divider sx={{ my: 4 }} />

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>使用者名稱</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>權限</TableCell>
                <TableCell>建立日期</TableCell>
                <TableCell>啟用用戶</TableCell>
                <TableCell>設定</TableCell>
                <TableCell>刪除</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {groups &&
                groups.map((group) => (
                  <TableRow key={group.get("id")}>
                    <TableCell>{group.get("username")}</TableCell>
                    <TableCell>{group.get("email")}</TableCell>
                    <TableCell>{group.get("role")}</TableCell>
                    <TableCell>
                      {moment
                        .unix(group.get("created_at"))
                        .format("YYYY-MM-DD HH:mm:ss")}
                    </TableCell>
                    <TableCell>
                      <Switch
                        checked={group.get("is_enabled")}
                        onChange={(e, checked) =>
                          handleChangePermission(checked, group)
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <JumboIconButton
                        sx={{ position: "relative", left: -5 }}
                        onClick={() => handleEditModalOpen(group)}
                      >
                        <EditIcon color="primary" />
                      </JumboIconButton>
                    </TableCell>
                    <TableCell sx={{ width: "6%" }}>
                      <JumboIconButton
                        sx={{ position: "relative", left: -5 }}
                        onClick={() =>
                          handleDelModalOpen(
                            group.get("group_id"),
                            group.get("id"),
                          )
                        }
                      >
                        <DeleteIcon color="error" />
                      </JumboIconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      <UserModal
        user={selectedUser}
        isOpen={isOpen}
        isEdit={isEdit}
        onClose={handleModalClose}
        onConfirm={handleSubmit}
      />
      <ConfirmModal
        open={isDelModalOpen}
        content="確定要刪除此帳號嗎？"
        onClose={handleDelModalClose}
        onConfirm={handleDeleteUser}
      />
    </>
  );
};

export default AdminUserPage;
