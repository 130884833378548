export const FCH_KNOWLEDGEBASES = "KNOWLEDGEBASE/FCH_KNOWLEDGEBASES";
export const FCH_KNOWLEDGEBASES_DONE = "KNOWLEDGEBASE/FCH_KNOWLEDGEBASES_DONE";

export const CREATE_KNOWLEDGEBASE = "KNOWLEDGEBASE/CREATE_KNOWLEDGEBASE";
export const CREATE_KNOWLEDGEBASE_DONE =
  "KNOWLEDGEBASE/CREATE_KNOWLEDGEBASE_DONE";

export const DEL_KNOWLEDGEBASE = "KNOWLEDGEBASE/DEL_KNOWLEDGEBASE";
export const DEL_KNOWLEDGEBASE_DONE = "KNOWLEDGEBASE/DEL_KNOWLEDGEBASE_DONE";

export const FCH_KNOWLEDGEBASE = "KNOWLEDGEBASE/FCH_KNOWLEDGEBASE";
export const FCH_KNOWLEDGEBASE_DONE = "KNOWLEDGEBASE/FCH_KNOWLEDGEBASE_DONE";

export const UPD_NAME = "KNOWLEDGEBASE/UPD_NAME";
export const UPD_NAME_DONE = "KNOWLEDGEBASE/UPD_NAME_DONE";

export const UPD_USER_ROLE = "KNOWLEDGEBASE/UPD_USER_ROLE";
export const UPD_USER_ROLE_DONE = "KNOWLEDGEBASE/UPD_USER_ROLE_DONE";

export const DEL_USER_ROLE = "KNOWLEDGEBASE/DEL_USER_ROLE";
export const DEL_USER_ROLE_DONE = "KNOWLEDGEBASE/DEL_USER_ROLE_DONE";

export const UPD_USER_PERMISSION = "KNOWLEDGEBASE/UPD_USER_PERMISSION";
export const UPD_USER_PERMISSION_DONE =
  "KNOWLEDGEBASE/UPD_USER_PERMISSION_DONE";

export const UPLOAD_SOURCE = "KNOWLEDGEBASE/UPLOAD_SOURCE";
export const UPLOAD_SOURCE_DONE = "KNOWLEDGEBASE/UPLOAD_SOURCE_DONE";
export const UPLOAD_SOURCE_FAIL = "KNOWLEDGEBASE/UPLOAD_SOURCE_FAIL";

export const DEL_SOURCE = "KNOWLEDGEBASE/DEL_SOURCE";
export const DEL_SOURCE_DONE = "KNOWLEDGEBASE/DEL_SOURCE_DONE";

export const RESET = "KNOWLEDGEBASE/RESET";

export const CHECK_SOURCE_STATUS = "KNOWLEDGEBASE/CHECK_SOURCE_STATUS";
export const CHECK_SOURCE_STATUS_DONE =
  "KNOWLEDGEBASE/CHECK_SOURCE_STATUS_DONE";

export const SET_CURRENT_SOURCE_ID = "KNOWLEDGEBASE/SET_CURRENT_SOURCE_ID";

export const SORT_KNOWLEDGEBASES = "KNOWLEDGEBASE/SORT_KNOWLEDGEBASES";
