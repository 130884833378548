import JumboIconButton from "@jumbo/components/JumboIconButton";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  Card,
  Divider,
  FormControl,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import {
  delSource,
  fchKnowledgebase,
  updKnowledgebaseName,
} from "app/redux/actions/knowledgebase";
import Loading from "app/shared/Loading";
import ConfirmModal from "app/shared/Modal/ConfirmModal";
import RoleModal from "app/shared/Modal/RoleModal";
import UploadModal from "app/shared/UploadModal";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";

const EditKnowledgebase = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const searchParams = useParams();

  const [isDelModalOpen, setIsDelModalOpen] = useState(false);
  const [sourceId, setSourceId] = useState(null);
  const [kbName, setKbName] = useState("");

  const user = useSelector(({ app }) => app.get("user"));
  const kId = searchParams.id;
  const knowledgebase = useSelector(({ knowledgebase }) =>
    knowledgebase.getIn(["results", "info"]),
  );

  const groupData = useSelector(({ group }) =>
    group.getIn(["results", "other_groups"]),
  );

  const handleChangeName = (e) => {
    setKbName(e.target.value);
  };

  const handleUpdName = async () => {
    if (kbName === knowledgebase.get("name")) return;
    await dispatch(updKnowledgebaseName(kId, kbName));
  };

  const handleDelModalOpen = (id) => {
    setIsDelModalOpen(true);
    setSourceId(id);
  };

  const handleDelModalClose = () => {
    setIsDelModalOpen(false);
    setSourceId(null);
  };

  const handleDelete = async () => {
    await dispatch(delSource(kId, sourceId));
    handleDelModalClose();
  };

  const fchData = async (id) => {
    const res = await dispatch(fchKnowledgebase(id));
    if (!res.success) {
      navigate("/404", { replace: true });
      return;
    }
    setKbName(res.data.name);
  };

  useEffect(() => {
    if (!kId) return;

    fchData(kId);
  }, [kId]);

  if (!knowledgebase || !user) return <Loading />;

  const isAdmin = user.role === "admin";
  const isOwner = user.id === knowledgebase.get("user_id");

  const rule = knowledgebase
    .get("rules")
    .find((rule) => rule.get("user_id") === user.id);

  const canWrite = rule && rule.get("w");
  const canDelete = rule && rule.get("x");

  return (
    <>
      <Card sx={{ p: 4 }}>
        <Stack flexDirection="row" alignItems="center">
          <JumboIconButton onClick={() => navigate(-1)}>
            <ArrowBackOutlinedIcon />
          </JumboIconButton>
          <Typography variant="h3" sx={{ m: 0 }}>
            知識庫設定
          </Typography>
        </Stack>

        <Divider sx={{ my: 4 }} />
        <Stack sx={{ width: { md: 500, sx: "100%" }, mb: 3 }}>
          <Typography variant="h4" sx={{ mb: 2 }}>
            知識庫名稱
          </Typography>
          <Stack flexDirection="row" alignItems="center" gap={2}>
            <TextField
              name="name"
              sx={{ width: "70%" }}
              disabled={!isOwner && !isAdmin && !canWrite}
              placeholder="請輸入知識庫名稱"
              value={kbName}
              onChange={handleChangeName}
            />
            {(isOwner || isAdmin || canWrite) && (
              <Button variant="contained" onClick={handleUpdName}>
                更新名稱
              </Button>
            )}
          </Stack>
        </Stack>
        <Stack flexDirection="row" gap={2}>
          {(isOwner || isAdmin || canWrite) && <UploadModal kId={kId} />}
          {(isOwner || isAdmin) && (
            <RoleModal
              groupData={groupData}
              roleData={knowledgebase.get("rules")}
              kId={kId}
            />
          )}
        </Stack>
        <FormControl sx={{ width: "100%", mb: 3 }}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>編號</TableCell>
                  <TableCell>資料</TableCell>
                  <TableCell>類型</TableCell>
                  <TableCell>狀態</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {knowledgebase.get("sources") &&
                  knowledgebase.get("sources").map((source, index) => (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{pathConvert(source.get("path"))}</TableCell>
                      <TableCell>{typeDict[source.get("type")]}</TableCell>
                      <TableCell
                        sx={{
                          color: statusDict[source.get("status")].color,
                        }}
                      >
                        {source.get("message") &&
                        source.get("message").includes("目前不支援此類型")
                          ? "格式不支援"
                          : statusDict[source.get("status")].text}
                      </TableCell>
                      {(isOwner || isAdmin || canDelete) && (
                        <TableCell>
                          <JumboIconButton
                            onClick={() => handleDelModalOpen(source.get("id"))}
                          >
                            <DeleteIcon color="error" />
                          </JumboIconButton>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </FormControl>
      </Card>
      <ConfirmModal
        content="確認刪除該筆資料？"
        open={isDelModalOpen}
        onClose={handleDelModalClose}
        onConfirm={handleDelete}
      />
    </>
  );
};

export default EditKnowledgebase;

const typeDict = {
  file: "檔案",
  url: "網址",
};

const pathConvert = (path) => {
  if (!path) return "";
  if (path.startsWith("http")) return path;
  return path.split("/").pop();
};

const statusDict = {
  INIT: {
    text: "初始化",
    color: "grey",
  },
  UPLOADING: {
    text: "上傳中",
    color: "orange",
  },
  DONE: {
    text: "已上傳",
    color: "green",
  },
  FAILED: {
    text: "上傳失敗",
    color: "red",
  },
  DENIED: {
    text: "沒有權限",
    color: "red",
  },
};
