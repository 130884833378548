import { Button, Menu, MenuItem, TextField } from "@mui/material";
import { useReactFlow } from "@xyflow/react";
import { tryPluginAction } from "app/redux/actions/plugin";
import Immutable from "immutable";
import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import NodeWrapper from "./NodeWrapper";

const LLMNode = ({ id, data }) => {
  const [model, setModel] = useState(data.model || "");
  const [prompt, setPrompt] = useState(data.prompt || "");
  const [temperature, setTemperature] = useState(data.temperature || 0.7);
  const [maxTokens, setMaxTokens] = useState(data.max_tokens || 1024);
  const [systemPrompt, setSystemPrompt] = useState(data.system_prompt || "");
  const [anchorEl, setAnchorEl] = useState(null);
  const { setNodes } = useReactFlow();
  const dispatch = useDispatch();

  const modelData = useSelector(({ model }) =>
    model.getIn(["results", "models"], Immutable.List()),
  );

  const handleTry = useCallback(
    ({ name, inputs }) => {
      const node = {
        model,
        prompt,
        temperature,
        max_tokens: maxTokens,
        system_prompt: systemPrompt,
      };
      return dispatch(
        tryPluginAction({ type: "llm", name, node, values: inputs }),
      );
    },
    [model, prompt, temperature, maxTokens, systemPrompt, dispatch],
  );

  const updateNodeData = useCallback(
    (newData) => {
      setNodes((nds) =>
        nds.map((node) => {
          if (node.id === id) {
            node.data = { ...node.data, ...newData };
          }
          return node;
        }),
      );
    },
    [id, setNodes],
  );

  const handleModelClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleModelClose = () => {
    setAnchorEl(null);
  };

  const handleModelChange = (newModel) => {
    setModel(newModel);
    updateNodeData({ model: newModel });
    handleModelClose();
  };

  const handlePromptChange = (e) => {
    const newPrompt = e.target.value;
    setPrompt(newPrompt);
    updateNodeData({ prompt: newPrompt });
  };

  const handleTemperatureChange = (e) => {
    const newTemperature = parseFloat(e.target.value);
    setTemperature(newTemperature);
    updateNodeData({ temperature: newTemperature });
  };

  const handleMaxTokensChange = (e) => {
    const newMaxTokens = parseInt(e.target.value);
    setMaxTokens(newMaxTokens);
    updateNodeData({ max_tokens: newMaxTokens });
  };

  const handleSystemPromptChange = (e) => {
    const newSystemPrompt = e.target.value;
    setSystemPrompt(newSystemPrompt);
    updateNodeData({ system_prompt: newSystemPrompt });
  };

  return (
    <NodeWrapper
      id={id}
      title="LLM Node"
      initialInputs={data.inputs || []}
      initialOutputs={data.outputs || []}
      width={300}
      data={data}
      setNodes={setNodes}
      canAddInputs={true}
      canAddOutputs={false}
      canDeleteInputs={true}
      canDeleteOutputs={false}
      onTry={handleTry}
    >
      <Button
        aria-controls="model-menu"
        aria-haspopup="true"
        onClick={handleModelClick}
        fullWidth
        variant="outlined"
        style={{ marginBottom: "10px" }}
      >
        {model || "Select Model"}
      </Button>
      <Menu
        id="model-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleModelClose}
      >
        {modelData.map((model) => (
          <MenuItem
            key={model.get("name")}
            onClick={() => handleModelChange(model.get("name"))}
          >
            {model.get("name")}
          </MenuItem>
        ))}
      </Menu>

      <TextField
        id={`prompt-input-${id}`}
        label="Prompt"
        value={prompt}
        onChange={handlePromptChange}
        placeholder="Enter prompt"
        fullWidth
        margin="normal"
        variant="outlined"
        multiline
        rows={4}
      />

      <TextField
        id={`system-prompt-input-${id}`}
        label="System Prompt"
        value={systemPrompt}
        onChange={handleSystemPromptChange}
        placeholder="Enter system prompt"
        fullWidth
        margin="normal"
        variant="outlined"
        multiline
        rows={4}
      />

      <TextField
        id={`temperature-input-${id}`}
        label="Temperature"
        type="number"
        value={temperature}
        onChange={handleTemperatureChange}
        fullWidth
        margin="normal"
        variant="outlined"
        inputProps={{
          step: 0.1,
          min: 0,
          max: 1,
        }}
      />

      <TextField
        id={`max-tokens-input-${id}`}
        label="Max Tokens"
        type="number"
        value={maxTokens}
        onChange={handleMaxTokensChange}
        fullWidth
        margin="normal"
        variant="outlined"
        inputProps={{
          step: 1,
          min: 1,
          max: 4096,
        }}
      />
    </NodeWrapper>
  );
};

export default LLMNode;
