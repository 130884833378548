import { Button, Card, Divider, Stack, Typography } from "@mui/material";
import { createCategory } from "app/redux/actions/menu";
import CategoryDataTable from "app/shared/DataTable/CategoryDataTable";
import Loading from "app/shared/Loading";
import CategoryModal from "app/shared/Modal/CategoryModal";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const MenuPage = () => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const categoryList = useSelector(({ menu }) =>
    menu.getIn(["results", "categories"]),
  );

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (values) => {
    const size = categoryList.size;

    const data = {
      ...values,
      sn: size + 1,
    };

    dispatch(createCategory(data));

    handleClose();
  };

  if (!categoryList) return <Loading />;

  return (
    <>
      <Card sx={{ p: 4 }}>
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h3" sx={{ m: 0 }}>
            主選單管理
          </Typography>
          <Stack flexDirection="row" gap={2}>
            <Button variant="contained" onClick={handleOpen}>
              建立
            </Button>
          </Stack>
        </Stack>

        <Divider sx={{ my: 4 }} />

        <CategoryDataTable dataSource={categoryList} />
      </Card>
      <CategoryModal
        isEdit={false}
        isOpen={open}
        onClose={handleClose}
        onSubmit={handleSubmit}
      />
    </>
  );
};

export default MenuPage;
