import Div from "@jumbo/shared/Div";
// import Link from "@mui/material/Link";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

function Logo({ sx }) {
  return (
    <Div sx={{ display: "inline-flex", ...sx }}>
      <Link to="/" sx={{ textDecoration: "none" }}>
        <img src="/logo.png" alt="nova rag" style={{ width: 160 }} />
      </Link>
    </Div>
  );
}

Logo.defaultProps = {
  mode: "light",
};

Logo.propTypes = {
  mini: PropTypes.bool,
  mode: PropTypes.oneOf(["light", "dark"]),
  sx: PropTypes.object,
};

export default Logo;
