import JumboIconButton from "@jumbo/components/JumboIconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Button, Card, Divider, Stack, Typography } from "@mui/material";
import { createSubCategory } from "app/redux/actions/menu";
import SubCategoryDataTable from "app/shared/DataTable/SubCategoryDataTable";
import Loading from "app/shared/Loading";
import MenuModal from "app/shared/Modal/MenuModal";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";

const SubMenuPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const categories = useSelector(({ menu }) =>
    menu.getIn(["results", "categories"]),
  );

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (values) => {
    const size = categories
      .find((category) => category.get("id") === id)
      .get("menus").size;
    const data = {
      ...values,
      sn: size + 1,
      category_id: id,
    };

    dispatch(createSubCategory(data));

    handleClose();
  };

  if (!categories || !id) return <Loading />;

  const category = categories.find((category) => category.get("id") === id);

  return (
    <>
      <Card sx={{ p: 4 }}>
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack flexDirection="row" gap={2} alignItems="center">
            <JumboIconButton onClick={() => navigate(-1)}>
              <ArrowBackIcon />
            </JumboIconButton>
            <Typography variant="h3" sx={{ m: 0 }}>
              主選單管理
            </Typography>
            <KeyboardArrowRightIcon />
            <Typography variant="h3" sx={{ m: 0 }}>
              {category.get("name")}
            </Typography>
          </Stack>
          <Stack flexDirection="row" gap={2}>
            <Button variant="contained" onClick={handleOpen}>
              建立
            </Button>
          </Stack>
        </Stack>

        <Divider sx={{ my: 4 }} />

        <SubCategoryDataTable
          dataSource={category.get("menus")}
          category_id={category.get("id")}
        />
      </Card>
      <MenuModal
        menu={category.get("menus")}
        isOpen={open}
        onClose={handleClose}
        onSubmit={handleSubmit}
      />
    </>
  );
};

export default SubMenuPage;
