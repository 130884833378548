import KnowledgebasePage from "app/pages/knowledgebase";
// import CreateKnowledgebase from "app/pages/knowledgebase/create";
import EditKnowledgebase from "app/pages/knowledgebase/edit";
import React from "react";

const knowledgebaseRoutes = [
  {
    path: "/knowledgebase",
    element: <KnowledgebasePage />,
  },
  // {
  //   path: "/create/knowledgebase",
  //   element: <CreateKnowledgebase />,
  // },
  {
    path: "/knowledgebase/:id",
    element: <EditKnowledgebase />,
  },
];

export default knowledgebaseRoutes;
