export const FCH_GROUPS = "GROUP/FCH_GROUPS";
export const FCH_GROUPS_DONE = "GROUP/FCH_GROUPS_DONE";

export const FCH_OTHER_GROUPS = "GROUP/FCH_OTHER_GROUPS";
export const FCH_OTHER_GROUPS_DONE = "GROUP/FCH_OTHER_GROUPS_DONE";

export const CREATE_USER = "GROUP/CREATE_USER";
export const CREATE_USER_DONE = "GROUP/CREATE_USER_DONE";

export const UPD_USER = "GROUP/UPD_USER";
export const UPD_USER_DONE = "GROUP/UPD_USER_DONE";

export const UPD_PERMISSION = "GROUP/UPD_PERMISSION";
export const UPD_PERMISSION_DONE = "GROUP/UPD_PERMISSION_DONE";

export const DEL_USER = "GROUP/DEL_USER";
export const DEL_USER_DONE = "GROUP/DEL_USER_DONE";
