import Div from "@jumbo/shared/Div";
import { SearchOutlined } from "@mui/icons-material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import LinkIcon from "@mui/icons-material/Link";
import {
  Card,
  Checkbox,
  Divider,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { selectSources } from "app/redux/actions/conversation";
import Immutable from "immutable";
import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import { useDispatch } from "react-redux";

function RetrievalFile({ sources, selectedSources }) {
  const dispatch = useDispatch();

  const [searchTerm, setSearchTerm] = useState("");

  const urlConvert = (path) => {
    if (!path) return "";
    if (path.startsWith("http")) return path;
    return path.split("/").pop();
  };

  const handleSelectSources = (source, checked) => {
    if (checked) {
      dispatch(selectSources(selectedSources.push(source)));
    } else {
      dispatch(
        selectSources(selectedSources.filter((s) => s.id !== source.id)),
      );
    }
  };

  const filteredSources = useMemo(() => {
    return sources.filter((source) =>
      (source.get("path") || source.get("url") || "")
        .toLowerCase()
        .includes(searchTerm.toLowerCase()),
    );
  }, [sources, searchTerm]);

  return (
    <>
      <Card sx={{ p: 3, mb: 3 }}>
        <Stack flexDirection="row">
          <Typography variant="h3" sx={{ mr: 0.3, mb: 0 }}>
            檔案檢索
          </Typography>
          <Tooltip title="請選擇檔案做為對話參考，若未選擇檔案會根據整個知識庫檔案做為對話依據">
            <ErrorOutlineIcon
              sx={{
                fontSize: 16,
                color: "#c0c0c0",
              }}
            />
          </Tooltip>
        </Stack>
        <Divider sx={{ my: 3 }} />
        <TextField
          fullWidth
          placeholder="檔案名稱"
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{ endAdornment: <SearchOutlined /> }}
        />
        <Divider sx={{ my: 3 }} />
        <Div>
          {filteredSources.size > 0 &&
            filteredSources.map((source) => (
              <Div
                key={source.get("id")}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  mb: 2,
                  alignItems: "flex-start",
                }}
              >
                <Checkbox
                  sx={{ mr: 2 }}
                  onChange={(e, checked) =>
                    handleSelectSources(source, checked)
                  }
                />
                <Div sx={{ width: "100%" }}>
                  <Stack
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                    mb={1}
                  >
                    <Stack direction="row" alignItems="center">
                      {typeDict[source.get("type")].icon}
                      <Typography variant="h5" sx={{ mb: 0 }}>
                        {typeDict[source.get("type")].name}
                      </Typography>
                    </Stack>
                    {source.get("pages") && (
                      <Typography variant="subtitle1">
                        {`p. ${source.get("page_number")} / ${source.get(
                          "pages",
                        )}`}
                      </Typography>
                    )}
                  </Stack>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      width: "100%",
                      wordBreak: "break-all",
                      fontWeight: 500,
                    }}
                  >
                    {urlConvert(source.get("path") || source.get("url"))}
                  </Typography>
                  {source.get("message") && (
                    <Typography
                      variant="subtitle1"
                      sx={{ width: "100%", wordBreak: "break-all" }}
                    >
                      {source.get("message").slice(0, 100) + "..."}
                    </Typography>
                  )}
                </Div>
              </Div>
            ))}
          {filteredSources.size === 0 && (
            <Stack
              alignItems="center"
              justifyContent="center"
              height="100%"
              spacing={1}
            >
              <InsertDriveFileIcon sx={{ color: "#ccc", fontSize: 36 }} />
              <Typography variant="body1" color="#ccc">
                查無參考資料
              </Typography>
            </Stack>
          )}
        </Div>
      </Card>
      <Card sx={{ p: 3 }}>
        <Typography variant="h3">已選取檔案 {selectedSources.size}</Typography>
        <Divider sx={{ my: 4 }} />
        <Div>
          {selectedSources.map((source) => (
            <Div
              key={source.get("id")}
              sx={{ display: "flex", flexDirection: "row", mb: 2 }}
            >
              <Div>
                <Div
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    mb: 1,
                  }}
                >
                  {typeDict[source.get("type")].icon}
                  <Typography variant="h5" sx={{ mb: 0 }}>
                    {typeDict[source.get("type")].name}
                  </Typography>
                </Div>
                <Typography
                  variant="subtitle1"
                  sx={{ width: "100%", wordBreak: "break-all" }}
                >
                  {urlConvert(source.get("path") || source.get("url"))}
                </Typography>
              </Div>
            </Div>
          ))}
        </Div>
      </Card>
    </>
  );
}

export default RetrievalFile;

RetrievalFile.propTypes = {
  sources: PropTypes.instanceOf(Immutable.List),
  selectedSources: PropTypes.instanceOf(Immutable.List),
};

const typeDict = {
  url: {
    name: "網址",
    icon: <LinkIcon sx={{ mr: 1 }} />,
  },
  file: {
    name: "檔案",
    icon: <InsertDriveFileIcon sx={{ mr: 1 }} />,
  },
};
