import { Button, Card, Divider, Stack, Typography } from "@mui/material";
import ModelDataTable from "app/shared/DataTable/ModelDataTable";
import Loading from "app/shared/Loading";
import ModelFormModal from "app/shared/Modal/ModelFormModal";
import React, { useState } from "react";
import { useSelector } from "react-redux";

const ModelPage = () => {
  const [isOpen, setIsOpen] = useState(false);

  const modelData = useSelector(({ model }) =>
    model.getIn(["results", "models"]),
  );

  const handleModelOpen = () => {
    setIsOpen(true);
  };

  const handleModelClose = () => {
    setIsOpen(false);
  };

  if (!modelData) return <Loading />;

  return (
    <>
      <Card sx={{ p: 4 }}>
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h3" sx={{ m: 0 }}>
            模型管理
          </Typography>
          <Stack flexDirection="row" gap={2}>
            <Button variant="contained" onClick={handleModelOpen}>
              建立
            </Button>
          </Stack>
        </Stack>

        <Divider sx={{ my: 4 }} />

        <ModelDataTable dataSource={modelData} />
      </Card>
      <ModelFormModal isOpen={isOpen} onClose={handleModelClose} />
    </>
  );
};

export default ModelPage;
