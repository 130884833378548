import DeleteIcon from "@mui/icons-material/Delete";
import { Button, Chip, Popover, Stack } from "@mui/material";
import { addPrompt, delPrompt } from "app/redux/actions/app";
import { List } from "immutable";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import PromptModal from "../Modal/PromptModal";

const PromptPopover = ({ promptList, onSendPrompt }) => {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [promptValue, setPromptValue] = useState("");

  const user = useSelector(({ app }) => app.get("user"));

  const handleChangePrompt = (e) => {
    setPromptValue(e.target.value);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSendPrompt = (prompt) => {
    onSendPrompt(prompt);
    handleClose();
  };

  const handleOpenPromptModal = () => {
    setIsOpen(true);
    handleClose();
  };

  const handleClosePromptModal = () => {
    setIsOpen(false);
  };

  const handleSubmitPrompt = () => {
    dispatch(addPrompt(user.id, promptValue));
    handleClosePromptModal();
  };

  const handleDeletePrompt = (promptId) => {
    dispatch(delPrompt(user.id, promptId));
  };

  if (!promptList) return null;
  return (
    <>
      <Button size="small" variant="outlined" onClick={handleOpen}>
        常用指令
      </Button>
      <Popover
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          sx: {
            bgcolor: "rgba(255, 255, 255, 0)",
            boxShadow: "none",
          },
        }}
      >
        <Stack
          sx={{ px: 2 }}
          direction="column"
          flexWrap="wrap"
          gap={1}
          alignItems="center"
        >
          {promptList.map((prompt) => (
            <Chip
              key={prompt.get("id")}
              sx={{ cursor: "pointer", bgcolor: "#f0f0f0" }}
              label={prompt.get("prompt")}
              deleteIcon={<DeleteIcon />}
              onDelete={() => handleDeletePrompt(prompt.get("id"))}
              onClick={() => handleSendPrompt(prompt.get("prompt"))}
            />
          ))}
          <Chip
            sx={{ cursor: "pointer" }}
            label="＋新增常用指令"
            color="primary"
            onClick={handleOpenPromptModal}
          />
        </Stack>
      </Popover>
      <PromptModal
        open={isOpen}
        onClose={handleClosePromptModal}
        onSubmit={handleSubmitPrompt}
        onChange={handleChangePrompt}
      />
    </>
  );
};

export default PromptPopover;

PromptPopover.propTypes = {
  promptList: PropTypes.instanceOf(List).isRequired,
  onSendPrompt: PropTypes.func.isRequired,
};
