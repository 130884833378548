import { Button, Chip, Popover, Stack } from "@mui/material";
import { List } from "immutable";
import PropTypes from "prop-types";
import React, { useState } from "react";

const SuggestedQuestionModal = ({ suggestedQuestions = List(), onClick }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSendSuggestedQuestion = (question) => {
    onClick(question);
    handleClose();
  };

  if (suggestedQuestions.size === 0) return null;

  return (
    <>
      <Button size="small" variant="outlined" onClick={handleOpen}>
        常見問題
      </Button>
      <Popover
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          sx: {
            bgcolor: "rgba(255, 255, 255, 0)",
            boxShadow: "none",
          },
        }}
      >
        <Stack sx={{ px: 2 }} direction="column" flexWrap="wrap" gap={1}>
          {suggestedQuestions.map((question) => (
            <Chip
              key={question.get("id")}
              sx={{ cursor: "pointer", bgcolor: "#f0f0f0" }}
              label={question.get("question")}
              onClick={() =>
                handleSendSuggestedQuestion(question.get("question"))
              }
            />
          ))}
        </Stack>
      </Popover>
    </>
  );
};

export default SuggestedQuestionModal;

SuggestedQuestionModal.propTypes = {
  suggestedQuestions: PropTypes.instanceOf(List).isRequired,
  onClick: PropTypes.func.isRequired,
};
