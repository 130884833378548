import http from "app/services";

import {
  CREATE_PLUGIN,
  CREATE_PLUGIN_DONE,
  DELETE_PLUGIN,
  DELETE_PLUGIN_DONE,
  EXECUTE_PLUGIN,
  EXECUTE_PLUGIN_DONE,
  FETCH_PLUGINS,
  FETCH_PLUGINS_DONE,
  GET_PLUGIN,
  GET_PLUGIN_DONE,
  THESIS_SUMMARY,
  TRANSLATE,
  TRY_PLUGIN_ACTION,
  TRY_PLUGIN_ACTION_DONE,
  UPDATE_PLUGIN,
  UPDATE_PLUGIN_DONE,
} from "../types/plugin";
import { statusHandler, successAlert } from "./alert";
import { fchConversationList } from "./conversation";

export const translate = (uId, data) => {
  return async (dispatch) => {
    dispatch({ type: TRANSLATE });
    const res = await http.post(`/api/v1/users/${uId}/translate_tasks`, data);
    if (res.response && res.response.status >= 400) {
      dispatch(statusHandler(res.response.status));
      return false;
    }

    return true;
  };
};

export const createPlugin = (data) => {
  return async (dispatch) => {
    dispatch({ type: CREATE_PLUGIN });
    const res = await http.post(`/api/v1/plugins`, data);
    if (res.response && res.response.status >= 400) {
      dispatch(statusHandler(res.response.status));
      return false;
    }
    dispatch({ type: CREATE_PLUGIN_DONE, payload: res.data });
    dispatch(successAlert("新增成功"));
    return true;
  };
};

export const fchPluginList = () => {
  return async (dispatch) => {
    dispatch({ type: FETCH_PLUGINS });
    const res = await http.get(`/api/v1/plugins`);
    dispatch({ type: FETCH_PLUGINS_DONE, payload: { doc: res.data } });
  };
};

export const getPlugin = (id) => {
  return async (dispatch) => {
    dispatch({ type: GET_PLUGIN });
    const res = await http.get(`/api/v1/plugins/${id}`);
    if (res.response && res.response.status >= 400) {
      dispatch(statusHandler(res.response.status));
      return;
    }
    dispatch({ type: GET_PLUGIN_DONE, payload: { doc: res.data } });
    return res.data;
  };
};

export const updatePlugin = (id, data) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_PLUGIN });
    const res = await http.patch(`/api/v1/plugins/${id}`, data);
    if (res.response && res.response.status >= 400) {
      dispatch(statusHandler(res.response.status));
      return false;
    }
    dispatch({ type: UPDATE_PLUGIN_DONE, payload: res.data });
    dispatch(successAlert("更新成功"));
    return true;
  };
};

export const executePlugin = (id, user_id, settings) => {
  return async (dispatch) => {
    dispatch({ type: EXECUTE_PLUGIN });
    const data = {
      settings,
      user_id,
    };
    const res = await http.post(`/api/v1/plugins/${id}/_execute`, data);
    if (res.response && res.response.status >= 400) {
      dispatch(statusHandler(res.response.status));
      return false;
    }
    dispatch({ type: EXECUTE_PLUGIN_DONE, payload: res.data });
    if (!settings) {
      dispatch(fchConversationList(user_id));
      return res.data.payload.id;
    }
    return true;
  };
};

export const deletePlugin = (id) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_PLUGIN });
    const res = await http.delete(`/api/v1/plugins/${id}`);
    if (res.response && res.response.status >= 400) {
      dispatch(statusHandler(res.response.status));
      return;
    }
    dispatch({ type: DELETE_PLUGIN_DONE, payload: { id } });
    dispatch(successAlert("刪除成功"));
    return;
  };
};

export const tryPluginAction = (data) => {
  return async (dispatch) => {
    dispatch({ type: TRY_PLUGIN_ACTION });
    const res = await http.post(`/api/v1/plugins/actions/_try`, data);
    if (res.response && res.response.status >= 400) {
      dispatch(statusHandler(res.response.status));
      return;
    }
    dispatch({ type: TRY_PLUGIN_ACTION_DONE, payload: res.data });
    return res.data;
  };
};

export const thesisSummary = (uId, data) => {
  return async (dispatch) => {
    dispatch({ type: THESIS_SUMMARY });
    const res = await http.post(`/api/v1/users/${uId}/thesis_tasks`, data);
    if (res.response && res.response.status >= 400) {
      dispatch(statusHandler(res.response.status));
      return false;
    }
    return true;
  };
};
