export const homeQA1 = [
  {
    id: "1",
    title: "如何建立知識庫?",
    content:
      "請在新增對話的選單中，找到新增知識庫，點選後，選擇資料來源(例如檔案或連結)，並上傳後，即可建立知識庫。",
  },
  {
    id: "2",
    title: "如何選擇對話模式？",
    content:
      "在建立對話時，使用者可以選擇「知識庫對話」或「語言模型對話」模式。這些選項會在對話設定頁面提供。",
  },
  {
    id: "3",
    title: "系統支援哪些最新的模型？",
    content:
      "系統支援多種最新的模型，包括Llama3 70B和 Mixtral 8x22B等。使用者可以在代理設定頁面選擇合適的模型。",
  },
];

export const homeQA2 = [
  {
    id: "1",
    title: "如何檢索並選擇所需的檔案片段？",
    content:
      "使用者在對話過程中可以檢索相關檔案，系統會自動顯示檢索結果。使用者可以瀏覽結果，選擇所需的片段，將其加入到已選擇檔案中。",
  },
  {
    id: "2",
    title: "可以根據哪些文件進行問答？",
    content:
      " 使用者可以根據已上傳並選擇的文件進行問答。系統會從這些文件中檢索相關內容，提供準確的回答。",
  },
  {
    id: "3",
    title: " 如何設定對話的系統提示詞？",
    content:
      "在建立對話時，使用者可以在對話設定頁面中輸入提示詞。提示詞將幫助代理更準確地理解並生成對話內容。",
  },
];
