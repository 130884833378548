import Div from "@jumbo/shared/Div";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Button, Divider, Modal, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

const ConfirmModal = ({ title, content, open, onClose, onConfirm }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 1,
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Div sx={style}>
        <Stack flexDirection="row" color="orange" gap={1} p={2}>
          <ErrorOutlineIcon />
          <Typography variant="h2" m={0}>
            {title}
          </Typography>
        </Stack>
        <Divider />
        <Div sx={{ p: 2 }}>
          {typeof content === "string" && (
            <Typography variant="body1">{content}</Typography>
          )}
          {React.isValidElement(content) && content}
        </Div>
        <Divider />
        <Stack p={1} flexDirection="row" gap={1} justifyContent="flex-end">
          <Button variant="contained" onClick={onClose} size="small">
            取消
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={onConfirm}
            size="small"
          >
            確定
          </Button>
        </Stack>
      </Div>
    </Modal>
  );
};

export default ConfirmModal;

ConfirmModal.propTypes = {
  title: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

ConfirmModal.defaultProps = {
  title: "警告",
  content: "請問確定要刪除嗎？",
};
