import CloudIcon from "@mui/icons-material/Cloud";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ExtensionIcon from "@mui/icons-material/Extension";
import LayersIcon from "@mui/icons-material/Layers";
import ListIcon from "@mui/icons-material/List";
import PersonIcon from "@mui/icons-material/Person";
import React from "react";

const adminMenu = [
  {
    label: "選單",
    type: "section",
    children: [
      {
        icon: <DashboardIcon />,
        id: "dashboard",
        label: "首頁",
        type: "nav-item",
        uri: "/admin/dashboard",
      },
      {
        icon: <PersonIcon />,
        id: "user",
        label: "使用者管理",
        type: "nav-item",
        uri: "/admin/user",
      },
      {
        icon: <LayersIcon />,
        id: "knowledgebase",
        label: "知識庫管理",
        type: "nav-item",
        uri: "/admin/knowledgebase",
      },
      {
        icon: <CloudIcon />,
        id: "model",
        label: "模型管理",
        type: "nav-item",
        uri: "/admin/model",
      },
      {
        icon: <ListIcon />,
        id: "menu",
        label: "選單管理",
        type: "nav-item",
        uri: "/admin/menu",
      },
      {
        icon: <ExtensionIcon />,
        id: "plugin",
        label: "插件管理",
        type: "nav-item",
        uri: "/admin/plugins",
      },
    ],
  },
];

export default adminMenu;
