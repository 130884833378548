import { useJumboRoutes } from "@jumbo/hooks";
import React from "react";

import routes from "./routes";

function AppRoutes() {
  const appRoutes = useJumboRoutes(routes);
  return <>{appRoutes}</>;
}
export default AppRoutes;
