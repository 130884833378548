import {
  ERROR_ALERT,
  INFO_ALERT,
  REMOVE_ALERT,
  SUCCESS_ALERT,
  WARNING_ALERT,
} from "../types/alert";

const initOptions = {
  autoHideDuration: 3000,
  anchorOrigin: {
    vertical: "top",
    horizontal: "center",
  },
};

export const successAlert = (message) => {
  return (dispatch) => {
    dispatch({
      type: SUCCESS_ALERT,
      payload: {
        message,
        key: new Date().getTime() + Math.random(),
        options: { ...initOptions, variant: "success" },
      },
    });
  };
};

export const errorAlert = (message) => {
  return (dispatch) => {
    dispatch({
      type: ERROR_ALERT,
      payload: {
        message,
        key: new Date().getTime() + Math.random(),
        options: { ...initOptions, variant: "error" },
      },
    });
  };
};

export const infoAlert = (message) => {
  return (dispatch) => {
    dispatch({
      type: INFO_ALERT,
      payload: {
        message,
        key: new Date().getTime() + Math.random(),
        options: { ...initOptions, variant: "info" },
      },
    });
  };
};

export const warningAlert = (message) => {
  return (dispatch) => {
    dispatch({
      type: WARNING_ALERT,
      payload: {
        message,
        key: new Date().getTime() + Math.random(),
        options: { ...initOptions, variant: "warning" },
      },
    });
  };
};

export const removeAlert = (key) => {
  return (dispatch) => {
    dispatch({
      type: REMOVE_ALERT,
      payload: {
        key,
      },
    });
  };
};

export const statusHandler = (status) => {
  let message = "";
  switch (status) {
    case 400:
      message = "請求錯誤";
      break;
    case 401:
      message = "未授權";
      break;
    case 403:
      message = "權限不足";
      break;
    case 404:
      message = "找不到資源";
      window.location.href = "/404";
      break;
    case 500:
      message = "伺服器錯誤";
      break;
    default:
      message = "未知錯誤";
  }

  return (dispatch) => {
    dispatch({
      type: ERROR_ALERT,
      payload: {
        message,
        key: new Date().getTime() + Math.random(),
        options: { ...initOptions, variant: "error" },
      },
    });
  };
};
