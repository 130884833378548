import styled from "@emotion/styled";
import JumboIconButton from "@jumbo/components/JumboIconButton";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { errorAlert } from "app/redux/actions/alert";
import { uploadFile } from "app/redux/actions/files";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

const ImageSection = ({
  position = "center",
  multiple = false,
  onFinish,
  showPath = false,
  error = false,
  helperText = "",
  tooltip = "",
  value,
}) => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState("INIT");
  const [path, setPath] = useState("");
  const fileInputRef = useRef(null);

  const handleFileChange = async (e) => {
    const files = Array.from(e.target.files);

    const isImage = (file) => file.type.startsWith("image/");

    if (files.length > 0) {
      const allImagesValid = multiple
        ? files.every(isImage)
        : isImage(files[0]);

      if (!allImagesValid) {
        dispatch(errorAlert("請上傳圖片檔案"));
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
        return;
      }
    }

    setStatus("UPLOADING");

    try {
      const res = await dispatch(uploadFile(files));
      if (!res) return setStatus("FAIL");

      setStatus("SUCCESS");

      const pathStr = `/files/${res[0].filename}?namespace=${res[0].namespace}`;

      setPath(pathStr);
      onFinish(res);
    } catch (error) {
      console.error(error);
      setStatus("FAIL");
      setTimeout(() => {
        setStatus("INIT");
      }, 3000);
    }
  };

  const handleDeleteFile = () => {
    setStatus("INIT");
    setPath("");
    onFinish([]);
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const statusDict = {
    INIT: {
      BtnText: "上傳圖片",
      BtnIcon: <CloudUploadIcon />,
      fn: null,
      color: "primary",
    },
    UPLOADING: {
      BtnText: "上傳中",
      BtnIcon: <CircularProgress size={16} />,
      fn: null,
      color: "inherit",
    },
    SUCCESS: {
      BtnText: "上傳成功",
      BtnIcon: null,
      color: "success",
    },
    FAIL: {
      BtnText: "上傳失敗",
      BtnIcon: null,
      color: "error",
    },
  };

  const getPositionStyle = () => {
    switch (position) {
      case "left":
        return "flex-start";
      case "right":
        return "flex-end";
      default:
        return "center";
    }
  };

  useEffect(() => {
    if (value && value.length > 0) {
      setStatus("SUCCESS");
      if (multiple) {
        const str = value.map((item) => item.filename).join(", ");
        setPath(str);
      } else {
        const filename = value.split("/").pop();
        setPath(`/files/${filename}?namespace=files`);
      }
    }
  }, [value]);

  return (
    <FormControl sx={{ mb: 3, width: "100%" }}>
      <Stack alignItems={getPositionStyle()}>
        <Stack flexDirection="row" gap={0.3}>
          <Typography variant="h4" sx={{ mb: 3 }}>
            請選擇圖片
          </Typography>
          {tooltip && (
            <Tooltip title={tooltip} placement="top-start">
              <ErrorOutlineIcon
                sx={{
                  fontSize: 16,
                  color: "#c0c0c0",
                }}
              />
            </Tooltip>
          )}
        </Stack>
        {(status === "SUCCESS" || value) && (
          <Stack direction="row" alignItems="center" gap={2}>
            <img src={path} alt="uploadImage" width={200} height={200} />
            <JumboIconButton onClick={handleDeleteFile}>
              <DeleteIcon />
            </JumboIconButton>
          </Stack>
        )}

        {(status !== "SUCCESS" || !value) && (
          <Button
            disabled={status === "UPLOADING" || status === "SUCCESS"}
            component="label"
            variant="contained"
            startIcon={statusDict[status].BtnIcon}
            color={statusDict[status].color}
            sx={{
              position: "relative",
            }}
          >
            {statusDict[status].BtnText}
            {(status === "INIT" || status === "FAIL") && (
              <VisuallyHiddenInput
                ref={fileInputRef}
                multiple={multiple}
                type="file"
                onChange={handleFileChange}
              />
            )}
          </Button>
        )}
      </Stack>
      {error && <FormHelperText error={error}>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default ImageSection;

ImageSection.propTypes = {
  position: PropTypes.oneOf(["left", "center", "right"]),
  multiple: PropTypes.bool,
  onFinish: PropTypes.func.isRequired,
  showPath: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  title: PropTypes.string,
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.array,
  supportType: PropTypes.array,
};
