import Immutable from "immutable";

import {
  CNG_CATEGORY_POSITION_DONE,
  CNG_SUB_CATEGORY_POSITION_DONE,
  CREATE_CATEGORY_DONE,
  CREATE_SUB_CATEGORY_DONE,
  DELETE_CATEGORY_DONE,
  DELETE_SUB_CATEGORY_DONE,
  FCH_CATEGORY_LIST_DONE,
  FCH_SUB_LIST_DONE,
  UPDATE_CATEGORY_DONE,
  UPDATE_SUB_CATEGORY_DONE,
} from "../types/menu";

const INIT_STATE = Immutable.OrderedMap({
  results: Immutable.OrderedMap({
    categories: null,
  }),
});

const menuReducer = (_ = INIT_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case FCH_CATEGORY_LIST_DONE: {
      const doc = Immutable.fromJS(payload.doc);
      return _.setIn(["results", "categories"], doc);
    }
    case FCH_SUB_LIST_DONE: {
      const doc = Immutable.fromJS(payload.doc);
      return _.updateIn(["results", "categories"], (categories) =>
        categories.map((category) => {
          const categoryId = category.get("id");
          const subList = doc.filter(
            (item) => item.get("category_id") === categoryId,
          );

          console.log(subList);
          return category.set("sub", subList);
        }),
      );
    }
    case CREATE_CATEGORY_DONE: {
      const doc = Immutable.fromJS(payload.doc);
      return _.updateIn(["results", "categories"], (categories) =>
        categories.push(doc),
      );
    }
    case UPDATE_CATEGORY_DONE: {
      const doc = Immutable.fromJS(payload.doc);
      return _.updateIn(["results", "categories"], (categories) =>
        categories.map((category) =>
          category.get("id") === doc.get("id")
            ? category.merge({ name: doc.get("name") })
            : category,
        ),
      );
    }
    case CNG_CATEGORY_POSITION_DONE: {
      const doc = Immutable.fromJS(payload.doc);
      return _.updateIn(["results", "categories"], (categories) => {
        const updatedCategories = categories.filter(
          (category) => !doc.some((d) => d.get("id") === category.get("id")),
        );
        return doc.reduce((acc, updatedCategory) => {
          const index = updatedCategory.get("sn") - 1;
          return acc.splice(index, 0, updatedCategory);
        }, updatedCategories);
      });
    }
    case DELETE_CATEGORY_DONE: {
      const id = payload.id;
      return _.updateIn(["results", "categories"], (categories) =>
        categories.filter((category) => category.get("id") !== id),
      );
    }
    case CREATE_SUB_CATEGORY_DONE: {
      const doc = Immutable.fromJS(payload.doc);
      return _.updateIn(["results", "categories"], (categories) =>
        categories.map((category) =>
          category.get("id") === doc.get("category_id")
            ? category.set("menus", category.get("menus").push(doc))
            : category,
        ),
      );
    }
    case UPDATE_SUB_CATEGORY_DONE: {
      const doc = Immutable.fromJS(payload.doc);

      return _.updateIn(["results", "categories"], (categories) =>
        categories.map((category) =>
          category.updateIn(["menus"], (menus) =>
            menus.map((menu) =>
              menu.get("id") === doc.get("id")
                ? menu.merge({ name: doc.get("name"), url: doc.get("url") })
                : menu,
            ),
          ),
        ),
      );
    }
    case CNG_SUB_CATEGORY_POSITION_DONE: {
      const { id, doc } = payload;
      const updatedDoc = Immutable.fromJS(doc);
      return _.updateIn(["results", "categories"], (categories) =>
        categories.map((category) =>
          category.get("id") === id
            ? category.update("menus", (menus) => {
                const updatedMenus = menus.filter(
                  (menu) =>
                    !updatedDoc.some((d) => d.get("id") === menu.get("id")),
                );
                return updatedDoc.reduce((acc, updatedMenu) => {
                  const index = updatedMenu.get("sn") - 1;
                  return acc.splice(index, 0, updatedMenu);
                }, updatedMenus);
              })
            : category,
        ),
      );
    }
    case DELETE_SUB_CATEGORY_DONE: {
      const id = payload.id;
      return _.updateIn(["results", "categories"], (categories) =>
        categories.map((category) =>
          category.updateIn(["menus"], (menus) =>
            menus.filter((menu) => menu.get("id") !== id),
          ),
        ),
      );
    }
    default:
      return _;
  }
};

export default menuReducer;
