import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

const RadioSection = ({
  title,
  name,
  width,
  value,
  onChange,
  options,
  error,
  helperText,
}) => {
  if (!options) return null;

  return (
    <Stack sx={{ width: width || "100%", mb: 3 }}>
      <Typography variant="h4" sx={{ mb: 2 }}>
        {title}
      </Typography>
      <FormControl fullWidth>
        <RadioGroup row name={name} value={value} onChange={onChange}>
          {options.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              control={<Radio size="medium" />}
              disabled={option.disabled}
              label={
                <Stack
                  flexDirection="row"
                  gap={option.tooltip && 0.3}
                  alignItems="center"
                >
                  <Typography>{option.label}</Typography>
                  {option.tooltip && (
                    <>
                      <Tooltip title={option.tooltip} placement="top-start">
                        <ErrorOutlineIcon
                          sx={{
                            fontSize: 16,
                            color: "#c0c0c0",
                          }}
                        />
                      </Tooltip>
                    </>
                  )}
                </Stack>
              }
            />
          ))}
        </RadioGroup>
        <FormHelperText error={error}>{helperText}</FormHelperText>
      </FormControl>
    </Stack>
  );
};

export default React.memo(RadioSection);

RadioSection.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      tooltip: PropTypes.string,
      disabled: PropTypes.bool,
    }),
  ).isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.string,
};
