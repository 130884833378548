import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Stack, TextField, Tooltip, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

const TextAreaSection = ({
  title,
  name,
  rows = 4,
  value,
  onChange,
  error,
  helperText,
  placeholder,
  width,
  tooltip,
}) => {
  return (
    <Stack sx={{ width: { md: width || 700, sx: "100%" }, mb: 3 }}>
      <Stack flexDirection="row" gap={0.3}>
        <Typography variant="h4" sx={{ mb: 2 }}>
          {title}
        </Typography>
        {tooltip && (
          <Tooltip title={tooltip} placement="top-start">
            <ErrorOutlineIcon
              sx={{
                fontSize: 16,
                color: "#c0c0c0",
              }}
            />
          </Tooltip>
        )}
      </Stack>
      <TextField
        multiline
        rows={rows}
        name={name}
        placeholder={placeholder || `請輸入${title}`}
        value={value}
        onChange={onChange}
        error={error}
        helperText={helperText}
      />
    </Stack>
  );
};

export default React.memo(TextAreaSection);

TextAreaSection.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  rows: PropTypes.number,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  placeholder: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tooltip: PropTypes.string,
};
