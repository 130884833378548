import Div from "@jumbo/shared/Div";
import { Tab, Tabs } from "@mui/material";
import Immutable from "immutable";
import PropTypes from "prop-types";
import React, { useState } from "react";

import RetrievalFile from "./RetrivalFile";
import TextToSQL from "./TextToSQL";

const RetrievalTab = ({ mode, sources, selectedSources }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Div>
        <Div>
          <Tabs value={value} onChange={handleChange} sx={{ mb: 2 }}>
            <Tab label="檔案檢索" />
            {mode === "text_to_sql" && <Tab label="智慧資料分析" />}
          </Tabs>
        </Div>
        <TabPanel value={value} index={0}>
          <RetrievalFile sources={sources} selectedSources={selectedSources} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <TextToSQL />
        </TabPanel>
      </Div>
    </>
  );
};

export default RetrievalTab;

RetrievalTab.propTypes = {
  mode: PropTypes.string,
  sources: PropTypes.instanceOf(Immutable.List),
  selectedSources: PropTypes.instanceOf(Immutable.List),
};

const TabPanel = ({ children, value, index }) => {
  return (
    <Div hidden={value !== index}>
      {value === index && <Div>{children}</Div>}
    </Div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  value: PropTypes.number,
  index: PropTypes.number,
};
