import AdminKnowledgebasePage from "app/pages/admin/knowledgebase";
import AdminEditKnowledgebase from "app/pages/admin/knowledgebase/edit";
import React from "react";

const adminKnowledgebaseRoutes = [
  {
    path: "/admin/knowledgebase",
    element: <AdminKnowledgebasePage />,
  },
  {
    path: "/admin/knowledgebase/:id",
    element: <AdminEditKnowledgebase />,
  },
];

export default adminKnowledgebaseRoutes;
