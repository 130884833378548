import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  FormControl,
  InputLabel,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/material";
import { executePlugin, getPlugin } from "app/redux/actions/plugin";
import Loading from "app/shared/Loading";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import * as yup from "yup";

const PluginExecutePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const plugin = useSelector(({ plugin }) => plugin.getIn(["results", "info"]));
  const [initialValues, setInitialValues] = useState({});
  const [validationSchema, setValidationSchema] = useState({});
  const user = useSelector(({ app }) => app.get("user"));

  useEffect(() => {
    dispatch(getPlugin(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (plugin) {
      const newInitialValues = {};
      const newValidationSchema = {};
      plugin.inputs.forEach((input) => {
        const inputKey = input.id;
        newInitialValues[inputKey] = input.default || "";
        newValidationSchema[inputKey] =
          input.type === "int"
            ? yup.number().required(`${input.label} is required`)
            : yup.string().required(`${input.label} is required`);
      });
      setInitialValues(newInitialValues);
      setValidationSchema(yup.object(newValidationSchema));
    }
  }, [plugin]);

  const handleSubmit = async (values) => {
    const settings = {};
    Object.keys(values).forEach((id) => {
      plugin.inputs
        .find((input) => input.id === id)
        ?.actions?.forEach((action) => {
          if (settings[action.action] === undefined)
            settings[action.action] = {};
          settings[action.action][action.key] = values[id];
        });
    });
    const success = await dispatch(executePlugin(id, user.id, settings));
    if (success) navigate("/task");
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  if (!plugin) return <Loading />;

  return (
    <Card sx={{ p: 4 }}>
      <Typography variant="h3">{plugin.name}</Typography>

      <Divider sx={{ my: 4 }} />

      <CardContent sx={{ p: 0 }}>
        <form onSubmit={formik.handleSubmit}>
          {plugin.inputs.map((input, index) => {
            const inputKey = input.id;
            return (
              <FormControl key={index} fullWidth sx={{ mb: 2 }}>
                <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                  <InputLabel
                    htmlFor={inputKey}
                    shrink
                    sx={{ position: "relative", transform: "none" }}
                  >
                    {input.label}
                  </InputLabel>
                  <Tooltip title={input.description} placement="right">
                    <HelpOutlineIcon
                      fontSize="small"
                      sx={{ ml: 1, color: "text.secondary", cursor: "pointer" }}
                    />
                  </Tooltip>
                </Box>
                <TextField
                  fullWidth
                  id={inputKey}
                  name={inputKey}
                  type={input.type === "int" ? "number" : "text"}
                  value={formik.values[inputKey]}
                  onChange={formik.handleChange}
                  error={
                    formik.touched[inputKey] && Boolean(formik.errors[inputKey])
                  }
                  helperText={
                    formik.touched[inputKey] && formik.errors[inputKey]
                  }
                />
              </FormControl>
            );
          })}
          <Button type="submit" variant="contained" color="primary">
            Execute
          </Button>
        </form>
      </CardContent>
    </Card>
  );
};

export default PluginExecutePage;
