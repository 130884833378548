import Div from "@jumbo/shared/Div";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { infoAlert, successAlert } from "app/redux/actions/alert";
import Immutable from "immutable";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import SQLChart from "../Chart/SQLChart";
import TextToSqlDataTable from "../DataTable/TextToSqlDataTable";

const TextToSQL = () => {
  const dispatch = useDispatch();

  const chartRef = useRef(null);

  const currentAssistantIndex = useSelector(({ conversation }) =>
    conversation.getIn(["config", "logIndex"]),
  );

  const dataSource = useSelector(({ conversation }) =>
    conversation.getIn(["results", "logs"]).get(currentAssistantIndex),
  );

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleDownloadChart = () => {
    dispatch(infoAlert("檔案處理中"));
    const link = document.createElement("a");
    link.download = "chart.png";
    link.href = chartRef.current.canvas.toDataURL("image/png");
    link.click();
    link.remove();
    dispatch(successAlert("下載成功"));
  };

  const handleDownloadTable = () => {
    dispatch(infoAlert("檔案處理中"));
    const data = dataSource.getIn(["sql", "result"]);
    const csvContent =
      "data:text/csv;charset=utf-8," +
      data.map((row) => row.join(",")).join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "table_data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    dispatch(successAlert("下載成功"));
  };

  const copyCommand = () => {
    navigator.clipboard.writeText(dataSource.getIn(["sql", "command"]));
  };

  return (
    <>
      <Card sx={{ px: 3, mb: 3 }}>
        <CardHeader
          title="SQL"
          sx={{ px: 0 }}
          action={
            <IconButton sx={{ p: 0 }} onClick={copyCommand}>
              <CopyAllIcon />
            </IconButton>
          }
        />
        <Divider />
        <CardContent sx={{ px: 0 }}>
          {Immutable.hasIn(dataSource, ["sql", "command"]) && (
            <Typography variant="body1">
              {dataSource.getIn(["sql", "command"])}
            </Typography>
          )}
          {!Immutable.hasIn(dataSource, ["sql", "command"]) && (
            <Typography variant="body1">暫無SQL指令</Typography>
          )}
        </CardContent>
      </Card>
      <Card sx={{ px: 3 }}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label="表格" />
          <Tab label="圖表" />
        </Tabs>
        <Divider />
        <CardContent sx={{ px: 0 }}>
          <TabPanel value={value} index={0}>
            <TextToSqlDataTable
              handleDownloadTable={handleDownloadTable}
              dataSource={dataSource}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <SQLChart
              chartRef={chartRef}
              dataSource={dataSource}
              handleDownloadChart={handleDownloadChart}
            />
          </TabPanel>
        </CardContent>
      </Card>
    </>
  );
};

export default TextToSQL;

const TabPanel = ({ children, value, index }) => {
  return (
    <Div hidden={value !== index}>
      {value === index && <Div>{children}</Div>}
    </Div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  value: PropTypes.number,
  index: PropTypes.number,
};
