import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Stack, TextField, Tooltip, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

const InputSection = ({
  title,
  tooltip,
  name,
  placeholder,
  value,
  onChange,
  error,
  helperText,
  width,
  multiline,
}) => {
  return (
    <Stack sx={{ width: { md: width || 400, sx: "100%" }, mb: 3 }}>
      <Stack flexDirection="row" gap={0.3}>
        <Typography variant="h4" sx={{ mb: 2 }}>
          {title}
        </Typography>
        {tooltip && (
          <Tooltip title={tooltip} placement="top-start">
            <ErrorOutlineIcon
              sx={{
                fontSize: 16,
                color: "#c0c0c0",
              }}
            />
          </Tooltip>
        )}
      </Stack>

      <TextField
        multiline={multiline}
        rows={multiline ? 4 : null}
        name={name}
        placeholder={placeholder || null}
        value={value}
        onChange={onChange}
        error={error || null}
        helperText={helperText || null}
      />
    </Stack>
  );
};

export default React.memo(InputSection);

InputSection.propTypes = {
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  multiline: PropTypes.bool,
};
