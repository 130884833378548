import http from "app/services";
import moment from "moment";

import {
  ADD_ALL_MODEL_RULES,
  ADD_ALL_MODEL_RULES_DONE,
  ADD_MODEL_RULES,
  ADD_MODEL_RULES_DONE,
  CREATE_MODEL,
  CREATE_MODEL_DONE,
  DELETE_ALL_MODEL_RULES,
  DELETE_ALL_MODEL_RULES_DONE,
  DELETE_MODEL,
  DELETE_MODEL_DONE,
  DELETE_MODEL_RULES,
  DELETE_MODEL_RULES_DONE,
  FCH_MODEL,
  FCH_MODEL_DONE,
  FCH_MODELS,
  FCH_MODELS_DONE,
  UPDATE_MODEL,
  UPDATE_MODEL_DONE,
  UPDATE_MODEL_RULES,
  UPDATE_MODEL_RULES_DONE,
} from "../types/model";
import { statusHandler, successAlert } from "./alert";

export const fchModels = () => {
  return async (dispatch) => {
    dispatch({ type: FCH_MODELS });
    const res = await http.get("/api/v1/models");
    if (res.response && res.response.status >= 400) {
      dispatch(statusHandler(res.response.status));
      return;
    }
    dispatch({ type: FCH_MODELS_DONE, payload: { doc: res.data } });
  };
};

export const fchModel = (id) => {
  return async (dispatch) => {
    dispatch({ type: FCH_MODEL });
    const res = await http.get(`/api/v1/models/${id}`);
    if (res.response && res.response.status >= 400) {
      dispatch(statusHandler(res.response.status));
      return { success: false, code: res.response.status };
    }
    dispatch({ type: FCH_MODEL_DONE, payload: { doc: res.data } });
    return { success: true, data: res.data };
  };
};

export const createModel = (data) => {
  return async (dispatch) => {
    dispatch({ type: CREATE_MODEL });
    const res = await http.post("/api/v1/models", data);
    if (res.response && res.response.status >= 400) {
      dispatch(statusHandler(res.response.status));
      return;
    }
    const doc = {
      ...data,
      created_at: moment().unix(),
      id: res.data.payload.id,
    };
    dispatch({ type: CREATE_MODEL_DONE, payload: { doc } });
    dispatch(successAlert("建立成功"));
  };
};

export const updateModel = (id, data) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_MODEL });
    const res = await http.patch(`/api/v1/models/${id}`, data);
    if (res.response && res.response.status >= 400) {
      dispatch(statusHandler(res.response.status));
      return;
    }
    dispatch({ type: UPDATE_MODEL_DONE, payload: { id, doc: data } });
    dispatch(successAlert("更新成功"));
  };
};

export const deleteModel = (id) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_MODEL });
    const res = await http.delete(`/api/v1/models/${id}`);
    if (res.response && res.response.status >= 400) {
      dispatch(statusHandler(res.response.status));
      return;
    }
    dispatch({ type: DELETE_MODEL_DONE, payload: { id } });
    dispatch(successAlert("刪除成功"));
  };
};

export const addModelRules = (mId, formData) => {
  return async (dispatch) => {
    dispatch({ type: ADD_MODEL_RULES });
    const data = {
      user_id: formData.id,
      r: false,
      email: formData.email,
    };
    const res = await http.post(`/api/v1/models/${mId}/rules`, data);
    if (res.response && res.response.status >= 400) {
      dispatch(statusHandler(res.response.status));
      return;
    }
    const doc = {
      ...data,
      model_id: mId,
      id: res.data.payload.id,
    };
    dispatch({ type: ADD_MODEL_RULES_DONE, payload: { doc } });
    dispatch(successAlert("建立成功"));
  };
};

export const addAllModelRules = (id, formData) => {
  return async (dispatch) => {
    dispatch({ type: ADD_ALL_MODEL_RULES });
    const data = formData.map((form) => ({
      user_id: form.get("id"),
      r: false,
      email: form.get("email"),
    }));

    const doc = [];

    for (const d of data) {
      try {
        const res = await http.post(`/api/v1/models/${id}/rules`, d);
        const resDoc = {
          ...d,
          id: res.data.payload.id,
          model_id: id,
        };
        doc.push(resDoc);
      } catch (error) {
        if (error.response && error.response.status >= 400) {
          dispatch(statusHandler(error.response.status));
          return;
        }
      }
    }

    dispatch({ type: ADD_ALL_MODEL_RULES_DONE, payload: { doc } });
    dispatch(successAlert("全部建立成功"));
  };
};

export const updateModelRules = (mId, rId, data) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_MODEL_RULES });
    const res = await http.patch(`/api/v1/models/${mId}/rules/${rId}`, data);
    if (res.response && res.response.status >= 400) {
      dispatch(statusHandler(res.response.status));
      return;
    }
    dispatch({
      type: UPDATE_MODEL_RULES_DONE,
      payload: { id: rId, doc: data },
    });
    dispatch(successAlert("更新成功"));
  };
};

export const deleteModelRules = (mId, rId) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_MODEL_RULES });
    const res = await http.delete(`/api/v1/models/${mId}/rules/${rId}`);
    if (res.response && res.response.status >= 400) {
      dispatch(statusHandler(res.response.status));
      return;
    }
    dispatch({ type: DELETE_MODEL_RULES_DONE, payload: { id: rId } });
    dispatch(successAlert("刪除成功"));
  };
};

export const deleteAllModelRules = (modelId) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_ALL_MODEL_RULES });
    const res = await http.delete(`/api/v1/models/${modelId}/rules/_all`);
    if (res.response && res.response.status >= 400) {
      dispatch(statusHandler(res.response.status));
      return;
    }
    dispatch({ type: DELETE_ALL_MODEL_RULES_DONE, payload: { id: modelId } });
    dispatch(successAlert("全部刪除成功"));
  };
};
