import AdminUserPage from "app/pages/admin/user";
import React from "react";

const adminUserRoutes = [
  {
    path: "/admin/user",
    element: <AdminUserPage />,
  },
];

export default adminUserRoutes;
