import { useJumboLayoutSidebar } from "@jumbo/hooks";
import { SIDEBAR_STYLES } from "@jumbo/utils/constants";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import { Button, IconButton } from "@mui/material";
import Stack from "@mui/material/Stack";
import PromptMenu from "app/shared/PromptMenu";
// import MenuSelect from "app/shared/Form/MenuSelect";
import React from "react";
import { useNavigate } from "react-router";

import NotificationsDropdown from "../../../../shared/NotificationsDropdown";

const Header = () => {
  const navigate = useNavigate();
  const { sidebarOptions, setSidebarOptions } = useJumboLayoutSidebar();

  return (
    <React.Fragment>
      {(sidebarOptions.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER ||
        (sidebarOptions.style !== SIDEBAR_STYLES.CLIPPED_UNDER_HEADER &&
          !sidebarOptions?.open)) && (
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          sx={{
            ml:
              sidebarOptions.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER
                ? -2
                : 0,
            mr: 3,
          }}
          onClick={() => setSidebarOptions({ open: !sidebarOptions.open })}
        >
          {sidebarOptions?.open ? (
            <MenuOpenIcon />
          ) : (
            <MenuOpenIcon sx={{ transform: "rotate(180deg)" }} />
          )}
        </IconButton>
      )}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={1.25}
        width="100%"
      >
        {/* {showDropdownSearch && (
          <JumboIconButton
            elevation={25}
            onClick={() => setDropdownSearchVisibility(true)}
          >
            <SearchIcon fontSize={"small"} />
          </JumboIconButton>
        )} */}
        {/* {!showDropdownSearch && (
          <SearchGlobal
            sx={{
              maxWidth: { xs: 240, md: 320 },
            }}
          />
        )} */}
        {/* <MenuSelect /> */}
        <PromptMenu />
        <Button
          variant="outlined"
          color="primary"
          onClick={() => navigate("/task")}
        >
          任務列表
        </Button>
        <NotificationsDropdown />
      </Stack>
    </React.Fragment>
  );
};

export default Header;
