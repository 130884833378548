import { useJumboApp } from "@jumbo/hooks";
import Div from "@jumbo/shared/Div";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Card,
  CardContent,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { LAYOUT_NAMES } from "app/layouts/layouts";
import { userLogin } from "app/redux/actions/auth";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setActiveLayout } = useJumboApp();

  const [isShow, setIsShow] = useState(false);

  const handleSubmit = (values) => {
    dispatch(userLogin(values));
  };

  const loginValidation = () => {
    const storage = localStorage.getItem("ragi");
    if (!storage) return;
    const existingData = JSON.parse(storage);
    const loginData = JSON.parse(atob(existingData.token.split(".")[1]));
    const expireTime = loginData.exp;
    if (expireTime < Date.now() / 1000 && !storage) return;

    navigate("/");
  };

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = yup.object({
    email: yup
      .string("請輸入電子信箱")
      .email("請輸入正確的電子信箱格式")
      .required("電子信箱為必填欄位"),
    password: yup.string("請輸入密碼").required("密碼為必填欄位"),
  });

  const formik = useFormik({
    validateOnChange: true,
    validateOnBlur: true,
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  React.useEffect(() => {
    setActiveLayout(LAYOUT_NAMES.SOLO_PAGE);
  }, []);

  React.useEffect(() => {
    loginValidation();
  }, []);

  return (
    <Div
      sx={{
        flex: 1,
        flexWrap: "wrap",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        p: (theme) => theme.spacing(4),
      }}
    >
      <img
        src="/logo.png"
        alt="nova rag"
        style={{ width: 360, marginBottom: 8 }}
      />
      <Card sx={{ maxWidth: "100%", width: 480, mb: 4 }}>
        <Div sx={{ p: 4, pb: 0 }}>
          <Typography variant="h3" textAlign="center">
            登入
          </Typography>
        </Div>

        <CardContent>
          <form onSubmit={formik.handleSubmit}>
            <Div sx={{ mb: 3, mt: 1 }}>
              <TextField
                fullWidth
                name="email"
                label="Email"
                onChange={formik.handleChange}
                value={formik.values.email}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Div>
            <Div sx={{ mb: 3, mt: 1 }}>
              <TextField
                fullWidth
                name="password"
                label="Password"
                type={isShow ? "text" : "password"}
                onChange={formik.handleChange}
                value={formik.values.password}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
                InputProps={{
                  endAdornment: isShow ? (
                    <IconButton
                      color="primary"
                      onClick={() => setIsShow(false)}
                    >
                      <VisibilityOffIcon />
                    </IconButton>
                  ) : (
                    <IconButton color="primary" onClick={() => setIsShow(true)}>
                      <RemoveRedEyeIcon />
                    </IconButton>
                  ),
                }}
              />
            </Div>

            <LoadingButton
              fullWidth
              type="submit"
              variant="contained"
              size="large"
              sx={{ mb: 3 }}
            >
              Login
            </LoadingButton>
          </form>
        </CardContent>
      </Card>
    </Div>
  );
}

export default Login;
