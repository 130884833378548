import http from "app/services";

import { UPD_FILE, UPD_FILE_DONE } from "../types/files";
import { statusHandler } from "./alert";

export const uploadFile = (files) => async (dispatch) => {
  dispatch({ type: UPD_FILE });

  const formData = new FormData();
  Array.from(files).forEach((file) => formData.append("files", file));

  try {
    const res = await http.post("/files?namespace=files", formData);

    if (!res.data.success) {
      return false;
    }

    dispatch({ type: UPD_FILE_DONE });
    return res.data.payload;
  } catch (error) {
    dispatch(statusHandler(error.response?.status || 500));
    return false;
  }
};
