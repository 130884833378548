import Immutable from "immutable";

import {
  ADD_ALL_MODEL_RULES_DONE,
  ADD_MODEL_RULES_DONE,
  CREATE_MODEL_DONE,
  DELETE_ALL_MODEL_RULES_DONE,
  DELETE_MODEL_DONE,
  DELETE_MODEL_RULES_DONE,
  FCH_MODEL_DONE,
  FCH_MODELS_DONE,
  UPDATE_MODEL_DONE,
  UPDATE_MODEL_RULES_DONE,
} from "../types/model";

const INIT_STATE = Immutable.OrderedMap({
  results: {
    models: null,
    info: null,
  },
});

const modelReducer = (_ = INIT_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case FCH_MODELS_DONE: {
      const data = Immutable.fromJS(payload.doc).sort((a, b) => {
        const aIsChinese = /[\u4e00-\u9fff]/.test(a.get("name"));
        const bIsChinese = /[\u4e00-\u9fff]/.test(b.get("name"));

        if (aIsChinese && !bIsChinese) return 1;
        if (!aIsChinese && bIsChinese) return -1;
        return a.get("name").localeCompare(b.get("name"));
      });
      return _.setIn(["results", "models"], data);
    }
    case FCH_MODEL_DONE: {
      const data = Immutable.fromJS(payload.doc);
      return _.setIn(["results", "info"], data);
    }
    case CREATE_MODEL_DONE: {
      const data = Immutable.fromJS(payload.doc);
      const newData = _.getIn(["results", "models"])
        .push(data)
        .sort((a, b) => {
          const aIsChinese = /[\u4e00-\u9fff]/.test(a.get("name"));
          const bIsChinese = /[\u4e00-\u9fff]/.test(b.get("name"));

          if (aIsChinese && !bIsChinese) return 1;
          if (!aIsChinese && bIsChinese) return -1;
          return a.get("name").localeCompare(b.get("name"));
        });
      return _.updateIn(["results", "models"], () => newData);
    }
    case UPDATE_MODEL_DONE: {
      const data = Immutable.fromJS(payload.doc);
      return _.updateIn(["results", "models"], (models) =>
        models.map((model) =>
          model.get("id") === payload.id ? model.mergeDeep(data) : model,
        ),
      ).updateIn(["results", "info"], (info) =>
        info ? info.mergeDeep(data) : data,
      );
    }
    case ADD_MODEL_RULES_DONE: {
      const data = Immutable.fromJS(payload.doc);
      return _.updateIn(["results", "info", "rules"], (rules) =>
        rules.push(data),
      );
    }
    case ADD_ALL_MODEL_RULES_DONE: {
      const data = Immutable.fromJS(payload.doc);
      return _.updateIn(["results", "info", "rules"], (rules) =>
        rules.concat(data),
      );
    }
    case DELETE_MODEL_RULES_DONE: {
      const id = payload.id;
      return _.updateIn(["results", "info", "rules"], (rules) =>
        rules.filter((rule) => rule.get("id") !== id),
      );
    }
    case DELETE_ALL_MODEL_RULES_DONE: {
      return _.updateIn(["results", "info"], (info) =>
        info.set("rules", Immutable.List()),
      );
    }
    case UPDATE_MODEL_RULES_DONE: {
      console.log(payload.doc);
      const data = Immutable.fromJS(payload.doc);
      return _.updateIn(["results", "info", "rules"], (rules) =>
        rules.map((rule) =>
          rule.get("id") === payload.id ? rule.mergeDeep(data) : rule,
        ),
      );
    }
    case DELETE_MODEL_DONE: {
      const id = payload.id;
      return _.updateIn(["results", "models"], (models) =>
        models.filter((model) => model.get("id") !== id),
      );
    }
    default:
      return _;
  }
};

export default modelReducer;
