import JumboIconButton from "@jumbo/components/JumboIconButton";
import Div from "@jumbo/shared/Div";
import BarChartIcon from "@mui/icons-material/BarChart";
import DownloadIcon from "@mui/icons-material/Download";
import { Stack, Typography } from "@mui/material";
import { Chart, registerables } from "chart.js";
import { Map } from "immutable";
import PropTypes from "prop-types";
import React from "react";

import BarChart from "./BarChart";
import LineChart from "./LineChart";

Chart.register(...registerables);

const SQLChart = ({ dataSource, chartRef, handleDownloadChart }) => {
  if (!dataSource || dataSource.get("chartjs").size === 0) {
    return (
      <Stack
        alignItems="center"
        justifyContent="center"
        height="100%"
        spacing={1}
      >
        <BarChartIcon sx={{ color: "#ccc", fontSize: 36 }} />
        <Typography variant="body1" color="#ccc">
          暫無統計圖數據
        </Typography>
      </Stack>
    );
  }

  return (
    <>
      <Div sx={{ display: "flex", justifyContent: "flex-end" }}>
        <JumboIconButton sx={{ p: 0 }} onClick={handleDownloadChart}>
          <DownloadIcon />
        </JumboIconButton>
      </Div>
      {dataSource.getIn(["chartjs", "type"]) === "bar" && (
        <BarChart
          chartRef={chartRef}
          data={dataSource.getIn(["chartjs", "data"]).toJS()}
        />
      )}
      {dataSource.getIn(["chartjs", "type"]) === "line" && (
        <LineChart
          data={dataSource.getIn(["chartjs", "data"]).toJS()}
          chartRef={chartRef}
        />
      )}
    </>
  );
};

export default SQLChart;

SQLChart.propTypes = {
  dataSource: PropTypes.instanceOf(Map),
  chartRef: PropTypes.object,
  handleDownloadChart: PropTypes.func,
};
