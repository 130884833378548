export const FCH_NOTIFICATIONS = "NOTIFICATION/FCH_LIST";
export const FCH_NOTIFICATIONS_DONE = "NOTIFICATION/FCH_LIST_DONE";

export const READ_NOTIFICATION = "NOTIFICATION/READ";
export const READ_NOTIFICATION_DONE = "NOTIFICATION/READ_DONE";

export const READ_ALL_NOTIFICATIONS = "NOTIFICATION/READ_ALL";
export const READ_ALL_NOTIFICATIONS_DONE = "NOTIFICATION/READ_ALL_DONE";

export const DEL_ALL_NOTIFICATIONS = "NOTIFICATION/DELETE_ALL";
export const DEL_ALL_NOTIFICATIONS_DONE = "NOTIFICATION/DELETE_ALL_DONE";
