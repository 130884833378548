import { Renderer } from "marked";
import React from "react";
import { renderToString } from "react-dom/server";

import CodeBlock from "./Code";

const renderer = new Renderer();

renderer.code = (code, language) => {
  return renderToString(<CodeBlock language={language} value={code} />);
};

export default renderer;
