import Div from "@jumbo/shared/Div";
import { Grid } from "@mui/material";
import {
  clearConversation,
  fchConversation,
} from "app/redux/actions/conversation";
import ChatRoom from "app/shared/ChatRoom";
import Loading from "app/shared/Loading";
import RetrievalTab from "app/shared/RetrievalTab";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const ConversationPage = () => {
  const { cId } = useParams();
  const dispatch = useDispatch();

  const conversationInfo = useSelector(({ conversation }) =>
    conversation.getIn(["results", "info"]),
  );

  const logs = useSelector(({ conversation }) =>
    conversation.getIn(["results", "logs"]),
  );

  const knowledgebase = useSelector(({ knowledgebase }) =>
    knowledgebase.getIn(["results", "info"]),
  );

  const mode = useSelector(({ conversation }) =>
    conversation.getIn(["results", "info", "metadata", "conversation_mode"]),
  );

  const sources = useSelector(({ conversation }) =>
    conversation.get("sources"),
  );

  const selectedSources = useSelector(({ conversation }) =>
    conversation.getIn(["config", "selectedSources"]),
  );

  const user = useSelector(({ app }) => app.get("user"));

  const fchData = async (id) => {
    dispatch(fchConversation(id));
  };

  React.useEffect(() => {
    if (!cId) return;
    fchData(cId);
    return () => {
      dispatch(clearConversation());
    };
  }, [cId]);

  const isStreamMode = useMemo(() => {
    return (
      conversationInfo &&
      conversationInfo.getIn(["metadata", "output_mode"]) === "streaming"
    );
  }, [conversationInfo]);

  const chatRoomProps = useMemo(
    () => ({
      user,
      title: conversationInfo && conversationInfo.get("name"),
      conversationInfo,
      conversationId: cId,
      sources,
      selectedSources,
      logs,
      isStreamMode,
      modalBadge:
        conversationInfo &&
        conversationInfo.getIn(["metadata", "language_model"]),
      model:
        conversationInfo &&
        conversationInfo.getIn(["metadata", "language_model"]),
      knowledgebase,
      mode,
    }),
    [
      user,
      conversationInfo,
      cId,
      selectedSources,
      logs,
      isStreamMode,
      knowledgebase,
      mode,
    ],
  );

  if (!logs || !conversationInfo) return <Loading />;

  return (
    <Div>
      <Grid container spacing={3}>
        <Grid item xs={12} md={mode !== "language_model" ? 8 : 12}>
          <ChatRoom {...chatRoomProps} />
        </Grid>
        {mode !== "language_model" && (
          <Grid
            item
            md={4}
            sx={{ height: "calc(100vh - 150px)", overflow: "auto", pb: 4 }}
          >
            <RetrievalTab
              mode={mode}
              sources={sources}
              selectedSources={selectedSources}
            />
          </Grid>
        )}
      </Grid>
    </Div>
  );
};

export default React.memo(ConversationPage);
