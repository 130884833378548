import JumboIconButton from "@jumbo/components/JumboIconButton";
import Div from "@jumbo/shared/Div";
import DownloadIcon from "@mui/icons-material/Download";
import TableChartIcon from "@mui/icons-material/TableChart";
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Immutable, { List } from "immutable";
import PropTypes from "prop-types";
import React from "react";
const TextToSqlDataTable = ({ dataSource, handleDownloadTable }) => {
  const data = Immutable.hasIn(dataSource, ["sql", "result"])
    ? dataSource.getIn(["sql", "result"])
    : List();

  if (!List.isList(data) || data.size === 0) {
    return (
      <Stack
        alignItems="center"
        justifyContent="center"
        height="100%"
        spacing={1}
      >
        <TableChartIcon sx={{ color: "#ccc", fontSize: 36 }} />
        <Typography variant="body1" color="#ccc">
          暫無數據表格
        </Typography>
      </Stack>
    );
  }

  const headers = data.get(0) || List();
  const rows = data.slice(1) || List();

  return (
    <>
      <Div sx={{ display: "flex", justifyContent: "flex-end" }}>
        <JumboIconButton sx={{ p: 0, mb: 2 }} onClick={handleDownloadTable}>
          <DownloadIcon />
        </JumboIconButton>
      </Div>
      <TableContainer>
        <Table sx={{ border: "1px solid #e0e0e0" }}>
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                <TableCell key={header}>{header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={row}>
                {row.map((cell) => (
                  <TableCell
                    sx={{
                      backgroundColor:
                        index % 2 === 0 ? "#f0f0f0" : "transparent",
                    }}
                    key={cell}
                  >
                    {cell}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default TextToSqlDataTable;

TextToSqlDataTable.propTypes = {
  dataSource: PropTypes.instanceOf(Immutable.Map).isRequired,
  handleDownloadTable: PropTypes.func,
};
