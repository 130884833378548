import JumboIconButton from "@jumbo/components/JumboIconButton";
import Div from "@jumbo/shared/Div";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import CopyIcon from "@mui/icons-material/ContentCopy";
import StorageIcon from "@mui/icons-material/Storage";
import { Avatar, List, ListItem, Stack, Typography } from "@mui/material";
import DOMPurify from "dompurify";
import Immutable from "immutable";
import { marked } from "marked";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";

import renderer from "../Markdown";
import formatException from "./formatException";

const MESSAGE_HEIGHT = 450;
const RESET_MESSAGE = "對話已重置！";

const MessageBox = ({
  logs,
  type,
  isSending,
  isHasKb,
  user,
  model,
  handleCopyContent,
  handleSetIndex,
  handleGetReference,
}) => {
  const chatRoomRef = useRef(null);

  // 使用正則表達式來匹配 <XXXX> 和 `<XXXXX>`，但不匹配 ` <XXXX> ` 和 ->
  const replaceCharacters = (text) => {
    const str = text.replace(
      /(?<![-`\s])(`?)<([^`][^>]*)>(`?)(?![-`\s])/g,
      (match, backtickBefore, p1, backtickAfter) => {
        // 檢查是否是合法的 HTML 標籤或前後都有反引號
        const validHtmlTags = [
          "b",
          "i",
          "em",
          "strong",
          "a",
          "div",
          "span",
          "p",
          "br",
          "img",
          "ul",
          "ol",
          "li",
          "h1",
          "h2",
          "h3",
          "h4",
          "h5",
          "h6",
        ];
        const tagName = p1.split(" ")[0];

        if (
          (backtickBefore && backtickAfter) ||
          validHtmlTags.includes(tagName)
        ) {
          return match; // 保留合法的 HTML 標籤或被反引號包圍的內容
        }

        return `&lt;${p1}&gt;`; // 替換其他情況
      },
    );
    return str;
  };

  const formattedStr = (str) => {
    if (!str) return "";

    let newStr = str;
    if (formatException.some((exception) => model.includes(exception))) {
      newStr = replaceCharacters(str);
    }

    const rawHtml = marked(newStr, { renderer });

    return DOMPurify.sanitize(rawHtml);
  };

  const formattedUserStr = (str) => {
    if (!str) return "";

    const newStr = str
      .replace(/\n/g, "\n\n") // Replace single newline with double newline
      .replace(/<([^`][^>]*)>/g, "&lt;$1&gt;");

    const rawHtml = marked(newStr, {
      breaks: true, // Enable line breaks
      gfm: true, // Enable GitHub Flavored Markdown
    });

    return DOMPurify.sanitize(rawHtml);
  };

  useEffect(() => {
    const current = chatRoomRef.current;
    if (current) {
      current.scrollTop = current.scrollHeight;
    }
  }, [logs.size]);

  useEffect(() => {
    const codeBlock = document.querySelectorAll("#code-block");
    codeBlock.forEach((block) => {
      const copyButton = block.querySelector("#copy-button");
      const code = block.querySelector("code");
      copyButton.addEventListener("click", () => {
        console.log("copying code");
        navigator.clipboard.writeText(code.textContent);
      });
    });
  }, [logs]);

  if (!logs) return null;

  return (
    <>
      <List
        id="chatroom"
        ref={chatRoomRef}
        sx={{
          overflowY: "auto",
          height: `calc(100vh - ${MESSAGE_HEIGHT}px)`,
          position: "relative",
          // pb: suggestedQuestions.size > 0 ? 12 : 6,
        }}
      >
        {logs.map((log, index) => {
          if (log.get("role") === "assistant") {
            return (
              <ListItem key={log.get("id")}>
                <Stack flexDirection="row" gap={1}>
                  <Avatar alt="assistant" src="/system-avatar.png" />
                  <Stack alignItems="flex-start">
                    <Div
                      sx={{
                        border: "1px solid #c0c0c0",
                        position: "relative",
                        px: 1.5,
                        borderRadius: 1,
                        maxWidth: 700,
                        wordWrap: "break-word",
                      }}
                    >
                      {(!log.get("message") || isSending) && (
                        <img
                          src="/assets/loading.gif"
                          alt="loading"
                          style={{ width: 36 }}
                        />
                      )}
                      {(log.get("message") || !isSending) && (
                        <Div
                          className="test_message"
                          sx={{
                            "&>ul": {
                              mb: 1,
                            },
                            "&>p": {
                              my: 1,
                            },
                            "&>ol": {
                              mb: 1,
                            },
                            "&>pre>code": {
                              wordWrap: "break-word",
                              whiteSpace: "pre-wrap",
                            },
                          }}
                          dangerouslySetInnerHTML={{
                            __html: formattedStr(log.get("message")),
                          }}
                        />
                      )}
                    </Div>

                    <Stack
                      direction="column"
                      alignItems="flex-start"
                      gap={0.5}
                      sx={{ zIndex: 1 }}
                    >
                      <Typography variant="caption" sx={{ mt: 0.5 }}>
                        {log.get("created_at") &&
                          moment
                            .unix(log.get("created_at"))
                            .format("YYYY-MM-DD HH:mm:ss")}
                      </Typography>
                      {log.get("message") !== RESET_MESSAGE && (
                        <Stack direction="row" gap={1}>
                          <JumboIconButton
                            sx={{ p: 0 }}
                            onClick={(e) =>
                              handleCopyContent(log.get("message"), e)
                            }
                          >
                            <CopyIcon sx={{ fontSize: 16 }} />
                          </JumboIconButton>
                          {type === "text_to_sql" && (
                            <JumboIconButton
                              sx={{ p: 0 }}
                              onClick={(e) => handleSetIndex(index, e)}
                            >
                              <StorageIcon sx={{ fontSize: 18 }} />
                            </JumboIconButton>
                          )}
                          {isHasKb && (
                            <JumboIconButton
                              sx={{ p: 0 }}
                              onClick={(e) =>
                                handleGetReference(log.get("id"), e)
                              }
                            >
                              <BookmarkIcon sx={{ fontSize: 18 }} />
                            </JumboIconButton>
                          )}
                        </Stack>
                      )}
                    </Stack>
                  </Stack>
                </Stack>
              </ListItem>
            );
          }

          return (
            <ListItem key={log.get("id")} sx={{ justifyContent: "flex-end" }}>
              <Stack flexDirection="row" gap={1}>
                <Stack alignItems="flex-end">
                  <Div
                    sx={{
                      border: "1px solid #c0c0c0",
                      position: "relative",
                      px: 1.5,
                      borderRadius: 1,
                      maxWidth: 700,
                      wordWrap: "break-word",
                    }}
                  >
                    <Div
                      sx={{
                        "&>p": {
                          my: 1,
                        },
                        "&>pre>code": {
                          wordWrap: "break-word",
                          whiteSpace: "pre-wrap",
                        },
                      }}
                      dangerouslySetInnerHTML={{
                        __html: formattedUserStr(log.get("message")),
                      }}
                    />
                  </Div>
                  <Typography variant="caption" sx={{ mt: 0.5 }}>
                    {moment
                      .unix(log.get("created_at"))
                      .format("YYYY-MM-DD HH:mm:ss")}
                  </Typography>
                </Stack>
                <Avatar alt="User" sx={{ bgcolor: "#32B5C9" }}>
                  {user.username[0].toUpperCase()}
                </Avatar>
              </Stack>
            </ListItem>
          );
        })}
      </List>
    </>
  );
};

MessageBox.propTypes = {
  logs: PropTypes.instanceOf(Immutable.List).isRequired,
  type: PropTypes.string,
  isSending: PropTypes.bool,
  user: PropTypes.object.isRequired,
  model: PropTypes.string.isRequired,
  handleCopyContent: PropTypes.func.isRequired,
  handleSetIndex: PropTypes.func.isRequired,
  handleGetReference: PropTypes.func.isRequired,
  isHasKb: PropTypes.bool,
};

export default MessageBox;
