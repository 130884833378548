import { TextField } from "@mui/material";
import { useReactFlow } from "@xyflow/react";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";

import NodeWrapper from "./NodeWrapper";

const URLWriterNode = ({ id, data }) => {
  const [url, setURL] = useState(data.url || "");
  const { setNodes } = useReactFlow();

  const updateNodeData = useCallback(
    (newData) => {
      setNodes((nds) =>
        nds.map((node) => {
          if (node.id === id) {
            node.data = { ...node.data, ...newData };
          }
          return node;
        }),
      );
    },
    [id, setNodes],
  );

  const handleURLChange = (e) => {
    const newURL = e.target.value;
    setURL(newURL);
    updateNodeData({ url: newURL });
  };

  return (
    <NodeWrapper
      id={id}
      title="URL Writer Node"
      initialInputs={[{ id: "input", label: "input" }]}
      initialOutputs={[]}
      data={data}
      width={300}
      color="#E53935"
      setNodes={setNodes}
      canAddInputs={false}
      canAddOutputs={false}
      canDeleteInputs={false}
      canDeleteOutputs={false}
    >
      <TextField
        id={`url-${id}`}
        label="URL"
        value={url}
        onChange={handleURLChange}
        placeholder="Enter URL"
        fullWidth
        margin="normal"
        variant="outlined"
      />
    </NodeWrapper>
  );
};

URLWriterNode.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.shape({
    url: PropTypes.string,
  }).isRequired,
};

export default URLWriterNode;
