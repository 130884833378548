import { useJumboApp } from "@jumbo/hooks";
import Div from "@jumbo/shared/Div";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { LAYOUT_NAMES } from "app/layouts/layouts";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const FourOThreePage = () => {
  const navigate = useNavigate();
  const { setActiveLayout } = useJumboApp();

  useEffect(() => {
    setActiveLayout(LAYOUT_NAMES.SOLO_PAGE);
  }, []);

  return (
    <Div
      sx={{
        flex: 1,
        flexWrap: "wrap",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        top: "40%",
        transform: "translateY(-50%)",
        p: (theme) => theme.spacing(4),
      }}
    >
      <Div sx={{ display: "inline-flex", mb: 1 }}>
        <Typography
          variant="h1"
          color="#423883"
          component="div"
          sx={{ fontSize: 200, m: 0 }}
        >
          4
        </Typography>
        <Typography variant="h1" component="div" sx={{ fontSize: 200, m: 0 }}>
          0
        </Typography>
        <Typography
          variant="h1"
          color="#423883"
          component="div"
          sx={{ fontSize: 200, m: 0 }}
        >
          3
        </Typography>
      </Div>
      <Typography
        align={"center"}
        component={"h2"}
        variant={"h1"}
        color={"text.secondary"}
        mb={3}
      >
        您沒有訪問此頁面的權限。請確認您是否擁有適當的權限或者已經登錄。
      </Typography>

      <Button variant="contained" onClick={() => navigate("/")}>
        返回首頁
      </Button>
    </Div>
  );
};

export default FourOThreePage;
