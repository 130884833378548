import JumboIconButton from "@jumbo/components/JumboIconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  // Button,
  Card,
  Divider,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  // TextField,
  Typography,
} from "@mui/material";
import { delKnowledgebase } from "app/redux/actions/knowledgebase";
import ConfirmModal from "app/shared/Modal/ConfirmModal";
import CreateKnowledgebaseModal from "app/shared/Modal/CreateKnowledgebase";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

const AdminKnowledgebasePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isDelModalOpen, setIsDelModalOpen] = useState(false);
  const [knowledgebaseId, setKnowledgebaseId] = useState(null);
  const [knowledgebaseList, setKnowledgebaseList] = useState(null);
  // const [searchValue, setSearchValue] = useState("");

  const knowledgebases = useSelector(({ knowledgebase }) =>
    knowledgebase.getIn(["results", "knowledgebases"]),
  );

  const user_id = useSelector(({ app }) => app.getIn(["user", "id"]));
  const groupData = useSelector(({ group }) =>
    group.getIn(["results", "groups"]),
  );

  const handleDelModalOpen = (id) => {
    setKnowledgebaseId(id);
    setIsDelModalOpen(true);
  };

  const handleDelModalClose = () => {
    setKnowledgebaseId(null);
    setIsDelModalOpen(false);
  };

  const handleDeleteKnowledgebase = async () => {
    await dispatch(delKnowledgebase(knowledgebaseId, user_id));
    handleDelModalClose();
  };

  // const handleFilter = (e) => {
  //   const value = e.target.value;
  //   const filtered = knowledgebases.filter((knowledgebase) =>
  //     knowledgebase.get("name").toLowerCase().includes(value.toLowerCase()),
  //   );
  //   setKnowledgebaseList(filtered);
  //   setSearchValue(value);
  // };

  const findEmailById = (id) => {
    if (!groupData) return null;
    const group = groupData.find((group) => group.get("id") === id);
    return group ? group.get("email") : null;
  };

  useEffect(() => {
    if (!knowledgebases) return;
    setKnowledgebaseList(knowledgebases);
  }, [knowledgebases]);

  if (!knowledgebaseList) return null;

  return (
    <>
      <Card sx={{ p: 4 }}>
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h3" sx={{ m: 0 }}>
            知識庫管理
          </Typography>
          <Stack flexDirection="row" gap={2}>
            {/* #TODO: Search bar is not implemented yet */}
            {/* <TextField
              placeholder="搜尋"
              value={searchValue}
              onChange={handleFilter}
              size="small"
            /> */}
            <CreateKnowledgebaseModal uId={user_id} />
          </Stack>
        </Stack>

        <Divider sx={{ my: 4 }} />

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>知識庫名稱</TableCell>
                <TableCell>建立者</TableCell>
                <TableCell>建立日期</TableCell>
                <TableCell>設定</TableCell>
                <TableCell>刪除</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {knowledgebaseList.toJS().map((knowledgebase) => (
                <TableRow key={knowledgebase.id}>
                  <TableCell>{knowledgebase.name}</TableCell>
                  <TableCell>{findEmailById(knowledgebase.user_id)}</TableCell>
                  <TableCell>
                    {moment
                      .unix(knowledgebase.created_at)
                      .format("YYYY/MM/DD HH:mm:ss")}
                  </TableCell>
                  <TableCell>
                    <JumboIconButton
                      sx={{ position: "relative", left: -5 }}
                      onClick={() =>
                        navigate(`/admin/knowledgebase/${knowledgebase.id}`)
                      }
                    >
                      <EditIcon color="primary" />
                    </JumboIconButton>
                  </TableCell>
                  <TableCell>
                    <JumboIconButton
                      sx={{ position: "relative", left: -5 }}
                      onClick={() => handleDelModalOpen(knowledgebase.id)}
                    >
                      <DeleteIcon color="error" />
                    </JumboIconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      <ConfirmModal
        open={isDelModalOpen}
        content={
          <>
            <Typography variant="body1">確定要刪除此知識庫嗎？</Typography>
            <Typography variant="body2" color="error">
              此動作會造成所有關聯對話一併刪除。
            </Typography>
          </>
        }
        onClose={handleDelModalClose}
        onConfirm={handleDeleteKnowledgebase}
      />
    </>
  );
};

export default AdminKnowledgebasePage;
