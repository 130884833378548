import Immutable from "immutable";

import {
  DELETE_PLUGIN_DONE,
  FETCH_PLUGINS_DONE,
  GET_PLUGIN_DONE,
  TRY_PLUGIN_ACTION_DONE,
} from "../types/plugin";

const INIT_STATE = Immutable.OrderedMap({
  results: {
    plugins: null,
    info: null,
    try: null,
  },
});

const pluginReducer = (_ = INIT_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_PLUGINS_DONE: {
      return _.setIn(["results", "plugins"], payload.doc);
    }
    case GET_PLUGIN_DONE: {
      return _.setIn(["results", "info"], payload.doc);
    }
    case DELETE_PLUGIN_DONE: {
      const plugins = _.getIn(["results", "plugins"]);
      return _.setIn(
        ["results", "plugins"],
        plugins.filter((plugin) => plugin.id !== payload.id),
      );
    }
    case TRY_PLUGIN_ACTION_DONE: {
      return _.setIn(["results", "try"], payload?.payload);
    }
    default:
      return _;
  }
};

export default pluginReducer;
