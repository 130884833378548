import Div from "@jumbo/shared/Div";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Button,
  Card,
  CircularProgress,
  Collapse,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { delTask, fchTask, fetchTasks } from "app/redux/actions/task";
import Loading from "app/shared/Loading";
import ConfirmModal from "app/shared/Modal/ConfirmModal";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const TaskPage = () => {
  const dispatch = useDispatch();

  const [refreshTime, setRefreshTime] = useState(10);
  const [isDelModalOpen, setIsDelModalOpen] = useState(false);
  const [taskId, setTaskId] = useState(null);
  const [expandedTasks, setExpandedTasks] = useState({});

  const tasks = useSelector(({ task }) => task.get("results"));
  const user = useSelector(({ app }) => app.getIn(["user"]));

  const handleRefresh = (id) => {
    dispatch(fchTask(user.id, id));
  };
  const handleDelModalOpen = (id) => {
    setTaskId(id);
    setIsDelModalOpen(true);
  };

  const handleDelModalClose = () => {
    setIsDelModalOpen(false);
    setTaskId(null);
  };

  const handleDelete = () => {
    dispatch(delTask(user.id, taskId));
    handleDelModalClose();
  };

  const handleDownload = (path) => {
    window.open(path, "_blank");
  };

  const handleExpandClick = (id) => {
    setExpandedTasks((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  useEffect(() => {
    if (user) {
      dispatch(fetchTasks(user.id));
    }
  }, [user]);

  useEffect(() => {
    const interval = setInterval(() => {
      setRefreshTime(refreshTime - 1);
    }, 1000);

    if (refreshTime < 0) {
      dispatch(fetchTasks(user.id));
      setRefreshTime(10);
    }

    return () => clearInterval(interval);
  }, [refreshTime]);

  if (!user || !tasks) return <Loading />;

  return (
    <>
      <Card sx={{ p: 4, borderRadius: 0, mb: 2 }}>
        <Stack flexDirection="row" justifyContent="space-between">
          <Typography variant="h4">任務清單</Typography>
          <Typography variant="body1">
            {`重新整理：${refreshTime}秒`}
          </Typography>
        </Stack>
      </Card>

      <Stack gap={2}>
        {tasks.map((task) => (
          <Card key={task.id} sx={{ p: 4, borderRadius: 0 }}>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack
                flexDirection="row"
                gap={18}
                alignItems="center"
                justifyContent="center"
              >
                <TaskProgress
                  progress={task.get("progress")}
                  status={task.get("status")}
                />

                <Stack
                  flexDirection="column"
                  gap={1}
                  justifyContent="center"
                  sx={{ maxWidth: 700 }}
                >
                  <Typography variant="body1">
                    類型：{TASK_TYPE_DICT[task.get("type")] || task.get("type")}
                  </Typography>
                  <Typography variant="body1">
                    建立時間：
                    {moment
                      .unix(task.get("created_at"))
                      .format("YYYY-MM-DD HH:mm:ss")}
                  </Typography>
                </Stack>
              </Stack>
              <Stack flexDirection="row" gap={2} alignItems="center">
                {task.get("status") === "FINISHED" && (
                  <IconButton
                    onClick={() => handleExpandClick(task.get("id"))}
                    aria-expanded={expandedTasks[task.get("id")]}
                    aria-label="show more"
                  >
                    <ExpandMoreIcon />
                  </IconButton>
                )}
                {task.get("status") !== "FINISHED" && (
                  <Button
                    variant="contained"
                    onClick={() => handleRefresh(task.get("id"))}
                  >
                    重新整理
                  </Button>
                )}
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => handleDelModalOpen(task.get("id"))}
                >
                  刪除
                </Button>
              </Stack>
            </Stack>
            {task.get("status") === "FINISHED" && (
              <Collapse
                in={expandedTasks[task.get("id")]}
                timeout="auto"
                unmountOnExit
              >
                <Stack sx={{ mt: 2, gap: 2 }}>
                  {task.get("results") && task.get("results").size > 0 ? (
                    task.get("results").map((result, index) => (
                      <Card key={index} sx={{ p: 2 }}>
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Typography
                            variant="subtitle1"
                            sx={{ fontWeight: "bold", minWidth: 80 }}
                          >
                            {result.get("result_type") === "text" && "文字："}
                            {result.get("result_type") === "url" && "網址："}
                            {result.get("result_type") === "download" &&
                              "檔案名稱："}
                            {result.get("result_type") === "image" && "圖片："}
                          </Typography>
                          {result.get("result_type") === "text" && (
                            <Typography
                              variant="body1"
                              sx={{ wordBreak: "break-word", flex: 1 }}
                            >
                              {result.get("result")}
                            </Typography>
                          )}
                          {result.get("result_type") === "url" && (
                            <a
                              href={result.get("result")}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                textDecoration: "none",
                                wordBreak: "break-word",
                              }}
                            >
                              {result.get("result")}
                            </a>
                          )}
                          {result.get("result_type") === "download" && (
                            <>
                              <Typography
                                variant="body1"
                                sx={{ wordBreak: "break-word", flex: 1 }}
                              >
                                {result.get("result")}
                              </Typography>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() =>
                                  handleDownload(result.get("result"))
                                }
                              >
                                下載
                              </Button>
                            </>
                          )}
                          {result.get("result_type") === "image" && (
                            <>
                              <Typography
                                variant="body1"
                                sx={{ wordBreak: "break-word", flex: 1 }}
                              >
                                {result.get("result")}
                              </Typography>
                              <img
                                src={result.get("result")}
                                alt="Preview"
                                style={{
                                  maxWidth: "200px",
                                  maxHeight: "200px",
                                  objectFit: "contain",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  window.open(result.get("result"), "_blank")
                                }
                              />
                            </>
                          )}
                        </Stack>
                      </Card>
                    ))
                  ) : (
                    <Typography variant="body1">
                      No results available
                    </Typography>
                  )}
                </Stack>
              </Collapse>
            )}
          </Card>
        ))}
      </Stack>
      <ConfirmModal
        content="確認刪除該筆任務？"
        open={isDelModalOpen}
        onClose={handleDelModalClose}
        onConfirm={handleDelete}
      />
    </>
  );
};

export default TaskPage;

const TaskProgress = ({ progress, status }) => {
  const getColor = (status) => {
    if (status === "FINISHED") return "success";
    if (status === "FAILED") return "error";
    return "primary";
  };

  return (
    <Div sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        variant="determinate"
        size={100}
        value={progress}
        color={getColor(status)}
      />
      <Div
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color={getColor(status)}
          sx={{ fontSize: 22 }}
        >
          {`${Math.round(progress)}%`}
        </Typography>
      </Div>
    </Div>
  );
};

TaskProgress.propTypes = {
  progress: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
};

const TASK_TYPE_DICT = {
  translation: "翻譯",
};
