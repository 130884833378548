import { useJumboApp } from "@jumbo/hooks";
import Div from "@jumbo/shared/Div";
import { Button, Card, Grid, Typography } from "@mui/material";
import { LAYOUT_NAMES } from "app/layouts/layouts";
import ExpandAccordion from "app/shared/ExpandAccordion";
import { homeQA1, homeQA2 } from "app/utils/homeQA";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Home() {
  const { setActiveLayout } = useJumboApp();
  const navigate = useNavigate();

  useEffect(() => {
    setActiveLayout(LAYOUT_NAMES.VERTICAL_DEFAULT);
  }, []);

  return (
    <Div>
      <Card
        sx={{
          py: 3,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Div sx={{ mb: 3 }}>
          <Typography variant="h1" textAlign="center">
            建立新對話
          </Typography>
          <Typography variant="body1" textAlign="center">
            請點擊以下按鈕建立新對話
          </Typography>
        </Div>
        <Grid container spacing={2} sx={{ px: 3, width: "80%", mb: 3 }}>
          <Grid item md={6}>
            <ExpandAccordion content={homeQA1} />
          </Grid>
          <Grid item md={6}>
            <ExpandAccordion content={homeQA2} />
          </Grid>
        </Grid>

        <Button
          variant="contained"
          color="primary"
          sx={{
            mx: 3,
            mb: 3,
          }}
          onClick={() => navigate("/create/conversation")}
        >
          新增對話
        </Button>
      </Card>
    </Div>
  );
}

export default Home;
