import { Button, TextField } from "@mui/material";
import { useReactFlow } from "@xyflow/react";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";

import NodeWrapper from "./NodeWrapper";

const ParameterNode = ({ id, data }) => {
  const [paramLabel, setParamLabel] = useState(data.paramLabel || "");
  const [paramType, setParamType] = useState(data.paramType || "str");
  const [paramValue, setParamValue] = useState(data.paramValue || "");
  const [paramDescription, setParamDescription] = useState(
    data.paramDescription || "",
  );
  const { setNodes } = useReactFlow();

  const updateNodeData = useCallback(
    (newData) => {
      setNodes((nds) =>
        nds.map((node) => {
          if (node.id === id) {
            node.data = { ...node.data, ...newData };
          }
          return node;
        }),
      );
    },
    [id, setNodes],
  );

  const handleParamLabelChange = (e) => {
    const newParamLabel = e.target.value;
    setParamLabel(newParamLabel);
    updateNodeData({ paramLabel: newParamLabel });
  };

  const handleParamTypeClick = () => {
    const newParamType = paramType === "str" ? "int" : "str";
    setParamType(newParamType);
    setParamValue("");
    updateNodeData({ paramType: newParamType, paramValue: "" });
  };

  const handleParamValueChange = (e) => {
    const newParamValue = e.target.value;
    setParamValue(newParamValue);
    updateNodeData({ paramValue: newParamValue });
  };

  const handleParamDescriptionChange = (e) => {
    const newParamDescription = e.target.value;
    setParamDescription(newParamDescription);
    updateNodeData({ paramDescription: newParamDescription });
  };

  return (
    <NodeWrapper
      id={id}
      title="Parameter Node"
      initialInputs={data.inputs || []}
      initialOutputs={data.outputs || []}
      width={300}
      data={data}
      setNodes={setNodes}
      color="#4CAF50"
      canAddInputs={false}
      canAddOutputs={false}
      canDeleteInputs={false}
      canDeleteOutputs={false}
    >
      <TextField
        id={`para-label-${id}`}
        label="Parameter Label"
        value={paramLabel}
        onChange={handleParamLabelChange}
        placeholder="Enter parameter label"
        fullWidth
        margin="normal"
      />
      <Button
        onClick={handleParamTypeClick}
        fullWidth
        variant="outlined"
        style={{ marginBottom: "10px" }}
      >
        {paramType === "str" ? "String" : "Integer"}
      </Button>
      <TextField
        id={`param-value-${id}`}
        label="Default Value"
        type={paramType === "int" ? "number" : "text"}
        value={paramValue}
        onChange={handleParamValueChange}
        placeholder={`Enter default ${paramType === "int" ? "integer" : "string"} value`}
        fullWidth
        margin="normal"
      />
      <TextField
        id={`param-description-${id}`}
        label="Parameter Description"
        value={paramDescription}
        onChange={handleParamDescriptionChange}
        placeholder="Enter parameter description"
        fullWidth
        margin="normal"
        multiline
        rows={2}
      />
    </NodeWrapper>
  );
};

ParameterNode.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.shape({
    paramLabel: PropTypes.string,
    paramType: PropTypes.string,
    paramValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    paramDescription: PropTypes.string,
    inputs: PropTypes.array,
    outputs: PropTypes.array,
  }).isRequired,
};

export default ParameterNode;
