import Div from "@jumbo/shared/Div";
import { Typography } from "@mui/material";
import React from "react";

function Footer() {
  return (
    <Div
      sx={{
        py: 2,
        px: { lg: 6, xs: 4 },
        borderTop: 2,
        borderColor: "divider",
        bgcolor: "background.paper",
      }}
    >
      <Typography variant="subtitle2" color="text.primary" textAlign="center">
        Copyright©{new Date().getFullYear()} RAGi All Rights Reserved
      </Typography>
    </Div>
  );
}

export default Footer;
