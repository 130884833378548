import {
  Button,
  Card,
  CardContent,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { thesisSummary } from "app/redux/actions/plugin";
import RadioSection from "app/shared/Form/RadioSection";
import SelectSection from "app/shared/Form/SelectSection";
import UploadSection from "app/shared/Form/UploadSection";
import Loading from "app/shared/Loading";
import PDF_PARSER from "app/utils/constants/pdfParser";
import { translateLangDict } from "app/utils/constants/translateLangDict";
import { useFormik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import * as yup from "yup";

const ThesisMasterPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const modelData = useSelector(({ model }) =>
    model.getIn(["results", "models"]),
  );

  const uId = useSelector(({ app }) => app.getIn(["user", "id"]));

  const initialValues = {
    model_name: null,
    file_path: "",
    language: null,
    detail_level: null,
    type: "file",
    url_path: "",
    output_mode: { label: "DOCX", value: "docx" },
    pdf_engine: PDF_PARSER[0],
  };

  const validationSchema = yup.object().shape({
    type: yup.string().required("請選擇輸出模式"),
    model_name: yup.object().required("請輸入模型名稱"),
    file_path: yup.array().when("type", {
      is: "file",
      then: () => yup.array().min(1, "請上傳檔案").required("請上傳檔案"),
      otherwise: () => yup.array().notRequired(),
    }),
    url_path: yup.string().when("type", {
      is: "url",
      then: () =>
        yup
          .string()
          .matches(/^https:\/\/arxiv\.org\//, "請輸入正確的arXiv連結")
          .required("請輸入arXiv連結"),
      otherwise: () => yup.string().notRequired(),
    }),
    detail_level: yup.object().required("請輸入詳細程度"),
    output_mode: yup.object().required("請輸入輸出格式"),
    language: yup.object().required("請輸入語言"),
    pdf_engine: yup.object().when("type", {
      is: "file",
      then: () => yup.object().required("請選擇解析器"),
      otherwise: () => yup.object().notRequired(),
    }),
  });

  const handleSubmit = async (values) => {
    const data = {
      model_name: values.model_name.value,
      type: values.type,
      path: values.type === "file" ? values.file_path[0].path : values.url_path,
      detail_level: values.detail_level.value,
      output_mode: values.output_mode.value,
      language: values.language.value,
      pdf_engine: values.pdf_engine.value,
    };

    const success = await dispatch(thesisSummary(uId, data));
    if (!success) return;
    navigate("/task");
  };

  const formik = useFormik({
    validateOnChange: true,
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  const handleUploadFile = (files) => {
    formik.setFieldValue("file_path", files);
  };

  if (!modelData) return <Loading />;

  return (
    <Card sx={{ p: 4 }}>
      <Typography variant="h3">論文大師</Typography>

      <Divider sx={{ my: 4 }} />

      <CardContent sx={{ p: 0 }}>
        <form onSubmit={formik.handleSubmit}>
          <Stack>
            <RadioSection
              title="輸出模式"
              name="type"
              options={[
                {
                  label: "上傳檔案",
                  value: "file",
                },
                {
                  label: "arXiv連結",
                  value: "url",
                },
              ]}
              value={formik.values.type}
              onChange={formik.handleChange}
              error={formik.touched.type && Boolean(formik.errors.type)}
              helperText={formik.touched.type && formik.errors.type}
            />
            {formik.values.type === "file" && (
              <UploadSection
                position="left"
                showPath
                hideTitle
                supportType={["pdf"]}
                value={formik.values.file_path}
                onFinish={handleUploadFile}
                error={
                  formik.touched.file_path && Boolean(formik.errors.file_path)
                }
                helperText={formik.touched.file_path && formik.errors.file_path}
              />
            )}
            {formik.values.type === "url" && (
              <TextField
                label="arXiv連結"
                name="url_path"
                sx={{ mb: 3 }}
                value={formik.values.url_path}
                onChange={formik.handleChange}
                error={
                  formik.touched.url_path && Boolean(formik.errors.url_path)
                }
                helperText={formik.touched.url_path && formik.errors.url_path}
              />
            )}
          </Stack>
          <SelectSection
            title="摘要精密程度"
            name="detail_level"
            defaultOptionLabel="請選擇精密程度"
            options={[
              { label: "低 (基本概述)", value: "low" },
              { label: "中 (詳細摘要)", value: "medium" },
              { label: "高 (深入分析)", value: "high" },
            ]}
            value={formik.values.detail_level}
            onChange={formik.handleChange}
            error={
              formik.touched.detail_level && Boolean(formik.errors.detail_level)
            }
            helperText={
              formik.touched.detail_level && formik.errors.detail_level
            }
          />
          <SelectSection
            title="語言模型"
            name="model_name"
            defaultOptionLabel="選擇語言模型"
            value={formik.values.model_name}
            onChange={formik.handleChange}
            options={modelData
              .map((model) => ({
                value: model.get("name"),
                label: model.get("name"),
              }))
              .toJS()}
            error={
              formik.touched.model_name && Boolean(formik.errors.model_name)
            }
            helperText={formik.touched.model_name && formik.errors.model_name}
          />
          <SelectSection
            title="目標語言"
            name="language"
            defaultOptionLabel="請選擇翻譯後的語言"
            value={formik.values.language}
            onChange={formik.handleChange}
            options={Object.entries(translateLangDict).map(
              ([value, label]) => ({
                label,
                value,
              }),
            )}
            error={formik.touched.language && Boolean(formik.errors.language)}
            helperText={formik.touched.language && formik.errors.language}
          />
          <SelectSection
            title="請選擇輸出格式"
            name="output_mode"
            defaultOptionLabel="請選擇輸出格式"
            options={[
              { label: "DOCX", value: "docx" },
              // { label: "PPTX", value: "pptx" }, // 目前不支援
            ]}
            value={formik.values.output_mode}
            onChange={formik.handleChange}
            error={
              formik.touched.output_mode && Boolean(formik.errors.output_mode)
            }
            helperText={formik.touched.output_mode && formik.errors.output_mode}
          />
          {formik.values.type === "file" && (
            <SelectSection
              title="解析器"
              name="pdf_engine"
              value={formik.values.pdf_engine}
              onChange={formik.handleChange}
              defaultOptionLabel="請選擇解析器"
              options={PDF_PARSER}
              error={
                formik.touched.pdf_engine && Boolean(formik.errors.pdf_engine)
              }
              helperText={formik.touched.pdf_engine && formik.errors.pdf_engine}
            />
          )}
          <Button type="submit" variant="contained" color="primary">
            生成摘要
          </Button>
        </form>
      </CardContent>
    </Card>
  );
};

export default ThesisMasterPage;
