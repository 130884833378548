import { Card, Divider, Grid, Typography } from "@mui/material";
import Loading from "app/shared/Loading";
import PluginCard from "app/shared/PluginCard";
import React from "react";
import { useSelector } from "react-redux";

const PluginManagePage = () => {
  const pluginList = useSelector(({ plugin }) =>
    plugin.getIn(["results", "plugins"]),
  );

  const user_id = useSelector(({ app }) => app.getIn(["user", "id"]));

  if (!pluginList) return <Loading />;

  return (
    <Card sx={{ p: 4 }}>
      <Typography variant="h3" sx={{ m: 0 }}>
        AI代理人
      </Typography>

      <Divider sx={{ my: 4 }} />

      <Grid container spacing={2}>
        {plugins.map((plugin) => (
          <Grid item xs={12} md={3} key={plugin.id}>
            <PluginCard plugin={plugin} user_id={user_id} key={plugin.id} />
          </Grid>
        ))}
        {pluginList.map((plugin) => (
          <Grid item xs={12} md={3} key={plugin.id}>
            <PluginCard plugin={plugin} user_id={user_id} />
          </Grid>
        ))}
      </Grid>
    </Card>
  );
};

export default PluginManagePage;

const plugins = [
  {
    id: 1,
    name: "翻譯大師",
    type: "translate_master",
    description:
      "您的多語言溝通助手。支援多種語言之間的即時翻譯,讓您輕鬆跨越語言障礙。",
    features: "可以翻譯PPTX, DOCX 文件，並以原本格式呈現翻譯結果",
    thumbnail_path: "/images/plugin/translate_master.jpg",
  },
  {
    id: 2,
    name: "論文大師",
    type: "thesis_master",
    description: "您閱讀論文時的摘要助手",
    features:
      "users可以上載\n 1.不限定格式的PDF\n 2. https://arxiv.org/ 內的文章解析 \n (ex. https://arxiv.org/abs/2407.14246)",
    thumbnail_path: "/images/plugin/thesis_master.jpg",
  },
];
