import JumboIconButton from "@jumbo/components/JumboIconButton";
import Div from "@jumbo/shared/Div";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import {
  Autocomplete,
  Button,
  Card,
  Divider,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import {
  addAllModelRules,
  addModelRules,
  deleteAllModelRules,
  deleteModelRules,
  fchModel,
} from "app/redux/actions/model";
import APIKeyTransform from "app/shared/APIKeyTransform";
import Loading from "app/shared/Loading";
import ModelFormModal from "app/shared/Modal/ModelFormModal";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";

const ModelEditPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const searchParams = useParams();
  const modelId = searchParams.id;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const modelData = useSelector(({ model }) =>
    model.getIn(["results", "info"]),
  );

  const groupData = useSelector(({ group }) =>
    group.getIn(["results", "other_groups"]),
  );

  const fchData = async (id) => {
    const res = await dispatch(fchModel(id));
    if (!res.success) {
      navigate("/404", { replace: true });
      return;
    }
  };

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleChangeRole = (value) => {
    const rules = modelData.get("rules");

    if (rules.size > value.length) return; // 搜尋刪除情況下防止錯誤

    const addedValue = value.find(
      (item) => !rules.some((rule) => rule.get("id") === item.id),
    );

    dispatch(addModelRules(modelId, addedValue));
  };

  const handleAddAllRole = () => {
    const filteredGroup = groupData.filter(
      (group) =>
        !modelData
          .get("rules")
          .some((role) => role.get("user_id") === group.get("id")),
    );
    dispatch(addAllModelRules(modelId, filteredGroup));
  };

  const handleDeleteAllRole = () => {
    dispatch(deleteAllModelRules(modelId));
  };

  useEffect(() => {
    if (!modelId) return;
    fchData(modelId);
  }, [modelId]);

  if (!modelData || !groupData) return <Loading />;

  return (
    <>
      <Card sx={{ p: 4 }}>
        <Stack flexDirection="row" alignItems="center">
          <JumboIconButton onClick={() => navigate(-1)}>
            <ArrowBackOutlinedIcon />
          </JumboIconButton>
          <Typography variant="h3" sx={{ m: 0 }}>
            模型設定
          </Typography>
        </Stack>

        <Divider sx={{ my: 4 }} />

        <Stack flexDirection="row" alignItems="flex-start" gap={2}>
          <Stack flexDirection="column" gap={1}>
            <Stack flexDirection="row" gap={1}>
              <Typography variant="h4" sx={{ m: 0 }}>
                名稱：<strong>{modelData.get("name")}</strong>
              </Typography>

              <Divider orientation="vertical" flexItem />

              <Typography variant="h4" sx={{ m: 0 }}>
                EndPoint：<strong>{modelData.get("api_base")}</strong>
              </Typography>

              <Divider orientation="vertical" flexItem />

              <Typography variant="h4" sx={{ m: 0 }}>
                最大Token數：<strong>{modelData.get("max_tokens")}</strong>
              </Typography>
            </Stack>
            <Stack flexDirection="row" alignItems="center">
              <Typography
                variant="h4"
                sx={{
                  m: 0,
                }}
              >
                API KEY：
              </Typography>
              <Div sx={{ width: "80%" }}>
                <APIKeyTransform apiKey={modelData.get("api_key")} />
              </Div>
            </Stack>
            <Div>
              <Typography variant="h4">
                對話模型：{modelData.get("is_chat_model") ? "是" : "否"}
              </Typography>
            </Div>
          </Stack>

          <Button variant="contained" size="small" onClick={handleModalOpen}>
            編輯
          </Button>
        </Stack>

        <Divider sx={{ my: 4 }} />

        <Stack flexDirection="row" alignItems="center" sx={{ mb: 2 }} gap={2}>
          <Typography variant="h4" sx={{ m: 0 }}>
            權限設定
          </Typography>
          {groupData.size === modelData.get("rules").size ? (
            <Button
              variant="contained"
              size="small"
              onClick={handleDeleteAllRole}
            >
              全部刪除
            </Button>
          ) : (
            <Button variant="contained" size="small" onClick={handleAddAllRole}>
              全部新增
            </Button>
          )}
        </Stack>

        <Div>
          <Autocomplete
            sx={{ mb: 3 }}
            multiple
            id="group"
            limitTags={3}
            filterSelectedOptions
            disableClearable
            options={groupData.toJS()}
            isOptionEqualToValue={(option, value) =>
              option.email === value.email
            }
            value={modelData.get("rules").toJS()}
            getOptionLabel={(option) => option.email}
            onChange={(e, value) => handleChangeRole(value)}
            renderTags={() => null}
            renderInput={(params) => (
              <TextField {...params} label="使用者/群組" />
            )}
          />

          <TableContainer component={Paper}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>使用者/群組</TableCell>
                  <TableCell>刪除</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {modelData.get("rules").map((rule, index) => (
                  <TableRow key={index}>
                    <TableCell>{rule.get("email")}</TableCell>
                    <TableCell>
                      <JumboIconButton
                        sx={{ px: 0 }}
                        onClick={() =>
                          dispatch(deleteModelRules(modelId, rule.get("id")))
                        }
                      >
                        <CancelOutlinedIcon color="error" />
                      </JumboIconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Div>
      </Card>
      <ModelFormModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        model={{
          name: modelData.get("name"),
          api_base: modelData.get("api_base"),
          api_key: modelData.get("api_key"),
          max_tokens: modelData.get("max_tokens"),
          is_chat_model: modelData.get("is_chat_model"),
        }}
        modelId={modelId}
      />
    </>
  );
};

export default ModelEditPage;
