import { useJumboApp } from "@jumbo/hooks";
import Div from "@jumbo/shared/Div";
import { Typography } from "@mui/material";
import { LAYOUT_NAMES } from "app/layouts/layouts";
import React, { useEffect } from "react";

const FiveOOPage = () => {
  const { setActiveLayout } = useJumboApp();

  useEffect(() => {
    setActiveLayout(LAYOUT_NAMES.SOLO_PAGE);
  }, []);

  return (
    <Div
      sx={{
        flex: 1,
        flexWrap: "wrap",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        top: "40%",
        transform: "translateY(-50%)",
        p: (theme) => theme.spacing(4),
      }}
    >
      <Div sx={{ display: "inline-flex", mb: 1 }}>
        <Typography
          variant="h1"
          color="#423883"
          component="div"
          sx={{ fontSize: 200, m: 0 }}
        >
          5
        </Typography>
        <Typography variant="h1" component="div" sx={{ fontSize: 200, m: 0 }}>
          0
        </Typography>
        <Typography
          variant="h1"
          color="#423883"
          component="div"
          sx={{ fontSize: 200, m: 0 }}
        >
          0
        </Typography>
      </Div>
      <Typography
        align={"center"}
        component={"h2"}
        variant={"h1"}
        color={"text.secondary"}
        mb={3}
      >
        伺服器產生錯誤，請稍後再試!!
      </Typography>
    </Div>
  );
};

export default FiveOOPage;
