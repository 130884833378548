import { Card, Divider, Stack, Typography } from "@mui/material";
import KnowledgebaseDataTable from "app/shared/DataTable/KnowledgebaseDataTable";
import CreateKnowledgebaseModal from "app/shared/Modal/CreateKnowledgebase";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const KnowledgebasePage = () => {
  const [knowledgebaseList, setKnowledgebaseList] = useState(null);
  // const [searchValue, setSearchValue] = useState("");

  const knowledgebases = useSelector(({ knowledgebase }) =>
    knowledgebase.getIn(["results", "knowledgebases"]),
  );

  const user_id = useSelector(({ app }) => app.getIn(["user", "id"]));

  // const handleFilter = (e) => {
  //   const value = e.target.value;
  //   const filtered = knowledgebases.filter((knowledgebase) =>
  //     knowledgebase.get("name").toLowerCase().includes(value.toLowerCase()),
  //   );
  //   setKnowledgebaseList(filtered);
  //   setSearchValue(value);
  // };

  useEffect(() => {
    if (!knowledgebases) return;
    setKnowledgebaseList(knowledgebases);
  }, [knowledgebases]);

  if (!knowledgebaseList) return null;

  return (
    <Card sx={{ p: 4 }}>
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h3" sx={{ m: 0 }}>
          知識庫管理
        </Typography>
        <Stack flexDirection="row" gap={2}>
          {/* #TODO: Search bar is not implemented yet */}
          {/* <TextField
              placeholder="搜尋"
              value={searchValue}
              onChange={handleFilter}
              size="small"
            /> */}
          <CreateKnowledgebaseModal uId={user_id} />
        </Stack>
      </Stack>

      <Divider sx={{ my: 4 }} />

      <KnowledgebaseDataTable dataSource={knowledgebaseList} />
    </Card>
  );
};

export default KnowledgebasePage;
