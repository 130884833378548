import Div from "@jumbo/shared/Div";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

const ExpandAccordion = ({ content }) => {
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      {content.map((qa, index) => (
        <Div key={qa.id} sx={{ mb: 1 }}>
          <Accordion
            expanded={expanded === `panel${index + 1}`}
            onChange={handleChange(`panel${index + 1}`)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
              sx={{ backgroundColor: "primary.main", color: "#fff" }}
            >
              <Typography>{qa.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{qa.content}</Typography>
            </AccordionDetails>
          </Accordion>
        </Div>
      ))}
    </>
  );
};

export default ExpandAccordion;

ExpandAccordion.propTypes = {
  content: PropTypes.array.isRequired,
};
