import {
  Autocomplete,
  FormControl,
  FormHelperText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

const SelectSection = ({
  name,
  width,
  title,
  value,
  onChange,
  options,
  error,
  helperText,
  defaultOptionLabel,
  disabled,
  multiple,
  groupBy,
}) => {
  return (
    <Stack sx={{ width: { md: width || 400, sx: "100%" }, mb: 3 }}>
      <Typography variant="h4" sx={{ mb: 2 }}>
        {title}
      </Typography>
      <FormControl fullWidth>
        <Autocomplete
          groupBy={groupBy}
          name={name}
          disabled={disabled}
          multiple={multiple}
          options={options}
          getOptionLabel={(option) => option.label}
          value={value}
          onChange={(event, newValue) => {
            onChange({
              target: {
                name,
                value: newValue,
              },
            });
          }}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          filterSelectedOptions
          disableClearable
          renderInput={(params) => (
            <TextField
              {...params}
              name={name}
              error={error}
              placeholder={defaultOptionLabel}
            />
          )}
          filterOptions={(options, { inputValue }) =>
            options.filter((option) =>
              option.label.toLowerCase().includes(inputValue.toLowerCase()),
            )
          }
        />
        <FormHelperText error={error}>{helperText}</FormHelperText>
      </FormControl>
    </Stack>
  );
};

export default React.memo(SelectSection);

SelectSection.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  width: PropTypes.number,
  value: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
  error: PropTypes.bool,
  helperText: PropTypes.string,
  defaultOptionLabel: PropTypes.string,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  groupBy: PropTypes.any,
};
