import Login from "app/pages/auth/login";
import React from "react";

const authRoutes = [
  {
    path: "/user/login",
    element: <Login />,
  },
];

export default authRoutes;
