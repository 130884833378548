import Immutable from "immutable";

import { USER_LOGOUT } from "../types/auth";
import {
  CREATE_USER_DONE,
  DEL_USER_DONE,
  FCH_GROUPS_DONE,
  FCH_OTHER_GROUPS_DONE,
  UPD_PERMISSION_DONE,
  UPD_USER_DONE,
} from "../types/group";

const INIT_STATE = Immutable.OrderedMap({
  results: {
    groups: null,
    other_groups: null,
  },
});

const groupReducer = (_ = INIT_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case FCH_GROUPS_DONE: {
      const data = Immutable.fromJS(payload.doc).sort((a, b) => {
        const aIsChinese = /[\u4e00-\u9fff]/.test(a.get("username"));
        const bIsChinese = /[\u4e00-\u9fff]/.test(b.get("username"));

        if (aIsChinese && !bIsChinese) return 1;
        if (!aIsChinese && bIsChinese) return -1;
        return a.get("username").localeCompare(b.get("username"));
      });
      return _.setIn(["results", "groups"], data);
    }
    case FCH_OTHER_GROUPS_DONE: {
      const data = Immutable.fromJS(payload.doc).sort((a, b) => {
        const aIsChinese = /[\u4e00-\u9fff]/.test(a.get("username"));
        const bIsChinese = /[\u4e00-\u9fff]/.test(b.get("username"));

        if (aIsChinese && !bIsChinese) return 1;
        if (!aIsChinese && bIsChinese) return -1;
        return a.get("username").localeCompare(b.get("username"));
      });
      return _.setIn(["results", "other_groups"], data);
    }
    case CREATE_USER_DONE: {
      const _newGroups = _.getIn(["results", "groups"])
        .push(Immutable.fromJS(payload.doc))
        .sort((a, b) => {
          const aIsChinese = /[\u4e00-\u9fff]/.test(a.get("username"));
          const bIsChinese = /[\u4e00-\u9fff]/.test(b.get("username"));

          if (aIsChinese && !bIsChinese) return 1;
          if (!aIsChinese && bIsChinese) return -1;
          return a.get("username").localeCompare(b.get("username"));
        });
      const _newOtherGroups = _.getIn(["results", "other_groups"])
        .push(Immutable.fromJS(payload.doc))
        .sort((a, b) => {
          const aIsChinese = /[\u4e00-\u9fff]/.test(a.get("username"));
          const bIsChinese = /[\u4e00-\u9fff]/.test(b.get("username"));

          if (aIsChinese && !bIsChinese) return 1;
          if (!aIsChinese && bIsChinese) return -1;
          return a.get("username").localeCompare(b.get("username"));
        });
      return _.setIn(["results", "groups"], _newGroups).setIn(
        ["results", "other_groups"],
        _newOtherGroups,
      );
    }
    case UPD_USER_DONE: {
      const _newGroups = _.getIn(["results", "groups"]).map((item) => {
        if (item.get("id") === payload.doc.id) {
          const doc = {
            ...item.toJS(),
            ...payload.doc,
          };
          return Immutable.fromJS(doc);
        }
        return item;
      });
      const _newOtherGroups = _.getIn(["results", "other_groups"]).map(
        (item) => {
          if (item.get("id") === payload.doc.id) {
            const doc = {
              ...item.toJS(),
              ...payload.doc,
            };
            return Immutable.fromJS(doc);
          }
          return item;
        },
      );
      return _.setIn(["results", "groups"], _newGroups).setIn(
        ["results", "other_groups"],
        _newOtherGroups,
      );
    }
    case UPD_PERMISSION_DONE: {
      const _newGroups = _.getIn(["results", "groups"]).map((item) => {
        if (item.get("id") === payload.id) {
          const doc = {
            ...item.toJS(),
            is_enabled: payload.is_enabled,
          };
          return Immutable.fromJS(doc);
        }
        return item;
      });
      const _newOtherGroups = _.getIn(["results", "other_groups"]).map(
        (item) => {
          if (item.get("id") === payload.id) {
            const doc = {
              ...item.toJS(),
              is_enabled: payload.is_enabled,
            };
            return Immutable.fromJS(doc);
          }
          return item;
        },
      );
      return _.setIn(["results", "groups"], _newGroups).setIn(
        ["results", "other_groups"],
        _newOtherGroups,
      );
    }
    case DEL_USER_DONE: {
      const _newGroups = _.getIn(["results", "groups"]).filter(
        (item) => item.get("id") !== payload.id,
      );
      const _newOtherGroups = _.getIn(["results", "other_groups"]).filter(
        (item) => item.get("id") !== payload.id,
      );
      return _.setIn(["results", "groups"], _newGroups).setIn(
        ["results", "other_groups"],
        _newOtherGroups,
      );
    }
    case USER_LOGOUT:
      return INIT_STATE;
    default:
      return _;
  }
};

export default groupReducer;
