import Div from "@jumbo/shared/Div";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { deletePlugin, executePlugin } from "app/redux/actions/plugin";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";

import ConfirmModal from "../Modal/ConfirmModal";

const PluginCard = ({ plugin, user_id }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [anchorEl, setAnchorEl] = useState(null);
  const [isDelModalOpen, setIsDelModalOpen] = useState(false);
  const [pluginId, setPluginId] = useState(null);

  const isAdminRoute = location.pathname.startsWith("/admin/");

  const open = Boolean(anchorEl);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleExecutePlugin = async (plugin) => {
    switch (plugin.type) {
      case "chatbot": {
        const id = await dispatch(executePlugin(plugin.id, user_id));
        navigate(`/conversation/${id}`);
        break;
      }
      case "task": {
        navigate(`/plugin/${plugin.id}/execute`);
        break;
      }
      case "translate_master": {
        navigate(`/plugin/translate_master`);
        break;
      }
      case "thesis_master": {
        navigate(`/plugin/thesis_master`);
        break;
      }
      default: {
        break;
      }
    }
  };

  const handleDelModalOpen = (id) => {
    setPluginId(id);
    setIsDelModalOpen(true);
  };

  const handleDelModalClose = () => {
    setPluginId(null);
    setIsDelModalOpen(false);
  };

  const handleDelete = () => {
    dispatch(deletePlugin(pluginId));

    handleDelModalClose();
  };

  const handleGoToEdit = () => {
    navigate(`/admin/plugin/process_master/${plugin.id}`);
  };

  let imageUrl = plugin.thumbnail_path;

  if (plugin.type === "chatbot" || plugin.type === "task") {
    const filename = (plugin?.thumbnail_path || "").split("/").pop();
    imageUrl = `/files/${filename}?namespace=files`;
  }

  return (
    <>
      <Card variant="outlined" key={plugin.id}>
        <CardHeader
          action={
            isAdminRoute && (
              <>
                <IconButton onClick={handleMenuOpen}>
                  <MoreHorizIcon />
                </IconButton>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleMenuClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem onClick={handleGoToEdit}>編輯</MenuItem>
                  <MenuItem onClick={() => handleDelModalOpen(plugin.id)}>
                    刪除
                  </MenuItem>
                </Menu>
              </>
            )
          }
          sx={{ p: 0, px: 2 }}
        />
        <Div sx={{ display: "flex", justifyContent: "center", py: 2 }}>
          <Avatar
            sx={{
              width: 120,
              height: 120,
              color: "primary.main",
              backgroundColor: "inherit",
            }}
            src={imageUrl}
            alt={plugin.name}
          />
        </Div>

        <CardContent sx={{ px: 2, py: 0, minHeight: 200 }}>
          <Stack gap={1}>
            <Typography variant="h1" textAlign="center">
              {plugin.name}
            </Typography>
            <Typography variant="body1">描述：{plugin.description}</Typography>

            <Box>
              <Typography variant="body1">特色：</Typography>
              <Typography variant="body1">
                {plugin.features.split("\n").map((feature, index) => (
                  <React.Fragment key={index}>
                    {feature}
                    <br />
                  </React.Fragment>
                ))}
              </Typography>
            </Box>
          </Stack>
        </CardContent>

        <Divider sx={{ my: 2 }} />

        <CardActions sx={{ pb: 3 }}>
          <Stack width="100%" direction="row" justifyContent="center">
            <Button
              variant="contained"
              endIcon={<ArrowForwardIcon />}
              sx={{ borderRadius: 100 }}
              onClick={() => handleExecutePlugin(plugin)}
            >
              立即使用
            </Button>
          </Stack>
        </CardActions>
      </Card>
      <ConfirmModal
        content="確認刪除該插件？"
        open={isDelModalOpen}
        onClose={handleDelModalClose}
        onConfirm={handleDelete}
      />
    </>
  );
};

export default PluginCard;

PluginCard.propTypes = {
  plugin: PropTypes.object.isRequired,
  user_id: PropTypes.string.isRequired,
};
