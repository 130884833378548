import FourOThreePage from "app/pages/error/403";
import FourOFourPage from "app/pages/error/404";
import FiveOOPage from "app/pages/error/500";
import React from "react";
import { Navigate } from "react-router";

const errorRoutes = [
  {
    path: "/404",
    element: <FourOFourPage />,
  },
  {
    path: "/403",
    element: <FourOThreePage />,
  },
  {
    path: "/500",
    element: <FiveOOPage />,
  },
  {
    path: "*",
    element: <Navigate to="/404" replace={true} />,
  },
];

export default errorRoutes;
