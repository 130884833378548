import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { Handle, Position, useReactFlow } from "@xyflow/react";
import React, { useCallback, useState } from "react";

const NodeWrapper = ({
  id,
  children,
  title,
  width = 200,
  data,
  setNodes,
  color = "#1976d2",
  canAddInputs = true,
  canAddOutputs = true,
  canDeleteInputs = true,
  canDeleteOutputs = true,
  onTry,
}) => {
  const [newInputLabel, setNewInputLabel] = useState("");
  const [newOutputLabel, setNewOutputLabel] = useState("");
  const [showInputForm, setShowInputForm] = useState(false);
  const [showOutputForm, setShowOutputForm] = useState(false);
  const [name, setName] = useState(data.name || "");
  const [inputs, setInputs] = useState(data.inputs || []);
  const [outputs, setOutputs] = useState(data.outputs || []);
  const [modalOpen, setModalOpen] = useState(false);
  const [inputValues, setInputValues] = useState({});
  const [tryResult, setTryResult] = useState(null);
  const [isWaiting, setIsWaiting] = useState(false);

  const { setEdges } = useReactFlow();

  const updateNodeData = useCallback(
    (newData) => {
      setNodes((nds) =>
        nds.map((node) => {
          if (node.id === id) {
            node.data = {
              ...node.data,
              ...newData,
            };
          }
          return node;
        }),
      );
    },
    [id, setNodes],
  );

  const handleNameChange = (e) => {
    e.preventDefault();
    const newName = e.target.value;
    setName(newName);
    updateNodeData({ name: newName });
  };

  const toggleInputForm = (e) => {
    e.preventDefault();
    setShowInputForm(!showInputForm);
    if (showInputForm) {
      setNewInputLabel("");
    }
  };

  const toggleOutputForm = (e) => {
    e.preventDefault();
    setShowOutputForm(!showOutputForm);
    if (showOutputForm) {
      setNewOutputLabel("");
    }
  };

  const addInput = (e) => {
    e.preventDefault();
    if (newInputLabel && canAddInputs) {
      const newInputs = [
        ...inputs,
        { id: newInputLabel, label: newInputLabel },
      ];
      setInputs(newInputs);
      setNewInputLabel("");
      setShowInputForm(false);
      updateNodeData({ inputs: newInputs });
    }
  };

  const addOutput = (e) => {
    e.preventDefault();
    if (newOutputLabel && canAddOutputs) {
      const newOutputs = [
        ...outputs,
        { id: newOutputLabel, label: newOutputLabel },
      ];
      setOutputs(newOutputs);
      setNewOutputLabel("");
      setShowOutputForm(false);
      updateNodeData({ outputs: newOutputs });
    }
  };

  const deleteInput = useCallback(
    (inputId) => (e) => {
      e.preventDefault();
      if (canDeleteInputs) {
        const newInputs = inputs.filter((input) => input.id !== inputId);
        setInputs(newInputs);
        setEdges((edges) =>
          edges.filter(
            (edge) => edge.target !== id || edge.targetHandle !== inputId,
          ),
        );
        updateNodeData({ inputs: newInputs });
      }
    },
    [id, inputs, setEdges, updateNodeData, canDeleteInputs],
  );

  const deleteOutput = useCallback(
    (outputId) => (e) => {
      e.preventDefault();
      if (canDeleteOutputs) {
        const newOutputs = outputs.filter((output) => output.id !== outputId);
        setOutputs(newOutputs);
        setEdges((edges) =>
          edges.filter(
            (edge) => edge.source !== id || edge.sourceHandle !== outputId,
          ),
        );
        updateNodeData({ outputs: newOutputs });
      }
    },
    [id, outputs, setEdges, updateNodeData, canDeleteOutputs],
  );

  const handleChildrenEvents = useCallback((e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  }, []);

  const handleRunClick = () => {
    setModalOpen(true);
    setTryResult(null);
    setIsWaiting(false);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setTryResult(null);
    setIsWaiting(false);
  };

  const handleInputChange = (inputId, value) => {
    setInputValues((prev) => ({ ...prev, [inputId]: value }));
  };

  const handleSubmit = async () => {
    if (onTry) {
      setIsWaiting(true);
      const result = await onTry({ id, name, inputs: inputValues });
      setTryResult(result);
      setIsWaiting(false);
    }
  };

  return (
    <div
      style={{
        border: "1px solid #ccc",
        borderRadius: "5px",
        width: `${width}px`,
        backgroundColor: "white",
        fontSize: "12px",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
      }}
    >
      <div
        style={{
          backgroundColor: color,
          color: "white",
          padding: "5px",
          borderTopLeftRadius: "5px",
          borderTopRightRadius: "5px",
          fontWeight: "bold",
          textAlign: "center",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <span>{title}</span>
        {onTry && (
          <IconButton
            onClick={handleRunClick}
            size="small"
            style={{ color: "white" }}
          >
            <PlayArrowIcon fontSize="small" />
          </IconButton>
        )}
      </div>

      <div style={{ padding: "10px" }}>
        <TextField
          id={`node-name-${id}`}
          label="Node Name"
          value={name}
          onChange={handleNameChange}
          onKeyDown={handleChildrenEvents}
          placeholder="Enter node name"
          fullWidth
          margin="normal"
          variant="outlined"
        />
      </div>

      <div
        style={{
          backgroundColor: "#e3f2fd",
          padding: "5px",
          borderBottom: "1px solid #bbdefb",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>Inputs</div>
        {canAddInputs &&
          (showInputForm ? (
            <>
              <TextField
                value={newInputLabel}
                onChange={(e) => {
                  e.preventDefault();
                  setNewInputLabel(e.target.value);
                }}
                onKeyDown={(e) => {
                  e.stopPropagation();
                  if (e.key === "Enter") {
                    e.preventDefault();
                    addInput(e);
                  }
                }}
                placeholder="New input label"
                size="small"
                style={{
                  width: "70%",
                  marginRight: "5px",
                  marginLeft: "5px",
                  backgroundColor: "white",
                }}
              />
              <IconButton onClick={(e) => addInput(e)} size="small">
                <CheckIcon />
              </IconButton>
              <IconButton onClick={(e) => toggleInputForm(e)} size="small">
                <CloseIcon />
              </IconButton>
            </>
          ) : (
            <IconButton onClick={(e) => toggleInputForm(e)} size="small">
              <AddIcon />
            </IconButton>
          ))}
      </div>

      <div style={{ padding: "10px" }}>
        {inputs.map((input, index) => (
          <div
            key={index}
            style={{
              position: "relative",
              marginBottom: "10px",
              height: "20px",
            }}
          >
            <Handle
              type="target"
              position={Position.Left}
              id={input.label}
              style={{
                left: -10,
                top: "50%",
                width: "10px",
                height: "10px",
                background: "#1976d2",
              }}
            />
            <div
              style={{
                position: "absolute",
                left: 10,
                top: "50%",
                transform: "translateY(-50%)",
              }}
            >
              {input.label}
            </div>
            {canDeleteInputs && (
              <IconButton
                onClick={(e) => deleteInput(input.id)(e)}
                size="small"
                style={{
                  position: "absolute",
                  right: 0,
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            )}
          </div>
        ))}

        <div
          onMouseDown={handleChildrenEvents}
          onTouchStart={handleChildrenEvents}
          onKeyDown={handleChildrenEvents}
          tabIndex={0}
        >
          {children}
        </div>
      </div>

      <div
        style={{
          backgroundColor: "#e3f2fd",
          padding: "5px",
          borderTop: "1px solid #bbdefb",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>Outputs</div>
        {canAddOutputs &&
          (showOutputForm ? (
            <>
              <TextField
                value={newOutputLabel}
                onChange={(e) => {
                  e.preventDefault();
                  setNewOutputLabel(e.target.value);
                }}
                onKeyDown={(e) => {
                  e.stopPropagation();
                  if (e.key === "Enter") {
                    e.preventDefault();
                    addOutput(e);
                  }
                }}
                placeholder="New output label"
                size="small"
                style={{
                  width: "70%",
                  marginRight: "5px",
                  marginLeft: "5px",
                  backgroundColor: "white",
                }}
              />
              <IconButton onClick={(e) => addOutput(e)} size="small">
                <CheckIcon />
              </IconButton>
              <IconButton onClick={(e) => toggleOutputForm(e)} size="small">
                <CloseIcon />
              </IconButton>
            </>
          ) : (
            <IconButton onClick={(e) => toggleOutputForm(e)} size="small">
              <AddIcon />
            </IconButton>
          ))}
      </div>

      <div style={{ padding: "5px" }}>
        {outputs.map((output, index) => (
          <div
            key={index}
            style={{
              position: "relative",
              height: "20px",
              marginBottom: index < outputs.length - 1 ? "10px" : "0",
            }}
          >
            <div
              style={{
                position: "absolute",
                right: 20,
                top: "50%",
                transform: "translateY(-50%)",
              }}
            >
              {output.label}
            </div>
            <Handle
              type="source"
              position={Position.Right}
              id={output.label}
              style={{
                right: -10,
                top: "50%",
                width: "10px",
                height: "10px",
                background: "#1976d2",
              }}
            />
            {canDeleteOutputs && (
              <IconButton
                onClick={(e) => deleteOutput(output.id)(e)}
                size="small"
                style={{
                  position: "absolute",
                  left: 0,
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            )}
          </div>
        ))}
      </div>

      <Modal
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
          }}
        >
          <h2 id="modal-modal-title">Try with input values</h2>
          {inputs.map((input) => (
            <TextField
              key={input.id}
              label={input.label}
              fullWidth
              margin="normal"
              onChange={(e) => handleInputChange(input.id, e.target.value)}
            />
          ))}
          <Button
            onClick={handleSubmit}
            variant="contained"
            sx={{ mt: 2, mb: 2 }}
            disabled={isWaiting}
          >
            {isWaiting ? "Processing..." : "Submit"}
          </Button>
          {isWaiting && (
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <CircularProgress />
            </Box>
          )}
          {tryResult && !isWaiting && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="h6">Result:</Typography>
              <Box
                sx={{
                  maxHeight: "200px",
                  overflowY: "auto",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  padding: "8px",
                }}
              >
                <pre
                  style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}
                >
                  {JSON.stringify(tryResult, null, 2)}
                </pre>
              </Box>
            </Box>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default NodeWrapper;
