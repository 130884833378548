import DeleteIcon from "@mui/icons-material/Delete";
import { Button, IconButton, Menu, MenuItem, TextField } from "@mui/material";
import { useReactFlow } from "@xyflow/react";
import { tryPluginAction } from "app/redux/actions/plugin";
import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";

import NodeWrapper from "./NodeWrapper";

const ApiNode = ({ id, data }) => {
  const [url, setUrl] = useState(data.url || "");
  const [method, setMethod] = useState(data.method || "GET");
  const [headers, setHeaders] = useState(data.headers || []);
  const [queryParams, setQueryParams] = useState(data.queryParams || []);
  const [bodyParams, setBodyParams] = useState(data.bodyParams || []);
  const [maxRetries, setMaxRetries] = useState(data.maxRetries || 3);
  const [anchorEl, setAnchorEl] = useState(null);
  const { setNodes } = useReactFlow();
  const dispatch = useDispatch();

  const handleTry = ({ name, inputs }) => {
    const node = {
      method,
      url,
      headers,
      queryParams,
      bodyParams,
      maxRetries,
    };
    return dispatch(
      tryPluginAction({
        type: "api",
        name,
        node,
        values: inputs,
      }),
    );
  };

  const updateNodeData = useCallback(
    (newData) => {
      setNodes((nds) =>
        nds.map((node) => {
          if (node.id === id) {
            node.data = { ...node.data, ...newData };
          }
          return node;
        }),
      );
    },
    [id, setNodes],
  );

  const handleUrlChange = (e) => {
    const newUrl = e.target.value;
    setUrl(newUrl);
    updateNodeData({ url: newUrl });
  };

  const handleMethodClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMethodClose = () => {
    setAnchorEl(null);
  };

  const handleMethodChange = (newMethod) => {
    setMethod(newMethod);
    updateNodeData({ method: newMethod });
    handleMethodClose();
  };

  const handleMaxRetriesChange = (e) => {
    const newMaxRetries = parseInt(e.target.value, 10);
    setMaxRetries(newMaxRetries);
    updateNodeData({ maxRetries: newMaxRetries });
  };

  const addHeader = () => {
    const newHeaders = [...headers, { key: "", value: "" }];
    setHeaders(newHeaders);
    updateNodeData({ headers: newHeaders });
  };

  const updateHeader = (index, field, value) => {
    const newHeaders = headers.map((header, i) =>
      i === index ? { ...header, [field]: value } : header,
    );
    setHeaders(newHeaders);
    updateNodeData({ headers: newHeaders });
  };

  const removeHeader = (index) => {
    const newHeaders = headers.filter((_, i) => i !== index);
    setHeaders(newHeaders);
    updateNodeData({ headers: newHeaders });
  };

  const addQueryParam = () => {
    const newQueryParams = [...queryParams, { key: "", value: "" }];
    setQueryParams(newQueryParams);
    updateNodeData({ queryParams: newQueryParams });
  };

  const updateQueryParam = (index, field, value) => {
    const newQueryParams = queryParams.map((param, i) =>
      i === index ? { ...param, [field]: value } : param,
    );
    setQueryParams(newQueryParams);
    updateNodeData({ queryParams: newQueryParams });
  };

  const removeQueryParam = (index) => {
    const newQueryParams = queryParams.filter((_, i) => i !== index);
    setQueryParams(newQueryParams);
    updateNodeData({ queryParams: newQueryParams });
  };

  const addBodyParam = () => {
    const newBodyParams = [...bodyParams, { key: "", value: "" }];
    setBodyParams(newBodyParams);
    updateNodeData({ bodyParams: newBodyParams });
  };

  const updateBodyParam = (index, field, value) => {
    const newBodyParams = bodyParams.map((param, i) =>
      i === index ? { ...param, [field]: value } : param,
    );
    setBodyParams(newBodyParams);
    updateNodeData({ bodyParams: newBodyParams });
  };

  const removeBodyParam = (index) => {
    const newBodyParams = bodyParams.filter((_, i) => i !== index);
    setBodyParams(newBodyParams);
    updateNodeData({ bodyParams: newBodyParams });
  };

  return (
    <NodeWrapper
      id={id}
      title="API Node"
      initialInputs={data.inputs || []}
      initialOutputs={data.outputs || []}
      width={300}
      data={data}
      setNodes={setNodes}
      canAddInputs={true}
      canAddOutputs={true}
      canDeleteInputs={true}
      canDeleteOutputs={true}
      onTry={handleTry}
    >
      <Button
        aria-controls="method-menu"
        aria-haspopup="true"
        onClick={handleMethodClick}
        fullWidth
        variant="outlined"
        style={{ marginBottom: "10px" }}
      >
        {method}
      </Button>
      <Menu
        id="method-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMethodClose}
      >
        <MenuItem onClick={() => handleMethodChange("GET")}>GET</MenuItem>
        <MenuItem onClick={() => handleMethodChange("POST")}>POST</MenuItem>
        <MenuItem onClick={() => handleMethodChange("PUT")}>PUT</MenuItem>
        <MenuItem onClick={() => handleMethodChange("DELETE")}>DELETE</MenuItem>
      </Menu>

      <TextField
        id={`url-input-${id}`}
        label="URL"
        value={url}
        onChange={handleUrlChange}
        placeholder="Enter API URL"
        fullWidth
        margin="normal"
        variant="outlined"
      />

      <TextField
        id={`max-retries-input-${id}`}
        label="Max Retries"
        type="number"
        value={maxRetries}
        onChange={handleMaxRetriesChange}
        fullWidth
        margin="normal"
        variant="outlined"
        inputProps={{ min: 0 }}
      />

      <div style={{ marginBottom: "10px" }}>
        <label>Headers:</label>
        <div style={{ maxHeight: "150px", overflowY: "auto", padding: "5px" }}>
          {headers.map((header, index) => (
            <div key={index} style={{ display: "flex", marginBottom: "5px" }}>
              <TextField
                value={header.key}
                onChange={(e) => updateHeader(index, "key", e.target.value)}
                placeholder="Key"
                style={{ flex: 1, marginRight: "5px" }}
                size="small"
              />
              <TextField
                value={header.value}
                onChange={(e) => updateHeader(index, "value", e.target.value)}
                placeholder="Value"
                style={{ flex: 1, marginRight: "5px" }}
                size="small"
              />
              <IconButton onClick={() => removeHeader(index)} size="small">
                <DeleteIcon fontSize="small" />
              </IconButton>
            </div>
          ))}
        </div>
        <Button
          onClick={addHeader}
          variant="outlined"
          color="primary"
          size="small"
          style={{ marginTop: "5px" }}
        >
          + Add Header
        </Button>
      </div>

      {method === "GET" && (
        <div style={{ marginBottom: "10px" }}>
          <label>Query Parameters:</label>
          <div
            style={{ maxHeight: "150px", overflowY: "auto", padding: "5px" }}
          >
            {queryParams.map((param, index) => (
              <div key={index} style={{ display: "flex", marginBottom: "5px" }}>
                <TextField
                  value={param.key}
                  onChange={(e) =>
                    updateQueryParam(index, "key", e.target.value)
                  }
                  placeholder="Key"
                  style={{ flex: 1, marginRight: "5px" }}
                  size="small"
                />
                <TextField
                  value={param.value}
                  onChange={(e) =>
                    updateQueryParam(index, "value", e.target.value)
                  }
                  placeholder="Value"
                  style={{ flex: 1, marginRight: "5px" }}
                  size="small"
                />
                <IconButton
                  onClick={() => removeQueryParam(index)}
                  size="small"
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </div>
            ))}
          </div>
          <Button
            onClick={addQueryParam}
            variant="outlined"
            color="primary"
            size="small"
            style={{ marginTop: "5px" }}
          >
            + Add Query Parameter
          </Button>
        </div>
      )}

      {(method === "POST" || method === "PUT") && (
        <div style={{ marginBottom: "10px" }}>
          <label>Body Parameters:</label>
          <div
            style={{ maxHeight: "150px", overflowY: "auto", padding: "5px" }}
          >
            {bodyParams.map((param, index) => (
              <div key={index} style={{ display: "flex", marginBottom: "5px" }}>
                <TextField
                  value={param.key}
                  onChange={(e) =>
                    updateBodyParam(index, "key", e.target.value)
                  }
                  placeholder="Key"
                  style={{ flex: 1, marginRight: "5px" }}
                  size="small"
                />
                <TextField
                  value={param.value}
                  onChange={(e) =>
                    updateBodyParam(index, "value", e.target.value)
                  }
                  placeholder="Value"
                  style={{ flex: 1, marginRight: "5px" }}
                  size="small"
                />
                <IconButton onClick={() => removeBodyParam(index)} size="small">
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </div>
            ))}
          </div>
          <Button
            onClick={addBodyParam}
            variant="outlined"
            color="primary"
            size="small"
            style={{ marginTop: "5px" }}
          >
            + Add Body Parameter
          </Button>
        </div>
      )}
    </NodeWrapper>
  );
};

export default ApiNode;
