import { Button } from "@mui/material";
import { removeAlert } from "app/redux/actions/alert";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

let displayed = [];

const AlertProvider = ({ children }) => {
  const dispatch = useDispatch();
  const alerts = useSelector(({ app }) => app.get("alerts"));
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayed = (id) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id) => {
    displayed = [...displayed.filter((key) => id !== key)];
  };

  useEffect(() => {
    alerts.forEach(({ key, message, options = {}, dismissed = false }) => {
      if (dismissed) {
        closeSnackbar(key);
        return;
      }

      if (displayed.includes(key)) return;

      enqueueSnackbar(message, {
        key,
        ...options,
        action: (key) => (
          <Button
            size="small"
            sx={{ color: "#fff" }}
            onClick={() => closeSnackbar(key)}
          >
            close
          </Button>
        ),
        onClose: (event, reason, myKey) => {
          if (options.onClose) {
            options.onClose(event, reason, myKey);
          }
        },
        onExited: (event, myKey) => {
          dispatch(removeAlert(myKey));
          removeDisplayed(myKey);
        },
      });

      storeDisplayed(key);
    });
  }, [alerts, closeSnackbar, enqueueSnackbar, dispatch]);

  return <>{children}</>;
};

export default AlertProvider;

AlertProvider.propTypes = {
  children: PropTypes.node,
};
