import Div from "@jumbo/shared/Div";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Button,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import * as yup from "yup";

const UserModal = ({ user, onClose, isOpen, onConfirm, isEdit }) => {
  const [isShow, setIsShow] = useState(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 1,
  };

  const handleSubmit = (values) => {
    onConfirm(values);
    formik.resetForm();
  };

  const initialValues = {
    username: "",
    email: "",
    password: "",
    role: "",
  };

  const validationSchema = yup.object({
    username: yup.string().required("使用者名稱為必填欄位"),
    email: yup
      .string()
      .email("請輸入正確的信箱格式")
      .required("電子郵件為必填欄位"),
    password: yup.string().required("密碼為必填欄位"),
    role: yup.string().required("權限為必選欄位"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  const handleClose = () => {
    formik.resetForm();
    onClose();
  };

  useEffect(() => {
    if (isEdit && user) {
      formik.setValues({
        username: user.get("username"),
        email: user.get("email"),
        password: user.get("password"),
        role: user.get("role"),
      });
    }
  }, [isEdit, user]);

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Div sx={style}>
        <Stack flexDirection="row" color="orange" gap={1} p={2}>
          <Typography variant="h2" m={0}>
            建立使用者
          </Typography>
        </Stack>
        <Divider />
        <form onSubmit={formik.handleSubmit}>
          <Stack gap={2} sx={{ p: 2 }}>
            <TextField
              fullWidth
              id="username"
              name="username"
              placeholder="請輸入使用者名稱"
              value={formik.values.username}
              onChange={formik.handleChange}
              error={formik.touched.username && Boolean(formik.errors.username)}
              helperText={formik.touched.username && formik.errors.username}
            />
            <TextField
              fullWidth
              id="email"
              name="email"
              placeholder="請輸入 Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <TextField
              fullWidth
              id="password"
              name="password"
              type={isShow ? "text" : "password"}
              placeholder="請輸入密碼"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              InputProps={{
                endAdornment: isShow ? (
                  <IconButton color="primary" onClick={() => setIsShow(false)}>
                    <VisibilityOffIcon />
                  </IconButton>
                ) : (
                  <IconButton color="primary" onClick={() => setIsShow(true)}>
                    <RemoveRedEyeIcon />
                  </IconButton>
                ),
              }}
            />
            <FormControl fullWidth>
              <Select
                id="role"
                name="role"
                value={formik.values.role}
                onChange={formik.handleChange}
                error={formik.touched.role && Boolean(formik.errors.role)}
              >
                <MenuItem value="">請選擇權限</MenuItem>
                <MenuItem value="admin">admin</MenuItem>
                <MenuItem value="user">user</MenuItem>
              </Select>
              <FormHelperText
                error={formik.touched.role && Boolean(formik.errors.role)}
              >
                {formik.touched.role && formik.errors.role}
              </FormHelperText>
            </FormControl>
          </Stack>
          <Divider />
          <Stack p={1} flexDirection="row" gap={1} justifyContent="flex-end">
            <Button variant="outlined" onClick={handleClose} size="small">
              取消
            </Button>
            <Button variant="contained" type="submit" size="small">
              確定
            </Button>
          </Stack>
        </form>
      </Div>
    </Modal>
  );
};

export default UserModal;

UserModal.propTypes = {
  user: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  isEdit: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};
